
/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Container, Navbar, NavbarBrand
} from "reactstrap";
import "./index.css"
class AdminNavbar extends React.Component {
  render() {
    return (
      <>
      <div className='bg-white w-100 fixed-header-bar'>
      <Container className="px-4 bg-white py-3 d-flex  align-items-center">
            <NavbarBrand to="/" tag={Link}>
            <img alt='logoVBC' src={'https://res.cloudinary.com/agridential/image/upload/v1602647664/Logo/image_x4dg6f.png'} style={{ height: "50px" }} />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar-collapse-main">
              <span className="navbar-toggler-icon" />
            </button>
         </Container>
      </div>
         
      </>
    );
  }
}

export default AdminNavbar;
