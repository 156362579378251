import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import "../../../css/reactTable.css";
import moment from "moment"

function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  const genTable = React.useCallback((data) => {
    const dataRender = data.map(item => {
        return {
            ...item,
            vehicleNo: item.vehicleNo || "Cập nhật sau",
            cooperativeName: item.cooperativeName || "Cập nhật sau",
            productName: item.productName || "Cập nhật sau",
            quantity: item.quantity || "Cập nhật sau",
            note: item.detail || "Cập nhật sau",
            date: moment(item.date).format("HH:mm:ss DD/MM/YYYY") 
        }
    })
    setActivity(dataRender)
    setLoading(false)
  }, [])

  const getData = async (startDate, endDate) => {
    axios.defaults.headers.common.Authorization = userInfo.data.token
    const response = await axios({
      method: 'GET',
      url: LINK_API.REPORT_IMPORT.concat('?startTime=', startDate, '&endTime=', endDate)
    })
    if (response && response.data && response.data.data) {
      genTable(response.data.data)
    }
    else {
      genTable([])
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getData(props.startDate, props.endDate)
    
  }, [props.startDate, props.endDate]);


  const columns = React.useMemo(
    () => [
      {
        Header: "Biển số xe",
        accessor: "vehicleNo",
        sortable: false
      },
      {
        Header: "Ngày nhập",
        accessor: "date",
        filterable: false
      },
      {
        Header: "Hộ kinh doanh",
        accessor: "cooperativeName",
        sortable: false
      },
      {
        Header: "Mặt hàng",
        accessor: "productName",
        filterable: false
      },
      {
        Header: "Số lượng (tấn)",
        accessor: "quantity",
        filterable: false
      },
      {
        Header: "Ghi chú",
        accessor: "note",
        filterable: false
      }
    ],
    [t]
  );

  return (

    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={activityRender}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    allActivity: state.allActivity
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllActivity: (data) => dispatch({ type: "GET_ALL_ACTIVITY", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));