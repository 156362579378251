import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import SubTable from "components/SubTable/table.js";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import moment from 'moment';
import "../../../css/reactTable.css";

function PrintManagerment(props) {
  const [printData, setPrintData] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  const genTable = React.useCallback((data) => {
    let dataRender = data.map((item, index) => {
      const dataDetail = item.printStamp.map(prop => {
        return {
          productName: prop.productName,
          time: moment(prop.date).format("HH:mm:ss DD/MM/YYYY"),
          number: <div>
            <div>
              {`${prop.stampNo} tem`}
            </div>
            <div>
              {`${prop.startId} - ${prop.endId}`}
            </div>
          </div>,
          sampleStamp: prop.sampleStampName ? prop.sampleStampName : "Tem đã bị xoá bởi quản trị viên",
          type: prop.endId.length === 13 ? "Tem sản phẩm" : "Tem lô hàng"
        }
      })
      return {
        id: item.userUuid,
        enterpriseName: item.username,
        dataDetail: dataDetail,
        count: item.count
      }
    })
    setPrintData(dataRender)
    setLoading(false)
  }, [])


  const getData = async (startDate, endDate) => {
    try {

      axios.defaults.headers.common.Authorization = userInfo.data.token
      const response = await axios({
        method: 'GET',
        url: LINK_API.GET_PRINT_STAMP_LIST_ADMIN.concat('?startTime=', startDate, '&endTime=', endDate)
      })
      if (response && response.data && response.data.data) {
        genTable(response.data.data)
      }
      else {
        genTable([])
      }
    }
    catch {
      genTable([]);
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    getData(props.startDate, props.endDate)

  }, [props.startDate, props.endDate]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "left",
        Header: () => (
          <div style={{
            textAlign: ""
          }}>
            Mã cơ sở sản xuất
          </div>
        )
      },
      {
        Header: "Cơ sở sản xuất",
        accessor: "enterpriseName",
        filterable: false,
      },
      {
        Header: "Số lần in",
        accessor: "count",
        filterable: false,
      },
    ],
    [t]
  );


  const columnSubTable = [
    {
      Header: "Sản phẩm",
      accessor: "productName",
      filterable: true,
      filterMethod: (filter, row) => {
        if (row.productName) {
          const timeInRow = row.productName.toUpperCase().search(filter.value.toUpperCase());
          if (timeInRow === -1) return false;
          return true;
        }
        return false;
      },
    },
    {
      Header: "Thời gian in",
      accessor: "time",
      filterable: true,
      filterMethod: (filter, row) => {
        const timeInRow = row.time.search(filter.value);
        if (timeInRow === -1) return false;
        return true;
      },
    },
    {
      Header: "Số lượng",
      accessor: "number",
      filterable: false
    },
    {
      Header: "Tên tem mẫu",
      accessor: "sampleStamp",
      filterable: false
    },
    {
      Header: "Loại tem",
      accessor: "type",
      filterable: false,
      maxWidth: 100
    }
]

  return (

    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={printData}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
          SubComponent={row => {
            return (
              <ReactTable
                noDataText={t('noData')}
                data={row.original.dataDetail}
                columns={columnSubTable}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                className="-striped -highlight"
                loading={loadingState}
                LoadingComponent={LoadingTable}
        />
            )
          }}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    harvestWithObject: state.harvestWithObject
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllHarvestWithObject: (data) => dispatch({ type: "GET_ALL_HARVEST_WITH_OBJECT", data: data })
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PrintManagerment));