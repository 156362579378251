import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import SubTable from "components/SubTable/table.js";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import moment from 'moment';
import "../../css/reactTable.css";

function PrintManagerment(props) {
  const [printData, setPrintData] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  const genTable = React.useCallback((data) => {
    let dataRender = data.map((item, index) => {
      const numberOfStamp = parseInt(item.endId) - parseInt(item.startId) + 1;
      return {
        id: index + 1,
        productName: item.productName,
        time: moment(item.createdAt).format("HH:mm:ss DD/MM/YYYY"),
        number: <div>
          <div>
            {`${numberOfStamp} tem`}
          </div>
          <div>
            {`${item.startId} - ${item.endId}`}
          </div>
        </div>,
        sampleStamp: item.StampSampleInfo ? item.StampSampleInfo.name : "Tem đã bị xoá bởi quản trị viên",
        type: item.endId.length === 13 ? "Tem sản phẩm" : "Tem lô hàng"
      }
    })
    setPrintData(dataRender)
    setLoading(false)
  }, [])


  const getData = async (startDate, endDate) => {
    axios.defaults.headers.common.Authorization = userInfo.data.token
    const response = await axios({
      method: 'GET',
      url: LINK_API.CREATE_PRINT_STAMP.concat('?startTime=', startDate, '&endTime=', endDate)
    })
    if (response && response.data && response.data.data) {
      genTable(response.data.data)
    }
    else {
      genTable([])
    }
  }

  useEffect(() => {
    setLoading(true)
    getData(props.startDate, props.endDate)

  }, [props.startDate, props.endDate]);


  const columns = React.useMemo(
    () => [
      // {
      //   accessor: "id",
      //   filterable: false,
      //   className: "left",
      //   maxWidth: 50,
      //   Header: () => (
      //     <div style={{
      //       textAlign: ""
      //     }}>

      //     </div>
      //   )
      // },
      {
        Header: "Sản phẩm",
        accessor: "productName",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.productName) {
            const timeInRow = row.productName.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Thời gian in",
        accessor: "time",
        filterable: true,
        filterMethod: (filter, row) => {
          const timeInRow = row.time.search(filter.value);
          if (timeInRow === -1) return false;
          return true;
        },
      },
      {
        Header: "Số lượng",
        accessor: "number",
        filterable: false
      },
      {
        Header: "Tên tem mẫu",
        accessor: "sampleStamp",
        filterable: false
      },
      {
        Header: "Loại tem",
        accessor: "type",
        filterable: false,
        maxWidth: 100
      }
    ],
    [t]
  );

  return (

    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={printData}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    harvestWithObject: state.harvestWithObject
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllHarvestWithObject: (data) => dispatch({ type: "GET_ALL_HARVEST_WITH_OBJECT", data: data })
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PrintManagerment));