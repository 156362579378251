import Loading from 'components/alert/loading';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { Field, Form, Formik } from 'formik';
import Printer from "mdi-react/PrinterIcon";
import SearchIcon from "mdi-react/SearchIcon";
import moment from 'moment';
import QRious from 'qrious';
import React, { useEffect } from 'react';
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTable from 'react-table-6';
import { Button, Card, FormGroup, Input, Modal, ModalBody, ModalHeader, UncontrolledTooltip, Container } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";
import { motion } from "framer-motion";
import PlusCircle from "mdi-react/PlusCircleIcon";
import "./style.css"
import Header from "components/Headers/Header"
import PrintIcon from "mdi-react/PrinterIcon";
import ReactToPrint from 'react-to-print';
import StampSample from "./sampleStamp"
import { LINK_API } from 'constants/API'
import axios from 'axios';
import EditPositionStamp from './editPositionStamp';

const Hashids = require('hashids/cjs')
const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLNOQRSTUVWXYZ0123456789'


function StampList(props) {
  const getStampList = props.getStampList;
  const renderProductList = props.renderProductList;
  const productListState = props.productList;
  const userInfo = props.userInfo;

  const [listProduct, setProduct] = React.useState([]);
  const [isPrint, setIsPrint] = React.useState(false);
  const [dataStampList, setStampList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;
  const [alert, setAlert] = React.useState(null)
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [startID, setStartID] = React.useState(null)
  const [endID, setEndID] = React.useState(null)
  const [type, setType] = React.useState(null)
  const [sampleStampList, setSampleStampList] = React.useState([])
  const [choosedSampleStamp, setChoosedSampleStamp] = React.useState({})
  const componentRef = React.useRef();

  const [data1, setData1] = React.useState(null)
  const [data2, setData2] = React.useState(null)

  const [numberOfRow, setNumberOfRow] = React.useState(0)
  const [numberOfColumn, setNumberOfColumn] = React.useState(5)

  React.useEffect(() => {
    axios.get(LINK_API.GET_SAMPLE_STAMP_FOR_USER)
      .then(res => {
        let listStamp = res.data.data.map(item => {
          return {
            label: item.name,
            value: item
          }
        })

        setSampleStampList(listStamp)
      })
      .catch(err => console.log("err", err))
  }, [])

  const handleModal = () => {
    setModal(!modal)
  }

  const handleModal2 = () => {
    setModal2(!modal2)
  }

  const handleModal3 = () => {
    setModal3(!modal3)
  }

  const genTable = React.useCallback((stampList) => {
    let data = stampList.map((prop, index) => {
      let sumStampRemain = prop.amount - prop.used || 0;
      let usedStamp = prop.used ? prop.used.toString() : "0";
      return {
        id: <div className="d-flex justify-content-start">{index + 1}</div>,
        type: <div className="d-flex justify-content-start">{prop.type === "BOX" ? "Tem lô hàng" : "Tem sản phẩm"}</div>,
        time: moment(prop.date).format("DD/MM/YYYY"),
        number: sumStampRemain === 0 ? `${prop.amount} ( ${t('stamp.out')} )` : usedStamp.concat(" / ", prop.amount, " ( ", t('stamp.remain'), " ", sumStampRemain, " ) "),
        start: prop.startId ? (prop.startId.length > 13 ? prop.startId.substr(13, 23) : prop.startId) : '',
        end: prop.endId ? (prop.endId.length > 13 ? prop.endId.substr(13, 23) : prop.endId) : '',
        actions: <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              cookie.save('type', prop.type, { path: '/' })
              cookie.save('minId', prop.startId, { path: '/' })
              cookie.save('maxId', prop.endId, { path: '/' })
              cookie.save('used', prop.used, { path: '/' })
              props.history.push("/user/stampDetail")
            }
            }
            color="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 d-inline-flex align-items-center justify-content-center" id={"stampSearch" + index}>
            <span className="btn-wrapper--icon d-flex">
              <SearchIcon
                size={18}
                className="text-info font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"stampSearch" + index}>
            {t('stamp.detail')}
          </UncontrolledTooltip>
          {/* <Button
            onClick={() => {
              setType(prop.type)
              setStartID(prop.startId)
              setEndID(prop.endId)
              setModal(true)
            }}
            color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 d-inline-flex align-items-center justify-content-center" id={"stampPrint" + index}>
            <span className="btn-wrapper--icon d-flex">
              <Printer
                size={18}
                className="text-info font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"stampPrint" + index}>
            {t('stamp.printStamp')}
          </UncontrolledTooltip> */}

        </div>
      }
    })
    setStampList(data)
    setLoading(false)
  }, [t, props.history])

  useEffect(() => {
    getStampList(1, 5)
  }, [getStampList]);

  useEffect(() => {
    renderProductList(1, 100);
  }, [renderProductList]);

  const stampListData = props.infoActiveStamp.data
  const totalPageProps = props.infoActiveStamp.totalPage
  useEffect(() => {
    if (productListState.data) {
      if (productListState.data.length > 0) {
        let newProduct = productListState.data.map(item => {
          return {
            ...item,
            value: item.name,
            label: item.name
          }
        })
        setProduct(newProduct)
      }
    }
  }, [productListState])


  useEffect(() => {

    if (stampListData && stampListData.length > 0) {
      genTable(stampListData)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }

  }, [totalPageProps, stampListData]);


  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div style={{
            textAlign: "left"
          }}>
            {t('stamp.type')}
          </div>
        ),
        accessor: "type",
        sortable: false
      },
      {
        Header: t('stamp.number'),
        accessor: "number",
        filterable: false
      },
      {
        Header: t('stamp.start'),
        accessor: "start",
        filterable: false
      },
      {
        Header: t('stamp.end'),
        accessor: "end",
        filterable: false
      },
      {
        Header: "Ngày cấp",
        accessor: "time",
        filterable: false
      },
      {
        Header: () => (
          <div style={{
            textAlign: "right"
          }}>
            {t('actions')}
          </div>),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  const userName = props.userInfo && props.userInfo.data && props.userInfo.data.name ? props.userInfo.data.name : "";
  const handlePrintStamp = React.useCallback(async (value) => {

    const startId = value.startID;
    const endId = value.endID;
    await setAlert(<Loading />)

    const productName = value.productName.value;
    const splitProductName = productName.split(" - ")[0]
    // print(startId, endId, splitProductName);

  }, [])

  const enterValue = (value) => {
    const ChildComponent = (
      <div>
        <Formik
          initialValues={{ startID: parseInt(startID), endID: parseInt(endID), productName: null }}
          validationSchema={

            Yup.object().shape({
              productName: Yup.string()
                .required(t('require')),
              startID: Yup.number()
                .required(t('require'))
                .integer(t('stamp.integer'))
                .test(t('stamp.endIDLessStartID'), t('stamp.endIDLessStartID'),
                  function (value) {
                    return value <= this.parent.endID;
                  }
                )
                .test(`Khoảng tem hợp lệ từ ${parseInt(startID)} - ${parseInt(endID)}`, `Khoảng tem hợp lệ từ ${parseInt(startID)} - ${parseInt(endID)}`,
                  function (value) {
                    return value >= parseInt(startID) && value <= parseInt(endID);
                  }
                ),

              endID: Yup.number()
                .required(t('require'))
                .integer(t('stamp.integer'))
                .test(t('stamp.max'), t('stamp.max'),
                  function (value) {
                    return value - this.parent.startID + 1 <= 500;
                  }
                )
                .test(`Khoảng tem hợp lệ từ ${parseInt(startID)} - ${parseInt(endID)}`, `Khoảng tem hợp lệ từ ${parseInt(startID)} - ${parseInt(endID)}`,
                  function (value) {
                    return value >= parseInt(startID) && value <= parseInt(endID);
                  }
                )
            })
          }
          onSubmit={(value) => handlePrintStamp(value)}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Field
                name="productName"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('stamp.productName')}
                    </label>
                    <Select
                      placeholder="Chọn loại sản phẩm"
                      options={listProduct}
                      value={values.productName}
                      onChange={(selectedOption) => setFieldValue("productName", selectedOption)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#3c44b1'
                        }
                      })}
                    />
                  </FormGroup>
                )}
              />
              {errors.startID && touched.productName ? <div style={{ color: "red" }}>{errors.productName}</div> : null}
              <Field
                name="startID"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('stamp.startID')}
                    </label>
                    <Input {...props} type="number" {...props.field} placeholder={t('stamp.startID')} />
                  </FormGroup>
                )}
              />
              {errors.startID && touched.startID ? <div style={{ color: "red" }}>{errors.startID}</div> : null}
              <Field name="endID"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('stamp.endID')}
                    </label>
                    <Input {...props} type="number" {...props.field} placeholder={t('stamp.endID')} />
                  </FormGroup>
                )}
              />
              {errors.endID ? (
                <div style={{ color: "red" }}>{errors.endID}</div>
              ) : null}

              <label className="font-weight-bold">
                {"Tem mẫu"}
              </label>

              <Select
                placeholder="Chọn tem mẫu"
                options={sampleStampList}
                value={choosedSampleStamp}
                onChange={(selectedOption) => {
                  setChoosedSampleStamp(selectedOption)
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '0.29rem',
                  borderWidth: 0.5,
                  height: 30,
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(60,68,177,0.15)',
                    primary50: 'rgba(60,68,177,0.15)',
                    primary: '#50b77a'
                  }
                })}
              />
              <div>
                <Button
                  className="mt-3"
                  color="info"
                  disabled={
                    !values.productName
                    || !values.startID
                    || !values.endID
                    || !choosedSampleStamp.value
                    || parseInt(values.startID) > parseInt(values.endID)
                    || parseInt(values.endID) - parseInt(values.startID) + 1 > 500
                    || parseInt(values.startID) < parseInt(startID)
                    || parseInt(values.endID) > parseInt(endID)
                  }
                  onClick={
                    () => {

                      const numberStamp = parseInt(values.endID) - parseInt(values.startID) + 1;
                      setNumberOfRow(Math.ceil(numberStamp / 5))
                      setData1({
                        userName: userName,
                        values: values,
                        choosedSampleStamp: choosedSampleStamp
                      })
                      setModal(false)
                      setModal2(true)
                    }
                  }>
                  Tiếp theo
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
    return ChildComponent
  }


  const handleSaveData2 = (data2) => {
    setData2(data2)
    setModal3(true)
    setModal2(false)
  }

  const enterValue2 = () => {
    return <EditPositionStamp data1={data1} saveData2={handleSaveData2} lengthStamp={type === "BOX" ? 11 : 13} />
  }

  const handleSubmitPrintStamp = (data1, lengthOfID) => {
    const body = {
      "productUuid": data1.productName.value.split(" - ")[1],
      "startId": data1.startID.toString().padStart(lengthOfID, "0"),
      "endId": data1.endID.toString().padStart(lengthOfID, "0"),
      "sampleStamp": choosedSampleStamp.value.uuid
    }

    axios.post(LINK_API.CREATE_PRINT_STAMP, body)
      .then(() => { })
      .catch(() => { })
  }

  const enterValue3 = () => {

    if (data1 && data2) {
      return (
        <div>
          <div className="mt-3">
            <div className="mb-2">
              Số cột:
          </div>
            <div>
              <Input
                type="number"
                value={numberOfColumn}
                onChange={(e) => setNumberOfColumn(e.target.value)}
              />
            </div>
            {
              numberOfColumn ?
                null
                :
                <div className="text-danger font-size-sm mt-2">
                  Thiếu số cột
              </div>
            }
          </div>
          <div className="mt-3">
            <div className="mb-2">
              Số hàng:
          </div>
            <div>
              <Input
                type="number"
                value={numberOfRow}
                onChange={(e) => setNumberOfRow(e.target.value)}
              />
            </div>
            {
              numberOfRow ?
                null
                :
                <div className="text-danger font-size-sm mt-2">
                  Thiếu số hàng
              </div>
            }
          </div>
          <div className="d-flex flex-row justify-content-center mt-3">
            <ReactToPrint
              onAfterPrint={() => handleSubmitPrintStamp(data1.values, type === "BOX" ? 11 : 13)}
              trigger={() => <Button color="info" onClick={() => { }}>
                <span className="btn-wrapper--icon">
                  In tem
                </span>
              </Button>}
              content={() => componentRef.current}
            />
            <div id="printObject" style={{ display: "none" }}>
              <StampSample
                lengthStamp={type === "BOX" ? 11 : 13}
                values={data1.values}
                choosedSampleStamp={data2}
                userName={userName}
                numberCol={numberOfColumn}
                numberRow={numberOfRow}
                ref={componentRef} />
            </div>
          </div>
        </div>

      )
    }

  }

  const [userRole, setRole] = React.useState("M");
  React.useEffect(() => {
    if (userInfo.data) {
      setRole(userInfo.data.role)
    }
  }, [userInfo])

  return (

    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header />
        <Container fluid className='mt--7'>
          <Card className="card-box mb-5">
            <div className="card-header py-3 d-flex justify-content-between">
              <div className=" font-weight-bold card-header--title font-size-lg">{t('stamp.list')}</div>
              <div className="card-header--actions">
                <Button onClick={() => props.history.push("/user/activateStamp")} color="info">
                  <span className="btn-wrapper--icon">
                    <PlusCircle size={16} />
                  </span>
                  <span className="btn-wrapper--label">{t('stamp.activateStamp')}</span>
                </Button>
              </div>
            </div>
            <div className="divider" />
            <div className="card-body pt-2 px-4 pb-4">
              <ReactTable
                noDataText={t('noData')}
                data={dataStampList}
                columns={columns}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                manual
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                pages={totalPage}
                className="-striped -highlight"
                loading={loadingState}
                LoadingComponent={LoadingTable}
                onFetchData={async (state, instance) => {
                  if (dataStampList && dataStampList.length > 0) {
                    setLoading(true)
                    let x = state.page + 1;
                    getStampList(x, state.pageSize)
                  }

                }}
              />
            </div>
          </Card>
        </Container>
        <Modal zIndex={2000} centered isOpen={modal} toggle={handleModal}>
          <ModalHeader className="d-flex" toggle={handleModal}>
            Nhập thông tin in trên tem
          </ModalHeader>
          <ModalBody>
            {enterValue()}
          </ModalBody>
        </Modal>
        <Modal zIndex={2000} centered isOpen={modal2} toggle={handleModal2}>
          <ModalHeader className="d-flex" toggle={handleModal2}>
            Chỉnh sửa thông tin trên tem in
          </ModalHeader>
          <ModalBody>
            {enterValue2()}
          </ModalBody>
        </Modal>
        <Modal zIndex={2000} centered isOpen={modal3} toggle={handleModal3}>
          <ModalHeader className="d-flex" toggle={handleModal3}>
            Thay đổi số hàng, số cột
          </ModalHeader>
          <ModalBody>
            {enterValue3()}
          </ModalBody>
        </Modal>
        {/* <Modal zIndex={2000} centered isOpen={modal} toggle={handleModal}>
          <ModalHeader className="d-flex" toggle={handleModal}>
            Nhập thông tin
          </ModalHeader>
          <ModalBody>
            {enterValue()}
          </ModalBody>
        </Modal> */}
        {alert}
      </motion.div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    productList: state.renderProduct,
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    infoActiveStamp: state.infoActiveStamp,
  }
}

const mapDispatchToProps = (dispatch) => ({
  renderProductList: (page, size) => dispatch({ type: "RENDER_PRODUCT_LIST", page: page, size: size }),
  getStampList: (page, size) => dispatch({ type: "GET_INFO_ACTIVE_STAMP", page: page, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));