import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "view/css/select.css";
import "./css/index.css";
import { Button } from "reactstrap";
import Success from "components/alert/success";
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import { LINK_API } from "constants/API";
import PositionContainer from "./components/PositionContainer";
import Axios from "axios";

const initialData = [
  {
    show: true,
    position: "left",
    images: [
      {
        image: [],
        link: "",
      },
    ],
  },
  {
    show: true,
    position: "right",
    images: [
      {
        image: [],
        link: "",
      },
    ],
  },
  {
    show: true,
    position: "middle",
    amongPagePosition: "",
    images: [
      {
        image: [],
        link: "",
      },
    ],
  },
];

const initialImageList = [
  {
    addImages: [],
    deleteImages: [],
  },
  {
    addImages: [],
    deleteImages: [],
  },
  {
    addImages: [],
    deleteImages: [],
  },
];

function Advertisement(props) {
  const t = props.t;

  const tabList = [
    {
      title: t(`portal.left`),
      image:
        "https://res.cloudinary.com/dinhtq98/image/upload/v1605077774/Icons/skeleton-left_bi72gc.png",
    },
    {
      title: t(`portal.middle`),
      image:
        "https://res.cloudinary.com/dinhtq98/image/upload/v1605077773/Icons/skeleton-middle_kbjooq.png",
    },
    {
      title: t(`portal.right`),
      image:
        "https://res.cloudinary.com/dinhtq98/image/upload/v1605077774/Icons/skeleton-right_gy345m.png",
    },
  ];

  const [alert, setAlert] = useState(null);
  const [isEdit, setEdit] = useState(false);

  const adsInfo = props.adsInfo;
  const getAdsInfo = props.getAdsInfo;
  const [tab, setTab] = useState(0);

  const [state, setState] = useState(initialData);
  const [images, setImageList] = useState(initialImageList);

  const loadData = (data) => {
    const temp = state;
    temp[tab] = data;
    setState(temp);
  };

  const updateImages = (data) => {
    const temp = images;
    temp[tab] = data;
    setImageList(temp);
  };

  const handleChangeTab = (value) => {
    setTab(value);
  };

  React.useEffect(() => {
    getAdsInfo();
  }, [getAdsInfo]);

  React.useEffect(() => {
    setState(adsInfo);
  }, [adsInfo]);

  const handleSaveData = async () => {
    setAlert(<Loading />);

    const requests = tabList.map((value, idx) => {
      let notChangeImages = [];
      let changeImages = {
        addImages: [],
        deleteImages: [],
      };

      changeImages.deleteImages = images[idx].deleteImages;

      // Filter to find images changed & images not changed
      state[idx].images.forEach((item) => {
        if (!Array.isArray(item.image) || !item.image.length) {
          notChangeImages.push(item);
        } else {
          changeImages.addImages.push({
            image: item.image[0],
            link: item.link,
          });
        }
      });

      const baseInfo = {
        ...state[idx],
        images: notChangeImages,
      };

      // This request to update base info (show, amongPagePosition, link, ...)
      Axios.put(
        `${LINK_API.UPDATE_ADS_SETTING}?id=${state[idx].uuid}`,
        baseInfo
      ).then((res) => res.data.errorCode);

      // This request to update changed images
      return Axios.put(
        `${LINK_API.UPLOAD_ADS_IMAGES}?id=${state[idx].uuid}`,
        changeImages
      ).then((res) => res.data.errorCode);
    });

    await Promise.all(requests)
      .then((res) => {
        if (res.length && res.includes((x) => x !== 1)) {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t("close")} />);
        } else {
          setAlert(
            <Success
              onConfirm={handleConfirm}
              hideCancel={true}
              confirmBtnText={t("confirm")}
            />
          );
        }
      })
      .catch((err) => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t("close")} />);
      });
  };

  const handleConfirm = () => {
    hideAlert();
    getAdsInfo();
  };

  const hideAlert = () => {
    setEdit(false);
    setAlert(null);
  };

  const onEdit = () => {
    getAdsInfo();
    setEdit(!isEdit);
  };

  if (!(state && state.length)) {
    return <Loading />
  }

  return (
    <>
      <div className="p-4">
        <div className="form-row">
          <div className="form-group col-md-8 text-left mb-0">
            <h5 className="font-size-xl font-weight-bold">
              {t("portal.adsSetting")}
            </h5>
            <p className="text-black-50 mb-0">{t("portal.aboutAds")}</p>
          </div>
          {isEdit ? (
            <div className="form-group col-md-4 text-right mb-0">
              <Button color="info" onClick={handleSaveData}>
                {t("save")}
              </Button>
              <Button color="secondary" onClick={onEdit}>
                {t("cancel")}
              </Button>
            </div>
          ) : (
            <div className="form-group col-md-4 text-right mb-0">
              <Button color="danger" onClick={onEdit}>
                {t("edit")}
              </Button>
            </div>
          )}
        </div>

        <div className="divider my-4" />
        <div className="m-2 d-flex flex-column">
          <label className="font-weight-bold">Chọn vị trí đặt quảng cáo</label>
          <div className="m-2 form-row">
            {tabList.map((item, idx) => (
              <Button
                onClick={() => handleChangeTab(idx)}
                title={item.title}
                className={`btn btn-align btn-align${
                  tab === idx ? "-active" : ""
                }`}
              >
                <img src={item.image} alt={item.title} />
              </Button>
            ))}
          </div>
        </div>
        <hr />
        <PositionContainer
          isEdit={isEdit}
          tab={tab}
          images={images[tab]}
          onUpdateImages={updateImages}
          onLoadData={loadData}
          data={state[tab]}
        />
      </div>
      {alert}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    adsInfo: state.portalSetting.adsInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdsInfo: () => dispatch({ type: "GET_ADS_SETTING" }),
  };
};
export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Advertisement)
);
