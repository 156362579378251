import {
  GET_OBJECT_LIST,
  GET_OBJECT_LIST_FAILURE,
  GET_OBJECT_LIST_SUCCESS,
  PUSH_NOTI_PRODUCT_SUCCESS,
  DEACTIVE_NOTI_PRODUCT,
  SAVE_NOTIFICATION,
  CREATE_OBJECT,
  UPDATE_OBJECT_PRODUCTION,
  GET_CROP_OBJECT_SUCCESS,
  GET_CROP_OBJECT_FAILURE,
  GET_CROP_OBJECT,
  CREATE_OBJECT_PRODUCT,
  GET_PRODUCT_LIST,
  GET_OBJECT_PRODUCTION_FILTER
} from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import responseCode from "constants/errorCode.js"

const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');

const getToken = (state) => state.userInfo
const getFullObjectList = (state) => state.objectList.fullData
const getToTalItem = (state) => state.objectList.totalItem

// Get crop object list
function* getCropObjectList(uuid) {
  const tokenInfo = yield select(getToken)
  const response = yield call(fetchCropObject, uuid.uuid, tokenInfo.data.token)
  const data = response.data
  if (data.errorCode === 1) {
    yield put({ type: GET_CROP_OBJECT_SUCCESS, data: data.data, errorCode: data.errorCode })
  } else {
    yield put({ type: GET_CROP_OBJECT_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchCropObject(uuid, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_CROP_LIST + uuid
  })
}

//udpate info object
function* watchUpdateObject(data) {
  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(1000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  try {
    const tokenInfo = yield select(getToken)
    const { response } = yield call(updateInfoObject, data.data, tokenInfo.data.token)
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin lô hàng thành công", color: 'success' })
      yield put({ type: GET_OBJECT_LIST, page: 1, size: 5 })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin lô hàng thất bại", color: 'error' })
      yield put({ type: GET_OBJECT_LIST, page: 1, size: 5 })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  } catch (error) {
    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nuôi trồng thất bại", color: 'error' })
    yield put({ type: GET_OBJECT_LIST, page: 1, size: 5 })
    yield delay(2000)
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  }
}
function updateInfoObject(data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.UPDATE_OBJECT_PRODUCTION, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//create Object
function* watchCreateObject(data) {
  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(2000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  const token = yield select(getToken)
  const { response, error } = yield call(createObject, data.data, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo lô hàng thành công " + result.data.uuid, color: 'success' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo lô hàng thành công " + result.data.uuid} })
      yield put({ type: GET_OBJECT_LIST, page: 1, size: 5 })
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      const { responseCrop, errorCrop } = yield call(assignCrop, result.data, token.data.token, token, data.data);
      if (responseCrop) {
        if (responseCrop.data.errorCode === 1) {
          const { responseRaw, errorRaw } = yield call(sendRawCrop, data.data, token, responseCrop.data.data, result.data);
          if (responseRaw) {
            if (responseRaw.data.errorCode === 1) {
              yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sổ nhật ký thành công - " + result.data.uuid, color: 'success' })
              yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo sổ nhật ký thành công - " + result.data.uuid } })
              yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
            } else {
              if (responseCode[`${responseRaw.data.name}`]) {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${responseRaw.data.name}`].vi, color: 'danger' })
                yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${responseRaw.data.name}`].vi } })
                //yield delay(3000)
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
              } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sổ nhật ký thất bại", color: 'danger' })
                yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sổ nhật ký thất bại" } })
                // yield delay(3000)
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
              }
            }
          } else {
            if (errorRaw) {
              if (errorRaw.response.data.name) {
                if (responseCode[`${errorRaw.response.data.name}`]) {
                  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${errorRaw.response.data.name}`].vi, color: 'danger' })
                  yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${errorRaw.response.data.name}`].vi } })
                  yield delay(3000)
                  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
                } else {
                  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo mụa vụ thất bại", color: 'danger' })
                  yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sổ nhật ký thất bại" } })
                  yield delay(3000)
                  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
                }
              } else {
                yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sổ nhật ký thất bại", color: 'danger' })
                yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sổ nhật ký thất bại" } })
                yield delay(3000)
                yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
              }
            } else {
              yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo màu vụ thất bại", color: 'danger' })
              yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sổ nhật ký thất bại" } })
              yield delay(3000)
              yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
            }
          }

        } else {
          if (responseCode[`${responseCrop.data.name}`]) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${responseCrop.data.name}`].vi, color: 'danger' })
            yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${responseCrop.data.name}`].vi } })
            //yield delay(3000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
          } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sổ nhật ký thất bại", color: 'danger' })
            yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sổ nhật ký thất bại" } })
            // yield delay(3000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
          }
        }
      } else {
        if (errorCrop) {
          if (errorCrop.response.data.name) {
            if (responseCode[`${errorCrop.response.data.name}`]) {
              yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${errorCrop.response.data.name}`].vi, color: 'danger' })
              yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${errorCrop.response.data.name}`].vi } })
              yield delay(3000)
              yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
            } else {
              yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo mụa vụ thất bại", color: 'danger' })
              yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sổ nhật ký thất bại" } })
              yield delay(3000)
              yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
            }
          } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sổ nhật ký thất bại", color: 'danger' })
            yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sổ nhật ký thất bại" } })
            yield delay(3000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
          }
        } else {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo màu vụ thất bại", color: 'danger' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sổ nhật ký thất bại" } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }
      }
    } else {
      if (responseCode[`${result.name}`]) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'danger' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${result.name}`].vi } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo lô hàng thất bại", color: 'danger' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo lô hàng thất bại" } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  } else {
    if (error) {
      if (error.response.data.name) {
        if (responseCode[`${error.response.data.name}`]) {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'danger' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${error.response.data.name}`].vi } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        } else {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo lô hàng thất bại", color: 'danger' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo lô hàng thất bại" } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo lô hàng thất bại", color: 'danger' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo lô hàng thất bại" } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo lô hàng thất bại", color: 'danger' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo lô hàng thất bại" } })
      yield delay(3000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
}
function createObject(data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.CREATE_OBJECT_PRODUCTION, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//creeate crop
async function assignCrop(data, checktoken, userInfo, dataObject) {
  axios.defaults.headers.common.Authorization = await checktoken;
  let data_crop = {
    "startTime": new Date(),
    "uuidOfProductionObject": data.uuid,
    "processCode": dataObject.processCode,
    "uuidOfProductType": dataObject.productType,
  }
  return axios.post(LINK_API.ASSIGN_CROP, data_crop)
    .then(responseCrop => ({ responseCrop }))
    .catch(errorCrop => ({ errorCrop }))
}
async function sendRawCrop(data_crop, userInfo, data_raw, result) {
  axios.defaults.headers.common.Authorization = await userInfo.data.token;
  var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password);
  const privateKey = bytes.toString(CryptoJS.enc.Utf8);
  const privatekey_slice = privateKey.substring(2, privateKey.length);
  const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
  const tx = new EthereumTx(data_raw.rawFungibleAsset, { chain: 4 });
  tx.sign(Buffer_privatekey);
  const rawTx = '0x' + tx.serialize().toString('hex');
  const rawtosend = {
    ...data_raw,
    "raw": rawTx,
    "startTime": new Date(),
    "uuidOfProductionObject": result.uuid,
    "processCode": data_crop.processCode,
    "uuidOfProductType": data_crop.productType,
    "image": data_crop.image
  }
  return axios.post(LINK_API.SEND_RAW_CROP, rawtosend)
    .then(responseRaw => ({ responseRaw }))
    .catch(errorRaw => ({ errorRaw }))
}


//get Object
function* getObjectList(page) {
  try {
    const tokenInfo = yield select(getToken)
    const fullData = yield select(getFullObjectList)
    const totalItem = yield select(getToTalItem)
    //   const size = setting_data.rowOfPage ? setting_data.rowOfPage : 5
    const size = page.size;
    if (fullData[`${page.page}`]) {
      yield put({
        type: GET_OBJECT_LIST_SUCCESS,
        data: fullData[`${page.page}`],
        fullData: fullData
      })
    }
    const response = yield call(fetchObject, page.page, tokenInfo.data.token, size)
    const data = response.data.data
    if (totalItem !== response.data.totalItem) {
      const newList = data
      fullData[`${page.page}`] = newList
      yield put({
        type: GET_OBJECT_LIST_SUCCESS,
        data: newList,
        errorCode: response.data.errorCode,
        totalItem: response.data.totalItem,
        totalPage: response.data.totalPage,
        fullData: fullData
      })
    } else {
      if (!fullData[`${page.page}`]) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_OBJECT_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      } else {
        if (fullData[`${page.page}`] !== data) {
          const newList = data
          fullData[`${page.page}`] = newList
          yield put({
            type: GET_OBJECT_LIST_SUCCESS,
            data: newList,
            errorCode: response.data.errorCode,
            totalItem: response.data.totalItem,
            totalPage: response.data.totalPage,
            fullData: fullData
          })
        }
      }
    }
  } catch (error) {
    yield put({ type: GET_OBJECT_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchObject(page, checktoken, size) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.CREATE_OBJECT_PRODUCTION + page + '&size=' + size
  })
}

//create object product
function * watchCreateObjectProduct (data) {
  let image_product = data.product.image;
  let certificates = data.product.certificates;
  let dataCreate = {
    ...data.product,
    image: [],
    certificates:[]
  }
 yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
 yield delay(2000)
 yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  const token = yield select(getToken)
  const { response, error } = yield call(createProduct, dataCreate, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      const dataraw = data.product
      var bytes = CryptoJS.AES.decrypt(token.data.privateEncrypted.toString(), token.password)
      const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
      const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
      const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
      const tx = new EthereumTx(result.data.raw, { chain: 4 })
      tx.sign(Buffer_privatekey)
      const rawTx = '0x' + tx.serialize().toString('hex')
      dataraw.raw = rawTx
      dataraw.image = image_product;
      dataraw.certificates = certificates;
      dataraw.uuid = result.data.uuid;
      const {responseRaw, errorRaw} = yield call(sendRawCreate, dataraw, token.data.token)
      if(responseRaw){
        if(responseRaw.data.errorCode === 1){
          let dataObject = data.object;
          dataObject.productType = responseRaw.data.data.uuid;
          yield put({ type: CREATE_OBJECT, data: dataObject })
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm thành công " + responseRaw.data.data.uuid, color: 'success' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo sản phẩm thành công " + responseRaw.data.data.uuid} })
           yield put({ type: GET_PRODUCT_LIST, page: 1, size: 5 })
           yield delay(3000)
           yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }else{
          if (responseCode[`${responseRaw.data.name}`].vi) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${responseRaw.data.name}`].vi, color: 'error' })
           yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${result.name}`].vi } })
            yield delay(3000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
          } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: 'error' })
           yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm không thành công" } })
            yield delay(3000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
         }
        }
      }else{
        if (errorRaw.response.data.name) {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${errorRaw.response.data.name}`].vi, color: 'error' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${error.response.data.name}`].vi } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        } else {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: 'error' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm không thành công" } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }
      }
    } else {
      if (responseCode[`${result.name}`].vi) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'error' })
       yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${result.name}`].vi } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: 'error' })
       yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm không thành công" } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
     }
    }
  } else {
    if (error.response.data.name) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${error.response.data.name}`].vi } })
      yield delay(3000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm không thành công" } })
      yield delay(3000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
}
function createProduct (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.CREATE_PRODUCT, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
function sendRawCreate (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.SEND_RAW_PRODUCT, data)
    .then(responseRaw => ({ responseRaw }))
    .catch(errorRaw => ({ errorRaw }))
}
// Get object list filter
function * getObjectListFilter (page) {
  try {
    const tokenInfo = yield select(getToken)
    const fullData = yield select(getFullObjectList)
    const totalItem = yield select(getToTalItem)
    const size = page.size;
    if (fullData[`${page.page}`]) {
      yield put({
        type: GET_OBJECT_LIST_SUCCESS,
        data: fullData[`${page.page}`],
        fullData: fullData
      })
    }
    const response = yield call(fetchObjectFilter, page.page, page.name, page.value, tokenInfo.data.token, size)
    const data = response.data.data
    if (totalItem !== response.data.totalItem) {
      const newList = data
      fullData[`${page.page}`] = newList
      yield put({
        type: GET_OBJECT_LIST_SUCCESS,
        data: newList,
        errorCode: response.data.errorCode,
        totalItem: response.data.totalItem,
        totalPage: response.data.totalPage,
        fullData: fullData
      })
    } else {
      if (!fullData[`${page.page}`]) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_OBJECT_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      }
    }
  } catch (error) {
    yield put({ type: GET_OBJECT_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchObjectFilter (page, name, value, checktoken, size) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.CREATE_OBJECT_PRODUCTION + page + '&size='+ size + '&' + name + '=' + value
  })
}
const oProduction = [
  takeLatest(GET_OBJECT_LIST, getObjectList),
  takeLatest(CREATE_OBJECT, watchCreateObject),
  takeLatest(UPDATE_OBJECT_PRODUCTION, watchUpdateObject),
  takeLatest(GET_CROP_OBJECT, getCropObjectList),
  takeLatest(CREATE_OBJECT_PRODUCT, watchCreateObjectProduct),
  takeLatest(GET_OBJECT_PRODUCTION_FILTER, getObjectListFilter)
]
export default oProduction

