import { default as Axios, default as axios } from "axios";
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import DropZone from "components/dropZone";
import { LINK_API } from "constants/API";
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
//formik
import { useFormik } from 'formik';
import React from "react";
import cookie from "react-cookies";
import { Editor } from 'react-draft-wysiwyg';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Header from "components/Headers/Header"
import { Button, Card, CustomInput, Input, Container } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';

const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');

const CreateObjectProduction = (props) => {
  const t = props.t;
  const userInfo = props.userInfo;
  const shipList = props.shipList;
  const productListState = props.productList;
  const processListState = props.processList;
  const areaListState = props.areaList;
  const permission = props.permission.data;

  const {
    renderProductList,
    renderProcessList,
    renderAreaList,
    getShipList
  } = props;

  const [listProduct, setProduct] = React.useState([]);
  const [listProcess, setProcess] = React.useState([]);
  const [listArea, setArea] = React.useState([])
  const [listImage, setImage] = React.useState([]);
  const [editorState, setEditor] = React.useState(EditorState.createEmpty());
  const [alert, setAlert] = React.useState(null);
  const [contentDescription, setContentDescription] = React.useState("");
  const [listWholesale, setWholeSale] = React.useState([])
  const handleChangeEditor = async (editorState) => {
    document.getElementById("desNoti").innerHTML = "";
    setEditor(editorState)
  }
  const handleAva = (data) => {
    setImage(data);
    document.getElementById("avaNoti").innerHTML = "";
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const handleCreateCrop = (uuid, process, product, dynamicContent) => {
    setAlert(<Loading title="Đang tạo sổ nhật ký ... " />)
    let data = {
      "startTime": new Date(),
      "uuidOfProductionObject": uuid,
      "processCode": process,
      "uuidOfProductType": product,
      "images": listImage,
      "dynamicContent": dynamicContent,
    }
    axios.post(LINK_API.ASSIGN_CROP, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          handleRawCrop(res.data.data, uuid, data);
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
      })
  }
  const handleDetail = async (uuid) => {
    await cookie.save("uuidOProduction", uuid, { path: "/" });
    props.history.push("/user/detailObjectProduction")
  }
  const handleRawCrop = (dataRaw, uuid, data) => {
    var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
    const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
    const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
    const tx = new EthereumTx(dataRaw.rawFungibleAsset, { chain: 4 });
    tx.sign(Buffer_privatekey)
    const rawTx = '0x' + tx.serialize().toString('hex');
    console.log("data", data)
    const rawtosend = {
      ...dataRaw,
      ...data,
      "raw": rawTx,
      "uuidOfProductionObject": uuid,
      "bc_id": dataRaw.bc_id
    }
    axios.post(LINK_API.SEND_RAW_CROP, rawtosend)
      .then(res => {
        if (res.data.errorCode === 1) {
          // setAlert(<Success onClose={() => handleDetail(uuid)} title="Thành công!" onConfirm={() => handleDetail(uuid)} hideCancel={true} confirmBtnText={t('confirm')} />)
          setAlert(<Loading title="Đang cấp quyền cho vựa sỉ ... " />)
          handleApprove(uuid)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
      })
  }
  const handleApprove = (uuid) => {
    let dataApprove = {
      "uuidOfObject": uuid,
      "uuidOfWholeSaler": formik.values.wholesaler
    }
    axios.post(LINK_API.APPROVE_WHOLESALER, dataApprove)
    .then(res => {
      if (res.data.errorCode === 1) {
         setAlert(<Success onClose={() => handleDetail(uuid)} title="Thành công!" onConfirm={() => handleDetail(uuid)} hideCancel={true} confirmBtnText={t('confirm')} />)
      } else {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
      }
    })
    .catch(err => {
      setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
    })
  }
  const handleCreateObject = (data) => {
    setAlert(<Loading title="Đang tạo lô hàng ... " />)
    Axios.post(LINK_API.CREATE_OBJECT_PRODUCTION, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          handleCreateCrop(res.data.data.uuid, data.processCode, data.productType, data.dynamicContent)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }
  React.useEffect(()=>{
    getShipList();
  },[getShipList])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      wholesaler: '',
      product: listProduct && listProduct[0] ? listProduct[0].value : 'noProduct',
      area: areaListState && areaListState.data && areaListState.data[0] ? areaListState.data[0].value : '',
      process: processListState && processListState.data && processListState.data[0] ? processListState.data[0].value : '',
      type: 1,
      number: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Thông tin bắt buộc"),
      product: Yup.string()
        .required("Thông tin bắt buộc"),
      area: Yup.string()
        .required("Thông tin bắt buộc"),
      process: Yup.string()
        .required("Thông tin bắt buộc"),
      type: Yup.string()
        .required("Thông tin bắt buộc"),
      wholesaler: Yup.string()
        .required("Thông tin bắt buộc"),
        number: Yup.string()
        .required("Thông tin bắt buộc"),
    }),
    onSubmit: async (values) => {
      let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
      dynamicContent = await dynamicContent.toString()
      dynamicContent = await dynamicContent.replace("↵", '')
      setContentDescription(dynamicContent)
      console.log("dynamicContent", dynamicContent)
      let data = {
        "name": values.name,
        "zoneUuid": values.area,
        "processCode": values.process,
        "productType": values.product,
        "type": values.type,
        "options": [],
        "dynamicContent": dynamicContent,
        "images": listImage,
        "quantityOfPackage": values.number
      }

      if (values.product === "noProduct") {
        let dataProduct = {
          name: values.name,
          description: '',
          dynamicContent: dynamicContent,
          glnCheckbox: false,
          gtin: "",
          image: listImage,
          certificates: [],
          category: "Trái cây",
          proposedPrice: "",
          images360: [],
          language: "vi",
          options: [],
          showOrigin: false,
          unit: 2,
        }
        handleCreateProduct(dataProduct, data)
        // props.createObjectProduct(dataProduct, data);
        // props.history.push("/oProduction/list");
      } else {
        // props.createObject(data)
        // props.history.push("/oProduction/list")
        handleCreateObject(data)
      }

    },
  });
  React.useEffect(() => {
    console.log('shipList', shipList)
    setWholeSale(shipList)
  }, [shipList])
  const handleCreateProduct = (product, object) => {
    setAlert(<Loading title="Đang tạo loại sản phẩm ... " />)
    axios.post(LINK_API.CREATE_PRODUCT, product)
      .then(res => {
        if (res.data.errorCode === 1) {
          handleRaw(res.data.data, product, object);
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }
  const handleRaw = (dataRaw, product, object) => {
    var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
    const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
    const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
    const tx = new EthereumTx(dataRaw.raw, { chain: 4 });
    tx.sign(Buffer_privatekey)
    const rawTx = '0x' + tx.serialize().toString('hex');
    let data = {
      ...product,
      uuid: dataRaw.uuid,
      raw: rawTx,
      bc_id: dataRaw.bc_id
    }
    axios.post(LINK_API.SEND_RAW_PRODUCT, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          let dataObject = {
            ...object,
            productType: res.data.data.uuid
          }
          handleCreateObject(dataObject)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })

  }
  React.useEffect(() => {
    renderProductList(1, 100);
    renderProcessList();
    renderAreaList(1, 100);
  }, [renderProductList, renderProcessList, renderAreaList])

  React.useEffect(() => {
    if (productListState.data) {
      if (productListState.data.length > 0) {
        setProduct(productListState.data)
      }
    }
  }, [productListState])

  React.useEffect(() => {
    if (processListState.data) {
      if (processListState.data.length > 0) {
        setProcess(processListState.data)
      }
    }
  }, [processListState])

  React.useEffect(() => {
    if (areaListState.data) {
      if (areaListState.data.length > 0) {
        setArea(areaListState.data)
      }
    }
  }, [areaListState])



  const MAX_LENGTH = 3000;

  const _getLengthOfSelectedText = React.useCallback(() => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }, [editorState])

  const _handleBeforeInput = React.useCallback(() => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return 'handled';
    }
  }, [editorState, _getLengthOfSelectedText])

  const _handlePastedText = React.useCallback((pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      return 'handled';
    }
  }, [editorState, _getLengthOfSelectedText])

  return (
    <>
      {
        permission.agdqr ? <div></div> :
          <>
            <Header />
            <Container className='mt--7' fluid>
              <Card className="card-box mb-5">
                <div className="wizard-steps horizontal">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="p-4">
                      <h5 className="font-size-xl font-weight-bold">{t('oProduction.oInfo')}</h5>
                      {/* <p className="text-black-50 mb-4">{t('oProduction.about')}</p> */}
                      <div className="form-row">
                        <div className="form-group col-md-6 pr-3">
                          <label className="font-weight-bold">{t('oProduction.name')} <span className="text-danger">(*)</span></label>
                          <Input
                            placeholder={t('oProduction.name')}
                            type="text"
                            maxLength={120}
                            value={formik.values.name}
                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                            {...formik.getFieldProps('name')}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6 pl-3">
                        <label className="font-weight-bold">Số lượng thùng <span className="text-danger">(*)</span></label>
                          <Input
                            type="number"
                            name="number"
                            id="numberBox"
                            placeholder='Số lượng thùng'
                            value={formik.values.number}
                            valid={formik.touched.number && formik.errors.number ? false : (formik.values.number ? true : false)}
                            invalid={formik.touched.number && formik.errors.number ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('number')}
                          >
                          </Input>
                          {formik.touched.number && formik.errors.number ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.number}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row ">
                        <div className="form-group col-md-6 pr-3">
                          <label className="font-weight-bold">{t('oProduction.area')} <span className="text-danger">(*)</span></label>
                          <CustomInput
                            type="select"
                            name="customSelect"
                            id="selectType"
                            placeholder={t('oProduction.area')}
                            value={formik.values.area}
                            valid={formik.touched.area && formik.errors.area ? false : (formik.values.area ? true : false)}
                            invalid={formik.touched.area && formik.errors.area ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('area')}
                          >
                            {listArea.map((item, idx) => (
                              <option key={idx} value={item.value}>{item.label}</option>
                            ))}
                          </CustomInput>
                          {formik.touched.area && formik.errors.area ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.area}</p>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6 pl-3">
                          <label className="font-weight-bold" >{t('oProduction.process')} <span className="text-danger">(*)</span></label>
                          <CustomInput
                            type="select"
                            name="customSelect"
                            id="selectType"
                            disabled={true}
                            placeholder={t('oProduction.process')}
                            value={formik.values.process}
                            valid={formik.touched.process && formik.errors.process ? false : (formik.values.process ? true : false)}
                            invalid={formik.touched.process && formik.errors.process ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('process')}
                          >
                            {/* <option  value="">{t('oProduction.process')}</option> */}
                            {listProcess.map((item, idx) => (
                              <option key={idx} value={item.value}>{item.name}</option>
                            ))}
                          </CustomInput>
                          {formik.touched.process && formik.errors.process ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.process}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row ">
                        <div className="form-group col-md-6 pr-3">
                          <label className="font-weight-bold">{t('oProduction.product')} <span className="text-danger">(*)</span></label>
                          <CustomInput
                            type="select"
                            name="customSelect"
                            id="selectType"
                            placeholder={t('oProduction.product')}
                            value={formik.values.product}
                            valid={formik.touched.product && formik.errors.product ? false : (formik.values.product ? true : false)}
                            invalid={formik.touched.product && formik.errors.product ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('product')}
                          >
                            <option value="noProduct">{t('oProduction.none') + formik.values.name}</option>
                            {listProduct.map((item, idx) => (
                              <option key={idx} value={item.value}>{item.name}</option>
                            ))}
                          </CustomInput>
                          {formik.touched.product && formik.errors.product ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.product}</p>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6 pl-3">
                          <label className="font-weight-bold">Vựa sỉ <span className="text-danger">(*)</span></label>
                          <CustomInput
                            type="select"
                            name="customSelect"
                            id="selectType"
                            placeholder='Vựa sỉ'
                            value={formik.values.wholesaler}
                            valid={formik.touched.wholesaler && formik.errors.wholesaler ? false : (formik.values.wholesaler ? true : false)}
                            invalid={formik.touched.wholesaler && formik.errors.wholesaler ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('wholesaler')}
                          >
                            <option disabled value=''>Chọn vựa sỉ</option>
                            {listWholesale.map((item, idx) => (
                              <option key={idx} value={item.value}>{item.label}</option>
                            ))}
                          </CustomInput>
                          {/* <Input
                            type="text"
                            name="wholesaler"
                            id="wholesalerSelect"
                            placeholder='Mã vựa sỉ'
                            value={formik.values.wholesaler}
                            valid={formik.touched.wholesaler && formik.errors.wholesaler ? false : (formik.values.wholesaler ? true : false)}
                            invalid={formik.touched.wholesaler && formik.errors.wholesaler ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('wholesaler')}
                          ></Input> */}
                          {formik.touched.wholesaler && formik.errors.wholesaler ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.wholesaler}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12 pr-3">
                          <label className="font-weight-bold">{t('oProduction.image')}</label>
                          <DropZone
                            max={3}
                            onConfirm={handleAva}
                            data={listImage}
                          />
                          <p id="avaNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                        </div>
                      </div>
                      <div className="form-row ">
                        <div className="form-group col-md-12">
                          <label className="font-weight-bold">{t('oProduction.description')}</label>
                          <Editor
                            handleBeforeInput={_handleBeforeInput}
                            handlePastedText={_handlePastedText}
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor borderEditor"
                            onEditorStateChange={handleChangeEditor}
                            toolbar={{
                              options: ["inline", 'image', 'embedded', 'textAlign', "link", "list",]
                            }}
                          />
                          <p id="desNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                        </div>
                      </div>
                      <div className="form-row ">
                        <div className="form-group col-md-6">
                          <p><span className="text-danger">(*)</span>{t('require')}</p>
                        </div>
                        <div className="form-group col-md-6 d-flex justify-content-end">
                          <Button
                            size="lg"
                            type="submit"
                            className="text-uppercase font-weight-bold font-size-sm"
                            color="neutral-success">
                            {t('confirm')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Card>
            </Container>
          </>
      }
      {alert}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    productList: state.renderProduct,
    processList: state.renderProcess,
    areaList: state.renderArea,
    userInfo: state.userInfo,
    permission: state.permission,
    shipList: state.shipList.options
  }
}

const mapDispatchToProps = (dispatch) => ({
  renderProductList: (page, size) => dispatch({ type: "RENDER_PRODUCT_LIST", page: page, size: size }),
  renderAreaList: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
  renderProcessList: () => dispatch({ type: "RENDER_PROCESS_LIST" }),
  createObject: (data) => dispatch({ type: "CREATE_OBJECT", data: data }),
  createObjectProduct: (dataProduct, data) => dispatch({ type: "CREATE_OBJECT_PRODUCT", product: dataProduct, object: data }),
  getShipList: () => dispatch({type: "GET_SHIP_LIST"})
  // setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateObjectProduction));