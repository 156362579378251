import DropZone from "components/dropZone";
import { LINK_API } from "constants/API";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
//formik
import { useFormik } from 'formik';
import CurrencyInput from 'react-currency-input';
import htmlToDraft from 'html-to-draftjs';
import React from "react";
import cookie from "react-cookies";
import { Editor } from 'react-draft-wysiwyg';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { CustomInput, Input, Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import QRCode from "react-qr-code";
import DownloadIcon from "mdi-react/DownloadIcon";
import PrintIcon from "mdi-react/PrinterIcon";
import SearchIcon from "mdi-react/SearchIcon";
import download from "downloadjs"
import * as htmlToImage from 'html-to-image';
import ReactToPrint from 'react-to-print';
import PrintObject from "../../../objectProduction-page/detail-page/printTemplate"
//style
import "view/css/select.css";
import "../css/edit.css"
import * as Yup from 'yup';
const Step1 = React.forwardRef((props, ref) => {
    const t = props.t;
    const uuid = cookie.load("uuidProduct");
    const [listImage, setImage] = React.useState([]);
    const [listCert, setCert] = React.useState([]);
    const componentRef = React.useRef();
    const [editorState, setEditor] = React.useState(EditorState.createEmpty());
    const [modal, setModal] = React.useState(false);
    const handleChangeEditor = async (editorState) => {
        document.getElementById("desNoti").innerHTML = "";
        setEditor(editorState)
    }
    const downloadQR = async () => {
        document.getElementById("downloadBtn").innerHTML = "Đang tải...";
        var node = document.getElementById('qrObject');

        htmlToImage.toPng(node)
            .then(async function (dataUrl) {
                await download(dataUrl, 'qrCodeObject.png');
                toggleModal();
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    };
    const handleAva = (data) => {
        setImage(data);
        document.getElementById("avaNoti").innerHTML = "";
    }
    const handleCert = (data) => {
        setCert(data)
        document.getElementById("certNoti").innerHTML = "";
    }
    React.useImperativeHandle(ref, () => ({
        handleMergeData() {
            formik.submitForm()
        }
    }));
    const toggleModal = () => {
        setModal(!modal)
    }
    const formik = useFormik({
        initialValues: {
            name: '',
            gtin: '',
            unit: '',
            type: '',
            price: ''
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Thông tin bắt buộc"),
            unit: Yup.string()
                .required("Thông tin bắt buộc"),
            type: Yup.string()
                .required("Thông tin bắt buộc"),
            gtin: Yup.string()
                .test(t('Mã GTIN không hợp lệ'), t('Mã GTIN không hợp lệ'),
                    function (value) {
                        return validateGtin(value);
                    }
                )
        }),
        onSubmit: async (values) => {
            // if (listImage.length === 0 || listCert.length === 0) {
            //     if (listImage.length === 0) {
            //         document.getElementById("avaNoti").innerHTML = "Vui lòng tải lên ảnh sản phẩm (ít nhất 1 ảnh)";
            //     }
            //     if (listCert.length === 0) {
            //         document.getElementById("certNoti").innerHTML = "Vui lòng tải lên ảnh chứng nhận sản phẩm (ít nhất 1 ảnh)";
            //     }
            //     return;
            // }
            let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
            dynamicContent = await dynamicContent.toString()
            dynamicContent = await dynamicContent.replace("↵", '')
            // if (dynamicContent === "<p></p>\n") {
            //     document.getElementById("desNoti").innerHTML = "Vui lòng điền thông tin miêu tả sản phẩm";
            // }
            let data = {
                ...values,
                ava: listImage,
                cert: listCert,
                htmlString: dynamicContent
            }
            props.saveData(data, false)
        },
    });
    const dataStepOne = props.data;
    React.useEffect(() => {
        formik.setFieldValue("name", dataStepOne.name)
        formik.setFieldValue("price", dataStepOne.proposedPrice)
        formik.setFieldValue("gtin", dataStepOne.gtin)
        formik.setFieldValue("type", dataStepOne.category)
        formik.setFieldValue("unit", dataStepOne.unit)
        let temp = [];
        if (dataStepOne.image) {
            for (let i = 0; i < dataStepOne.image.length; i++) {
                temp.push(dataStepOne.image[i].secure_url)
            }
        }
        setImage(temp)
        let tempCert = [];
        if (dataStepOne.certificates) {
            for (let i = 0; i < dataStepOne.certificates.length; i++) {
                tempCert.push(dataStepOne.certificates[i].secure_url)
            }
        }
        setCert(tempCert)
        if (dataStepOne.dynamicContent) {
            let dynamicContent = dataStepOne.dynamicContent;
            let contentBlock = htmlToDraft(dynamicContent);
            if (contentBlock && dataStepOne.dynamicContent && dataStepOne.dynamicContent !== "") {
                let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                let editorState = EditorState.createWithContent(contentState);
                setEditor(editorState)
            }
        }
    }, [dataStepOne])
    const handleChangePrice = (event, maskedvalue, floatvalue) => {
        formik.setFieldValue("price", maskedvalue, true)
    }

    const validateGtin = function (valueInt) {

        if (!valueInt) return true;
        const value = valueInt.toString();
        var barcode = value.substring(0, value.length - 1);
        var checksum = parseInt(value.substring(value.length - 1), 10);
        var calcSum = 0;
        var calcChecksum = 0;

        barcode.split('').map(function (number, index) {
            number = parseInt(number, 10);
            if (value.length % 2 === 0) {
                index += 1;
            }
            if (index % 2 === 0) {
                calcSum += number;
            }
            else {
                calcSum += number * 3;
            }
        });

        calcSum %= 10;
        calcChecksum = (calcSum === 0) ? 0 : (10 - calcSum);

        if (calcChecksum !== checksum) {
            return false;
        }

        return true;

    };

    return (
        <>
            <div className="wizard-steps horizontal">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-4">
                        <h5 className="font-size-xl font-weight-bold">{t('product.productInfo')}</h5>
                        <p className="text-black-50 mb-4">{t('product.introProduct')}</p>
                        <div className="form-row">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{t('product.qrCode')}</label>
                                <div className="text-center mt-3 d-flex flex-row w-100">
                                    <QRCode value={"https://txngdanang.vn/q/" + "P" + props.userInfo.uuid + props.data.uuid} size={200} />
                                    {/* <div className="text-left ml-3">
                                        <div className="form-group mb-0">
                                            <label className="font-weight-bold mr-1 sizeCode" >Mã sản phẩm:</label>
                                            <label className="font-weight-bold mr-1 sizeCode text-info" >{"P" + props.userInfo.uuid + uuid}</label>
                                        </div>
                                        <div className="form-group ">
                                            <Button onClick={toggleModal} className="hover-scale-sm btn-block" color="neutral-success" id="oProductionDownload" >
                                                <span className="btn-wrapper--icon">
                                                    <DownloadIcon size={18} />
                                                </span>
                                                <span className="btn-wrapper--label">{t('download')}</span>
                                            </Button>
                                        </div>
                                        <div className="form-group ">
                                            <ReactToPrint
                                                trigger={() => <Button className="hover-scale-sm btn-block" color="neutral-success" id="oProductionDownload" >
                                                    <span className="btn-wrapper--icon">
                                                        <PrintIcon size={18} />
                                                    </span>
                                                    <span className="btn-wrapper--label">{t('print')}</span>
                                                </Button>}
                                                content={() => componentRef.current}
                                            />
                                            <div id="printObject" style={{ display: "none" }}>
                                                <PrintObject
                                                    data={dataStepOne}
                                                    name={props.userInfo.name}
                                                    uuid={"P" + props.userInfo.uuid + uuid}
                                                    ref={componentRef}
                                                    logo={props.userInfo.logo ? props.userInfo.logo.secure_url : ""} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <Button className="hover-scale-sm btn-block" color="neutral-success" id="oProductionDownload" onClick={() => window.open("https://txngdanang.vn/q/" + "P" + props.userInfo.uuid + uuid)} >
                                                <span className="btn-wrapper--icon">
                                                    <SearchIcon size={18} />
                                                </span>
                                                <span className="btn-wrapper--label">{t('search')}</span>
                                            </Button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="form-group col-md-6 pl-3">
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('product.name')} <span className="text-danger">(*)</span></label>
                                    <Input
                                        placeholder={t('product.name')}
                                        type="text"
                                        value={formik.name}
                                        valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                        invalid={formik.touched.name && formik.errors.name ? true : false}
                                        {...formik.getFieldProps('name')}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('product.from')} <span className="text-danger">(*)</span></label>
                                    <CustomInput type="select" id="selectUnit" placeholder={t('product.from')}
                                        value={formik.unit}
                                        onChange={formik.handleChange}
                                        valid={formik.touched.unit && formik.errors.unit ? false : (formik.values.unit ? true : false)}
                                        invalid={formik.touched.unit && formik.errors.unit ? true : false}
                                        name="customSelect"
                                        {...formik.getFieldProps('unit')}
                                    >
                                        <option value="">{t('product.from')}</option>
                                        <option value={1}>{t('product.kg')}</option>
                                        <option value={2}>{t('product.product')}</option>
                                    </CustomInput>
                                    {formik.touched.unit && formik.errors.unit ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.unit}</p>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('product.link')} <span className="text-danger">(*)</span></label>
                                    <CustomInput
                                        type="select"
                                        name="customSelect"
                                        id="selectType"
                                        placeholder={t('product.link')}
                                        value={formik.type}
                                        valid={formik.touched.type && formik.errors.type ? false : (formik.values.type ? true : false)}
                                        invalid={formik.touched.type && formik.errors.type ? true : false}
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps('type')}
                                    >
                                        <option value="">{t('product.link')}</option>
                                        <option value={t('product.meat')}>{t('product.meat')}</option>
                                        <option value={t('product.veget')}>{t('product.veget')}</option>
                                        <option value={t('product.fruit')}>{t('product.fruit')}</option>
                                        <option value={t('product.cold')}>{t('product.cold')}</option>
                                        <option value={t('product.spice')}>{t('product.spice')}</option>
                                        <option value={t('product.dry')}>{t('product.dry')}</option>
                                        <option value={t('product.beer')}>{t('product.beer')}</option>
                                        <option value={t('product.milk')}>{t('product.milk')}</option>
                                    </CustomInput>
                                    {formik.touched.type && formik.errors.type ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.type}</p>
                                    ) : null}
                                </div>

                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{t('product.retailPrice')}</label>
                                <CurrencyInput
                                    className={formik.touched.price && formik.errors.price ? false : (formik.values.price ? "form-control is-valid" : "form-control")}
                                    placeholder={t('product.retailPrice')}
                                    value={formik.values.price}
                                    precision={0}
                                    maxLength="11"
                                    valid={formik.touched.price && formik.errors.price ? false : (formik.values.price ? true : false)}
                                    onChangeEvent={handleChangePrice} />
                            </div>
                            <div className="form-group col-md-6 pl-3">
                                <label className="font-weight-bold" >{t('product.gtin')}</label>
                                <Input
                                    placeholder={t('product.gtin')}
                                    type="number"
                                    valid={formik.touched.gtin && formik.errors.gtin ? false : (formik.values.gtin ? true : false)}
                                    invalid={formik.touched.gtin && formik.errors.gtin ? true : false}
                                    {...formik.getFieldProps('gtin')}
                                />
                                {formik.touched.gtin && formik.errors.gtin ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.gtin}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{t('product.image')} </label>
                                <DropZone
                                    max={3}
                                    onConfirm={handleAva}
                                    data={listImage}
                                    api={true}
                                    uuid={uuid}
                                    path={LINK_API.UPDATE_IMG_PRODUCT}
                                />
                                <p id="avaNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>

                            <div className="form-group col-md-6 pl-3">
                                <label className="font-weight-bold">{t('product.cert')} </label>
                                <DropZone
                                    api={true}
                                    max={3}
                                    uuid={uuid}
                                    path={LINK_API.UPDATE_IMG_PRODUCT_CERT}
                                    onConfirm={handleCert}
                                    data={listCert} />
                                <p id="certNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>
                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">{t('product.productInfo')}</label>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor borderEditor"
                                    onEditorStateChange={handleChangeEditor}
                                    toolbar={{
                                        options: ["inline", 'image', 'embedded', 'textAlign', "link",]
                                    }}
                                />
                                <p id="desNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>
                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <p><span className="text-danger">(*)</span> {t('require')}</p>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal zIndex={2000} centered isOpen={modal} toggle={toggleModal} className="widthFrame">
                    <ModalHeader toggle={toggleModal}>{t('download')}</ModalHeader>
                    <ModalBody>
                        <div className="qrFrame" id="qrObject">
                            &nbsp;
                            <div className="outSide">
                                <QRCode value={"https://txngdanang.vn/q/" + "P" + props.userInfo.uuid + props.data.uuid} size={165} className="qrMargin" />
                            </div>
                            <div className="inSide">
                                <p className="text-white font-weight-bold font-size-xl">{props.data.name}</p>
                                <p className="text-white font-weight-bold font-size-xl">{"P" + props.userInfo.uuid + props.data.uuid}</p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="neutral-danger" className="btn-link-dark" onClick={toggleModal}>{t('cancel')}</Button>{' '}
                        <Button id="downloadBtn" color="info" className="ml-auto" onClick={downloadQR}>{t('save')}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    )
})
const mapStateToProps = (state) => {
    return {
        productList: state.renderProduct,
        processList: state.renderProcess,
        areaList: state.renderArea,
        userInfo: state.userInfo.data
    }
}
const mapDispatchToProps = (dispatch) => ({
    saveData: (dataOne, check) => dispatch({ type: "SAVE_DATA_EDIT_PRODUCT", dataOne: dataOne, check: check }),
    renderEmp: (page, size) => dispatch({ type: "RENDER_EMPLOYEE_LIST", page: page, size: size }),
});

export default withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Step1));