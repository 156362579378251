import axios from "axios";
import Header from "components/Headers/Header";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from "constants/API";
import PenIcon from "mdi-react/LeadPencilIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import DownloadIcon from "mdi-react/DownloadIcon";
import CloseIcon from "mdi-react/CloseIcon"
import SearchIcon from "mdi-react/SearchIcon"
import PrintIcon from "mdi-react/PrinterIcon"
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import CopyIcon from "mdi-react/ContentCopyIcon"
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, Container, FormGroup, Input, UncontrolledTooltip, Modal, ModalBody, ModalHeader } from 'reactstrap';
import cookie from "react-cookies";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import "../../css/reactTable.css";
import ReactToPrint from 'react-to-print';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import EditPositionStamp from '../stamp-history-page/editPositionStamp';
import StampSample from "../stamp-history-page/sampleStamp"
import Select from 'react-select';
import ConfirmAlert from "components/alert/confirmAlert.js";
import html2pdf from 'html2pdf.js'
const HTMLToPDF = require('html-to-pdf');


const DiaryRecord = props => {
  const [loadingState, setLoading] = React.useState(true)
  const [recordList, setRecordList] = React.useState([])
  const [dataSheet, setDataSheet] = React.useState([])
  const [alert, setAlert] = React.useState(null)

  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [startID, setStartID] = React.useState(null)
  const [endID, setEndID] = React.useState(null)
  const [type, setType] = React.useState({
    value: "BOX",
    label: "Tem lô hàng"
  })
  const [productType, setProductType] = React.useState([])
  const [productTypeID, setProductTypeID] = React.useState([])
  const [choosedSampleStamp, setChoosedSampleStamp] = React.useState({})
  const [diaryRecordID, setDiaryRecordID] = React.useState({})
  const [showPrint, setShowPrint] = React.useState(false)


  const componentRef = React.useRef();

  const [data1, setData1] = React.useState(null)
  const [data2, setData2] = React.useState(null)

  const [numberOfRow, setNumberOfRow] = React.useState(0)
  const [numberOfColumn, setNumberOfColumn] = React.useState(5)

  let _exporter = () => { };

  const {
    t,
    userInfo
  } = props;

  const userName = props.userInfo && props.userInfo.data && props.userInfo.data.name ? props.userInfo.data.name : "";

  const handlePrintStamp = React.useCallback(async (value) => {

    // const startId = value.startID;
    // const endId = value.endID;
    // await setAlert(<Loading />)

    // const productName = value.productName.value;
    // const splitProductName = productName.split(" - ")[0]
    // // print(startId, endId, splitProductName);

  }, [])


  const handleModal = () => {
    setModal(!modal)
  }

  const handleModal2 = () => {
    setModal2(!modal2)
  }

  const handleModal3 = () => {
    setModal3(!modal3)
  }


  const enterValue = (value) => {
    const ChildComponent = (
      <div>
        <Formik
          initialValues={{ startID: 0, endID: 0, productName: productType }}
          validationSchema={
            Yup.object().shape({
              productName: Yup.string()
                .required(t('require')),
              startID: Yup.number()
                .required(t('require'))
                .integer(t('stamp.integer'))
                .test(t('stamp.endIDLessStartID'), t('stamp.endIDLessStartID'),
                  function (value) {
                    return value <= this.parent.endID;
                  }
                ),

              endID: Yup.number()
                .required(t('require'))
                .integer(t('stamp.integer'))
                .test(t('stamp.max'), t('stamp.max'),
                  function (value) {
                    return value - this.parent.startID + 1 <= 300;
                  }
                )
            })
          }
          onSubmit={(value) => handlePrintStamp(value)}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Field
                name="typeStamp"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      Loại tem
                    </label>
                    <Select
                      placeholder="Chọn loại tem"
                      options={[
                        {
                          label: "Tem lô hàng",
                          value: "BOX"
                        },
                        {
                          label: "Tem sản phẩm",
                          value: "UNIT"
                        }
                      ]}
                      value={type}
                      onChange={(selectedOption) => {
                        setType(selectedOption)
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#3c44b1'
                        }
                      })}
                    />
                  </FormGroup>
                )}
              />

              <Field
                name="startID"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('stamp.startID')}
                    </label>
                    <Input {...props} type="number" {...props.field} placeholder={t('stamp.startID')} />
                  </FormGroup>
                )}
              />
              {errors.startID && touched.startID ? <div style={{ color: "red" }}>{errors.startID}</div> : null}
              <Field name="endID"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('stamp.endID')}
                    </label>
                    <Input {...props} type="number" {...props.field} placeholder={t('stamp.endID')} />
                  </FormGroup>
                )}
              />
              {errors.endID ? (
                <div style={{ color: "red" }}>{errors.endID}</div>
              ) : null}

              <div>
                <Button
                  className="mt-3"
                  color="info"
                  disabled={
                    !values.startID
                    || !values.endID
                    || parseInt(values.startID) > parseInt(values.endID)
                    || parseInt(values.endID) - parseInt(values.startID) + 1 > 300
                  }
                  onClick={
                    () => {
                      const numberStamp = parseInt(values.endID) - parseInt(values.startID) + 1;
                      setNumberOfRow(Math.ceil(numberStamp / 5))
                      setData1({
                        userName: userName,
                        values: values,
                        choosedSampleStamp: choosedSampleStamp
                      })
                      setModal(false)
                      setModal2(true)
                    }
                  }>
                  Tiếp theo
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
    return ChildComponent
  }


  const handleSaveData2 = (data2) => {
    setData2(data2)
    setModal3(true)
    setModal2(false)
  }

  const enterValue2 = () => {
    return <EditPositionStamp data1={data1} saveData2={handleSaveData2} lengthStamp={type.value === "BOX" ? 11 : 13} />
  }

  const handleSubmitPrintStamp = (data1, lengthOfID) => {
    console.log("handleSubmitPrintStamp", data1, lengthOfID)
    const body = {
      "productUuid": productTypeID,
      "startId": data1.startID.toString().padStart(lengthOfID, "0"),
      "endId": data1.endID.toString().padStart(lengthOfID, "0"),
      "sampleStamp": choosedSampleStamp.uuid,
      "diaryLedgerUuid": diaryRecordID
    }

    console.log("body", body)
    axios.post(LINK_API.CREATE_PRINT_STAMP, body)
      .then(() => {
        axios.get(LINK_API.GET_ALL_DIARY_RECORD)
          .then(res => {
            genTable(res.data.data)
          })
          .catch(err => {
          })
      })
      .catch(() => { })
  }

  const enterValue3 = () => {

    if (data1 && data2) {
      return (
        <div>
          <div className="mt-3">
            <div className="mb-2">
              Số cột:
          </div>
            <div>
              <Input
                type="number"
                value={numberOfColumn}
                onChange={(e) => setNumberOfColumn(e.target.value)}
              />
            </div>
            {
              numberOfColumn ?
                null
                :
                <div className="text-danger font-size-sm mt-2">
                  Thiếu số cột
              </div>
            }
          </div>
          <div className="mt-3">
            <div className="mb-2">
              Số hàng:
          </div>
            <div>
              <Input
                type="number"
                value={numberOfRow}
                onChange={(e) => setNumberOfRow(e.target.value)}
              />
            </div>
            {
              numberOfRow ?
                null
                :
                <div className="text-danger font-size-sm mt-2">
                  Thiếu số hàng
              </div>
            }
          </div>
          <div className="d-flex flex-row justify-content-center mt-3">
            {/* <ReactToPrint
              onAfterPrint={() => handleSubmitPrintStamp(data1.values, type === "BOX" ? 11 : 13)}
              trigger={() => <Button color="info" onClick={() => { }}>
                <span className="btn-wrapper--icon">
                  In tem
                </span>
              </Button>}
              content={() => componentRef.current}
            /> */}
            <Button color="info" onClick={() => {
              handleSubmitPrintStamp(data1.values, type === "BOX" ? 11 : 13)
              setModal3(false);
              setShowPrint(true)
            }}>
              <span className="btn-wrapper--icon">
                In tem
                </span>
            </Button>

            {/* <div id="printObject" style={{ display: "none" }}>
              <StampSample
                lengthStamp={type.value === "BOX" ? 11 : 13}
                values={data1.values}
                choosedSampleStamp={data2}
                userName={userName}
                numberCol={numberOfColumn}
                numberRow={numberOfRow}
                ref={componentRef} />
            </div> */}
          </div>
        </div>

      )
    }

  }

  const handleConfirmDelete = (id) => {
    setAlert(<Loading />)

    axios.defaults.headers.common.Authorization = userInfo.data.token
    axios({
      method: 'DELETE',
      url: LINK_API.DELETE_DIARY_RECORD + id
    }).then(res => {
      setAlert(<Success onClose={() => setAlert(null)} hideCancel={true} hideCancel={() => setAlert(null)} onConfirm={() => setAlert(null)} confirmBtnText={t('close')} />)
      axios.get(LINK_API.GET_ALL_DIARY_RECORD)
        .then(res => {
          genTable(res.data.data)
        })
        .catch(err => {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        })
    }).catch(err => {
      setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
    })
  }

  const handleDelete = (id) => {
    setAlert(<ConfirmAlert
      child='Bạn chắc chắn xoá hồ sơ này!'
      onConfirm={() => handleConfirmDelete(id)}
      hideCancel={true}
    />)
  }

  const genTable = React.useCallback((data) => {
    const dataReverse = data.reverse()
    const dataRender = dataReverse.map((item, index) => {
      return {
        id: index + 1,
        name: item.name,
        record: `${item.CropInfo && item.CropInfo.ProductTypeInfo && item.CropInfo.ProductTypeInfo.name}`,
        sampleStamp: (item.StampSampleInfo && item.StampSampleInfo.name) || "",
        countPrintedStamp: item.PrintStampInfo ? item.PrintStampInfo.stampNo : 0,
        distributor: (item.DistributorInfo && item.DistributorInfo.name) || "",
        actions: <div className="d-flex flex-row justify-content-center">
          <Button onClick={() => {
            cookie.save("editDiary", item.uuid, { path: "/" });
            props.history.push("/user/editRecord")
          }} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipEdit" + index}>
            <span className="btn-wrapper--icon d-flex">
              <PenIcon
                size={18}
                className="text-success font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipEdit" + index}>
            {t('edit')}
          </UncontrolledTooltip>

          <Button onClick={() => {
            cookie.save("isDuplicate", item.uuid, { path: "/" });
            props.history.push("/user/createRecord")
          }} color="neutral-warning" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"duplicate" + index}>
            <span className="btn-wrapper--icon d-flex">
              <CopyIcon
                size={18}
                className="text-warning font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"duplicate" + index}>
            Sao chép
          </UncontrolledTooltip>

          <Button onClick={() => handleDelete(item.uuid)} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"delete" + index}>
            <span className="btn-wrapper--icon d-flex">
              <CloseIcon
                size={18}
                className="text-danger font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"delete" + index}>
            Xoá
          </UncontrolledTooltip>

          <Button onClick={() => {
            cookie.save("diaryRecord", item.uuid, { path: "/" });
            props.history.push("/user/activatedStamp")
          }} color="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"detail" + index}>
            <span className="btn-wrapper--icon d-flex">
              <SearchIcon
                size={18}
                className="text-info font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"detail" + index}>
            Xem danh sách kích hoạt tem
          </UncontrolledTooltip>

          {
            item.StampSampleInfo ?
              <>
                <Button onClick={() => {
                  setStartID(item.startId)
                  setEndID(item.endId)
                  setModal(true)
                  setChoosedSampleStamp(item.StampSampleInfo)
                  setProductType(item.CropInfo.ProductTypeInfo.name)
                  setProductTypeID(item.CropInfo.ProductTypeInfo.uuid)
                  setDiaryRecordID(item.uuid)
                }} color="neutral-first" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"printstamp" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <PrintIcon
                      size={18}
                      className="text-first font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"printstamp" + index}>
                  In tem
          </UncontrolledTooltip>
              </>
              :
              <>
                <Button onClick={() => { }} disabled color="neutral-first" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"printstamp" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <PrintIcon
                      size={18}
                      className="text-first font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"printstamp" + index}>
                  Chưa có mẫu tem dùng để in
          </UncontrolledTooltip>
              </>
          }

        </div>
      }
    })

    setRecordList(dataRender)
    setLoading(false)
  }, [t])

  React.useEffect(() => {
    axios.defaults.headers.common['Authorization'] = userInfo.data.token;
    axios.get(LINK_API.GET_ALL_DIARY_RECORD)
      .then(res => {
        genTable(res.data.data)
      })
      .catch(err => {
      })
  }, []);

  React.useEffect(() => {
    axios.defaults.headers.common['Authorization'] = userInfo.data.token;
    axios.get(LINK_API.GET_ALL_DIARY_RECORD)
      .then(res => {
        genTable(res.data.data)
      })
      .catch(err => {
      })
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 50,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: "Tên",
        accessor: "name",
        sortable: false,
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.name) {
            const timeInRow = row.name.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Sổ nhật ký",
        accessor: "record",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.record) {
            const timeInRow = row.record.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Mẫu tem",
        accessor: "sampleStamp",
        sortable: false,
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.sampleStamp) {
            const timeInRow = row.sampleStamp.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Vựa sỉ",
        accessor: "distributor",
        sortable: false,
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.distributor) {
            const timeInRow = row.distributor.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Số lượng in xtem",
        accessor: "countPrintedStamp",
        filterable: false
      },
      {
        Header: "Hành động",
        accessor: "actions",
        sortable: false
      },
    ],
    [t]
  );

  const valueDataSheet = [
    {
      name: "Tên hồ sơ",
      value: "name"
    },
    {
      name: "Tên sản phẩm",
      value: "productType"
    },
    {
      name: "Mẫu tem",
      value: "stampSample"
    },
    {
      name: "Vựa sỉ",
      value: "distributorName"
    },
    {
      name: "Quy trình",
      value: "process"
    },
    {
      name: "Mô tả",
      value: "description"
    },
    {
      name: "Số lượng in tem",
      value: "stampNo"
    },
  ]


  const handleExportReport = async () => {
    setAlert(<Loading />)

    axios.defaults.headers.common.Authorization = userInfo.data.token
    let dataReponse = await axios({
      method: 'GET',
      url: LINK_API.EXPORT_DATA_DIARY_RECORD
    })

    if (dataReponse && dataReponse.data && dataReponse.data.data) {
      setDataSheet(dataReponse.data.data)
      _exporter.save();
    }
    setAlert(null)
  }


  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        {
          showPrint ?
            <Card className="card-box mb-5">
              <div className="card-header py-3 d-flex justify-content-between">
                <div className=" font-weight-bold card-header--title font-size-lg">{"Danh sách tem in xem trước"}</div>
                <div className="card-header--actions">
                  <Button onClick={async () => {
                    setAlert(<Loading />)
                    var element = document.getElementById('element-to-print');
                    var opt = {
                      filename: 'Tem_truy_xuat_nguon_goc.pdf',
                      image: { type: 'png', quality: 1 },
                      html2canvas: { scale: 2 },
                      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
                    };
                    await html2pdf().from(element).set(opt).save();
                    setAlert(null)
                    setShowPrint(false)
                  }} color="info">
                    <span className="btn-wrapper--icon">
                      <PrintIcon size={16} />
                    </span>
                    <span className="btn-wrapper--label">{"Tải xuống"}</span>
                  </Button>
                </div>
              </div>
              <div className="d-flex justify-content-center">
              <div id="element-to-print">
                <StampSample
                  lengthStamp={type.value === "BOX" ? 11 : 13}
                  values={data1.values}
                  choosedSampleStamp={data2}
                  userName={userName}
                  numberCol={numberOfColumn}
                  numberRow={numberOfRow}
                  ref={componentRef} />
              </div>
              </div>
            </Card>
            :
            <Card className="card-box mb-5">
              <div className="card-header py-3 d-flex justify-content-between">
                <div className=" font-weight-bold card-header--title font-size-lg">{"Hồ sơ truy xuất"}</div>
                <div className="card-header--actions">
                  <Button onClick={() => handleExportReport()} color="info">
                    <span className="btn-wrapper--icon">
                      <DownloadIcon size={16} />
                    </span>
                    <span className="btn-wrapper--label">Xuất báo cáo</span>
                  </Button>
                  <ExcelExport
                    data={dataSheet}
                    fileName={"Hồ sơ truy xuất"}
                    ref={(exporter) => {
                      if (exporter) {
                        _exporter = exporter
                      }
                    }}
                  >
                    {valueDataSheet.map((field) =>
                      (<ExcelExportColumn field={field.value} title={field.name} width={200} />)
                    )}
                  </ExcelExport>
                  <Button onClick={() => {
                    cookie.save("isDuplicate", false, { path: "/" });
                    props.history.push("/user/createRecord")
                  }} color="info">
                    <span className="btn-wrapper--icon">
                      <PlusCircle size={16} />
                    </span>
                    <span className="btn-wrapper--label">{t('product.add')}</span>
                  </Button>
                </div>
              </div>
              <div className="divider" />
              <div className="card-body pt-2 px-4 pb-4">
                <ReactTable
                  noDataText={t('noData')}
                  data={recordList}
                  columns={columns}
                  previousText={"<"}
                  nextText={">"}
                  rowsText={t('row')}
                  ofText="/"
                  defaultPageSize={5}
                  showPaginationBottom={true}
                  sortable={false}
                  resizable={true}
                  PaginationComponent={Pagination}
                  className="-striped -highlight"
                  loading={loadingState}
                  LoadingComponent={LoadingTable}
                />
              </div>
            </Card>

        }
        {alert}
        <Modal zIndex={2000} centered isOpen={modal} toggle={handleModal}>
          <ModalHeader className="d-flex" toggle={handleModal}>
            Nhập thông tin in trên tem
          </ModalHeader>
          <ModalBody>
            {enterValue()}
          </ModalBody>
        </Modal>
        <Modal zIndex={2000} centered isOpen={modal2} toggle={handleModal2}>
          <ModalHeader className="d-flex" toggle={handleModal2}>
            Chỉnh sửa thông tin trên tem in
          </ModalHeader>
          <ModalBody>
            {enterValue2()}
          </ModalBody>
        </Modal>
        <Modal zIndex={2000} centered isOpen={modal3} toggle={handleModal3}>
          <ModalHeader className="d-flex" toggle={handleModal3}>
            Thay đổi số hàng, số cột
          </ModalHeader>
          <ModalBody>
            {enterValue3()}
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => ({
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(DiaryRecord));