/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from 'axios';
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import { LINK_API } from 'constants/API';
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// reactstrap components
import {
  Container, DropdownItem, DropdownMenu,
  DropdownToggle,
  Media, Nav, Navbar, UncontrolledDropdown
} from "reactstrap";
import './index.css';
toast.configure();
const AdminNavbar = (props) => {
  const userInfo = props.userInfo;
  // const data_user = props.userInfo.data;
  const cleanStore = props.cleanStore;
  const history = props.history;
  const getUserProfile = props.getUserProfile;
  const getPermission = props.getPermission;
  const [alert, setAlert] = React.useState(null)
  const t = props.t;
  const [info, setInfo] = React.useState({
    name: "",
    ava: "", role: ""
  })
  const userProfile = props.userProfile
  React.useEffect(() => {
    getUserProfile();
  }, [getUserProfile])
  React.useEffect(() => {
    getPermission();
  }, [getPermission])
  // React.useEffect(() => {
  //   if (permissionData) {
  //     if (permissionData.planName) {
  //       setPlan(permissionData.planName)
  //     }
  //   }
  // }, [permissionData])
  React.useEffect(() => {
    async function handleSetInfo(data, userProfile) {
      if (data.uuid !== data.ownedBy) {
        data.role = 'SM'
      }
      if (data) {
        setInfo({
          name: userProfile.name,
          ava: userProfile.avatar && userProfile.avatar[0] ? userProfile.avatar[0].secure_url : userProfile.avatar && userProfile.avatar.secure_url ? userProfile.avatar.secure_url : 'https://res.cloudinary.com/agridential/image/upload/v1603252735/Logo/logo_sg7qlp.png',
        })
      }
    }
    handleSetInfo(userInfo, userProfile)
  }, [userInfo, userProfile])
  React.useEffect(() => {
    if (!userInfo.token) {
      cleanStore();
      history.push("/auth/login")
    }
  }, [userInfo, cleanStore, history])
  const pushNoti = props.pushNoti
  React.useEffect(() => {
    // if (pushNoti.tr) {
    //   if (pushNoti.color === "success") {
    //     toast.success(pushNoti.message, { transition: Zoom, position: "top-right" });
    //   } else {
    //     if (pushNoti.color === "danger") {
    //       toast.error(pushNoti.message, { transition: Zoom, position: "top-right" });
    //     }
    //     if (pushNoti.color === "warning") {
    //       toast.warning(pushNoti.message, { transition: Zoom, autoClose: 2000, position: "top-right" });
    //     }
    //   }
    // }
  }, [pushNoti])
  const handleSignOut = async () => {
    await setAlert(<Loading />)
    hanldeChangeRoute();
  }
  const hanldeChangeRoute = () => {
    axios.defaults.headers.common['Authorization'] = userInfo.token;
    axios.get(LINK_API.SIGN_OUT)
      .then(res => {
        if (res.data.errorCode === 1) {
          props.cleanStore();
          props.history.push('/auth/login')
        } else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        }
      })
      .catch(err => {
        setAlert(null)
        props.history.push('/PageError505')
      })
  }
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle bg-white">
                    <img
                      alt="..."
                      src={info.ava}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {info.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Xin chào!</h6>
                </DropdownItem>
                <DropdownItem to={
                  props.userInfo.type === 'M'? "/user/profile" :
                  props.userInfo.type === 'F' ? "/distributor/profile":
                  props.userInfo.type === 'Z' ? "/admin/profile" :
                  props.userInfo.type === 'S' ? '/sadmin/profile' :
                  props.userInfo.type === 'U' ? '/market/profile' :
                  props.userInfo.type === 'G' ? '/guard/profile' :
                  props.userInfo.type === 'W' ? '/wholesaler/profile' :
                  props.userInfo.type === 'FA' ? '/farmer/profile' :
                  '/auth/profile'} tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Tài khoản</span>
                </DropdownItem>
                <div className='divider' />
                <DropdownItem href="#pablo" onClick={handleSignOut}>
                  <i className="ni ni-user-run" />
                  <span>Đăng xuất</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
      {alert}

    </>
  );
}

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  pushNoti: state.pushNoti,
  userInfo: state.userInfo.data,
  userProfile: state.userProfile.data,
});

const mapDispatchToProps = (dispatch) => ({
  saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
  cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
  getUserProfile: () => dispatch({ type: "GET_USER_PROFILE" }),
  getPermission: () => dispatch({ type: "GET_PERMISSION" })
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
