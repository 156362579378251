import {
  CREATE_DISTRIBUTOR,
  PUSH_NOTI_PRODUCT_SUCCESS,
  DEACTIVE_NOTI_PRODUCT,
  GET_DISTRIBUTOR_LIST
} from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import responseCode from 'constants/errorCode.js'

const getToken = (state) => state.userInfo


// Create Distributor
function * watchCreateDistributor (data) {
  const token = yield select(getToken)
  const { response, error } = yield call(createDistributor, data.data, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo đối tác thành công", color: 'success' })
      yield put({ type: GET_DISTRIBUTOR_LIST, page: 1, size: 5})
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      if (responseCode[`${result.name}`]) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'error' })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo đối tác thất bại", color: 'error' })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  } else {
    if (error.response.data.name) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo đối tác thất bại", color: 'error' })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
}


function createDistributor (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.CREATE_DISTRIBUTOR, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


const handlePartner = [
  takeLatest(CREATE_DISTRIBUTOR, watchCreateDistributor)
]

export default handlePartner;
