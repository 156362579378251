import LoadingTable from "components/LoadingTable/index.js";
import ContentAlert from 'components/alert/contentAlert';
import Pagination from "components/Pagination/index.jsx";
import PlusCircle from "mdi-react/PlusCircleIcon";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, Col, Row, CardBody, CardTitle, Container, UncontrolledTooltip } from 'reactstrap';
import "../../css/reactTable.css";
import "./css/index.css"
import moment from "moment"
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Select from 'react-select';
import axios from 'axios';
import { LINK_API } from 'constants/API';
import Loading from 'components/alert/loading'
import Error from 'components/alert/error'
import Success from 'components/alert/success'
import Header from "components/Headers/Header"
import DetailIcon from "mdi-react/InfoOutlineIcon"
import CloseIcon from "mdi-react/CloseIcon"
function SubscriptionList(props) {
  const getSubscription = props.getSubscription;

  const [dataSubscriptionList, setDataSubscriptionList] = React.useState([])
  const [dataCustomerList, setDataCustomerList] = React.useState([])
  const [dataPlanList, setDataPlanList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [alert, setAlert] = React.useState(null)
  const { t } = props;

  const formalityList = [
    {
      value: true,
      label: t('registerList.replace')
    },
    {
      value: false,
      label: t('registerList.continute')
    }
  ]

  const hideAlert = () => {
    setAlert(null)
  }

  const renderSelectPlan = (data) => {
    const x = data ? data.length : 0;
    let temp = [];
    for (let i = 0; i < x; i++) {
      temp.push({
        label: data[i].name + " - " + data[i].uuid,
        uuid: data[i].uuid
      })
    }

    setDataPlanList(temp)
  }

  const handleDetail = async (data, uuid) => {
  await props.savePackage(data);
  if(props.userInfo.data.type === 'Z'){
    props.history.push('/admin/detailPackage/' + uuid);

  }else{
   props.history.push('/sadmin/detailPackage/' + uuid);

  }
  }
  const genTable = React.useCallback((dataList) => {
    let data = dataList.map((prop, index) => {
      return {
        id: index + 1,
          name: prop.name,
          uuid: prop.uuid,
          date: moment(prop.createdAt).format("DD/MM/YYYY hh:mm:ss"),
          duration: prop.periodTime + " " + (prop.typePeriodTime === "Y" ? "Năm" : (prop.typePeriodTime === "M" ? "Tháng" : (prop.typePeriodTime === "D" ? "Ngày" : "Giờ"))),
          unit: prop.unit,
          end: moment(prop.end).format("DD/MM/YYYY HH:mm:ss"),
          plan: {
            name: prop.name,
            maxAction: prop.maxAction,
            maxBizProcess: prop.maxBizProcess,
            maxCrop: prop.maxCrop,
            maxEmployee: prop.maxEmployee,
            maxProcessingObject: prop.maxProcessingObject,
            maxProductType: prop.maxProductType,
            maxProductionObject: prop.maxProductionObject,

          },
          action:(
            <div className="d-flex flex-row justify-content-center">
           <Button onClick={() => handleDetail(prop, prop.uuid)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"detailAccount" + index}>
            <span className="btn-wrapper--icon d-flex">
              <DetailIcon
                size={18}
                className="text-info font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"detailAccount" + index}>
            Chi tiết gói
          </UncontrolledTooltip>
          <Button onClick={() => console.log('delete')} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"delete" + index}>
            <span className="btn-wrapper--icon d-flex">
              <CloseIcon
                size={18}
                className="text-danger font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"delete" + index}>
          Xóa gói
          </UncontrolledTooltip>
          </div>
          )
      }
    })
    setDataSubscriptionList(data)
    setLoading(false)
  }, [])

  useEffect(() => {
    getSubscription();
  }, [getSubscription]);

  const subscription = props.subscription.data || []
  useEffect(() => {
    if (subscription && subscription.length > 0) {
      genTable(subscription)
    } else {
      genTable([])
    }
  }, [subscription]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 100,
        Header: () => (
          <div style={{
            textAlign: "center",
          }}>
            #
          </div>
        )
      },
      {
        Header: t('package.packageName'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('registerList.ID'),
        accessor: "uuid",
        filterable: false
      },
      {
        Header: t('registerList.createTime'),
        accessor: "date",
        filterable: false
      },
      {
        Header: t('package.duration'),
        accessor: "duration",
        filterable: false
      },
      {
        Header: t('package.unit'),
        accessor: "unit",
        filterable: false
      },
      {
        Header: 'Hành động',
        accessor: "action",
        filterable: false
      }
    ],
    [t]
  );


  const handleChange_SelectID = (prop, selectedOption) => {
    if (selectedOption) {
      prop.form.setFieldValue("id", selectedOption)
    }
  }

  const handleChange_SelectPackageCode = (prop, selectedOption) => {
    if (selectedOption) {
      prop.form.setFieldValue("packageCode", selectedOption)
    }
  }

  const handleChange_SelectFormality = (prop, selectedOption) => {
    if (selectedOption) {
      prop.form.setFieldValue("formality", selectedOption)
    }
  }


  const handleSubmit = (values) => {
    setAlert(<Loading />)
    const data = {
      "clientId": values.id.value,
      "planId": values.packageCode.uuid,
      "replace": values.formality.value
    }
    axios.post(LINK_API.GET_SUBSCRIPTION, data)
      .then(response => {
        if (response.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} confirmBtnText={t('close')} hideCancel={true} onConfirm={hideAlert} />)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }


  return (
    <>
    <Header/>
    <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3 d-flex justify-content-between">
          <div className=" font-weight-bold card-header--title font-size-lg">{t('package.list')}</div>
          <div className="card-header--actions">
            <Button onClick={() => props.userInfo.data.role === 'Z' ? props.history.push("/admin/createPackage") :  props.history.push("/sadmin/createPackage")} color="info">
              <span className="btn-wrapper--icon">
                <PlusCircle size={16} />
              </span>
              <span className="btn-wrapper--label">{t('addVote')}</span>
            </Button>
          </div>
        </div>

        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">

          <ReactTable
            noDataText={t('noData')}
            data={dataSubscriptionList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText={t('row')}
            ofText="/"
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            className="-striped -highlight"
            loading={loadingState}
            LoadingComponent={LoadingTable}
            SubComponent={row => {
              const planData = row.original.plan;
              return (
                <div>
                  <Card className="card-box">
                    <CardBody>
                      <CardTitle className="font-weight-bold font-size-lg mb-4">
                        {t('package.infoPackage')}
                      </CardTitle>
                      <Row>
                        <Col span={8}>
                          <p className="tagTitleSub">{t('package.type')}: </p>
                          <p className="tagTitleSub">{t('package.actionNumber')}: </p>
                          <p className="tagTitleSub">{t('package.processingNumber')}: </p>
                          <p className="tagTitleSub">{t('package.cropNumber')}: </p>
                          <p className="tagTitleSub">{t('package.employee')}: </p>
                          <p className="tagTitleSub">{t('package.processingObjectNumber')}: </p>
                          <p className="tagTitleSub">{t('package.productNumber')}: </p>
                          <p className="tagTitleSub">{t('package.productObjectNumber')}: </p>
                        </Col>
                        <Col span={16}>
                          <p>{planData.name}</p>
                          {planData.maxAction === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxAction}</p>}
                          {planData.maxBizProcess === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxBizProcess}</p>}
                          {planData.maxCrop === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxCrop}</p>}
                          {planData.maxEmployee === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxEmployee}</p>}
                          {planData.maxProcessingObject === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxProcessingObject}</p>}
                          {planData.maxProductType === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxProductType}</p>}
                          {planData.maxProductionObject === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxProductionObject}</p>}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              );
            }}

          // onFetchData={async (state, instance) => {
          //   setLoading(true)
          //   let x = state.page + 1;
          //   getPartnerList(x, state.pageSize)
          // }}
          />
        </div>
      </Card>
      {alert}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    subscription: state.planList,
    userInfo: state.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSubscription: () => dispatch({ type: "GET_PLAN_LIST" }),
  savePackage: (data) => dispatch({type: 'SAVE_PACKAGE', data: data})
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(SubscriptionList));