import DropZone from "components/dropZone";
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
//formik
import { useFormik } from 'formik';
import React from "react";
import { Editor } from 'react-draft-wysiwyg';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Card, CustomInput, Input, Container } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { LINK_API } from "constants/API";
import axios from "axios"
import cookie from "react-cookies"
import Header from "components/Headers/Header"
const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');

const CreateObjectProduction = (props) => {
    const t = props.t;
    const userInfo = props.userInfo;
    const productListState = props.productList;
    const processListState = props.processList;
    const permission = props.permission.data;
    const areaListState = props.areaList;
    const [alert, setAlert] = React.useState(null);
    const {
        renderProductList,
        renderProcessList,
        renderAreaList
    } = props;

    const [listProduct, setProduct] = React.useState([]);
    const [listProcess, setProcess] = React.useState([]);
    const [listArea, setArea] = React.useState([])
    const [listImage, setImage] = React.useState([]);
    const [editorState, setEditor] = React.useState(EditorState.createEmpty());
    const [checked, setCheck] = React.useState(false)

    const handleChangeEditor = async (editorState) => {
        document.getElementById("desNoti").innerHTML = "";
        setEditor(editorState)
    }
    const hideAlert = () => {
        setAlert(null)
    }
    const handleAva = (data) => {
        setImage(data);
        document.getElementById("avaNoti").innerHTML = "";
    }
    const handleCheckBox = () => {
        setCheck(!checked)
    }
    const formik = useFormik({
        initialValues: {
            name: '',
            product: 'noProduct',
            area: '',
            process: '',
            type: 0,
            material: ''
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Thông tin bắt buộc"),
            product: Yup.string()
                .required("Thông tin bắt buộc"),
            area: Yup.string()
                .required("Thông tin bắt buộc"),
            process: Yup.string()
                .required("Thông tin bắt buộc"),
            type: Yup.string()
                .required("Thông tin bắt buộc"),
            material: Yup.string()
                .required("Thông tin bắt buộc"),
        }),
        onSubmit: async (values) => {
            let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
            dynamicContent = await dynamicContent.toString()
            dynamicContent = await dynamicContent.replace("↵", '')
            let data = {
                "name": values.name,
                "zoneUuid": values.area,
                "processCode": values.process,
                "productType": values.product,
                "type": values.type,
                "options": [],
                "dynamicContent": dynamicContent,
                "images": listImage
            }
            if (values.product === "noProduct") {
                let dataProduct = {
                    name: values.name,
                    description: '',
                    dynamicContent: dynamicContent,
                    glnCheckbox: false,
                    gtin: "",
                    image: listImage,
                    certificates: [],
                    category: "Đông lạnh",
                    proposedPrice: "",
                    images360: [],
                    language: "vi",
                    options: [],
                    showOrigin: false,
                    unit: 2,
                }
                handleCreateProduct(dataProduct, data)
                // props.createObjectProduct(dataProduct, data);
                // props.history.push("/oProduction/list");
            } else {
                // props.createObject(data)
                // props.history.push("/oProduction/list")
                handleCreateObject(data)
            }
        },
    });
    const handleCreateProduct = (product, object) => {
        setAlert(<Loading title="Đang tạo loại sản phẩm ... " />)
        axios.post(LINK_API.CREATE_PRODUCT, product)
            .then(res => {
                if (res.data.errorCode === 1) {
                    handleRaw(res.data.data, product, object);
                } else {
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
                }
            })
            .catch(err => {
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
            })
    }
    const handleRaw = (dataRaw, product, object) => {
        var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
        const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
        const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
        const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
        const tx = new EthereumTx(dataRaw.raw, { chain: 4 });
        tx.sign(Buffer_privatekey)
        const rawTx = '0x' + tx.serialize().toString('hex');
        let data = {
            ...product,
            uuid: dataRaw.uuid,
            raw: rawTx
        }
        axios.post(LINK_API.SEND_RAW_PRODUCT, data)
            .then(res => {
                if (res.data.errorCode === 1) {
                    let dataObject = {
                        ...object,
                        productType: res.data.data.uuid
                    }
                    handleCreateObject(dataObject)
                } else {
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
                }
            })
            .catch(err => {
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
            })

    }
    const handleCreateObject = (data) => {
        setAlert(<Loading title="Đang tạo lô hàng ... " />)
        axios.post(LINK_API.CREATE_OBJECT_PRODUCTION, data)
            .then(res => {
                if (res.data.errorCode === 1) {
                    handleCreateCrop(res.data.data.uuid, data.processCode, data.productType)
                } else {
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
                }
            })
            .catch(err => {
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
            })
    }
    const handleCreateCrop = (uuid, process, product) => {
        setAlert(<Loading title="Đang tạo lô / mẻ chế biến ... " />)
        let data = {
            "startTime": new Date(),
            "uuidOfProductionObject": uuid,
            "processCode": process,
            "uuidOfProductType": product,
        }
        axios.post(LINK_API.ASSIGN_CROP, data)
            .then(res => {
                if (res.data.errorCode === 1) {
                    handleRawCrop(res.data.data, uuid, data);
                } else {
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
                }
            })
            .catch(err => {
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
            })
    }
    const handleDetail = async (uuid) => {
        await cookie.save("uuidOProcessing", uuid, { path: "/" });
        props.history.push("/user/detailObjectProcessing")
    }
    const handleRawCrop = (dataRaw, uuid, data) => {
        var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
        const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
        const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
        const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
        const tx = new EthereumTx(dataRaw.rawFungibleAsset, { chain: 4 });
        tx.sign(Buffer_privatekey)
        const rawTx = '0x' + tx.serialize().toString('hex');
        const rawtosend = {
            ...dataRaw,
            ...data,
            "raw": rawTx,
            "uuidOfProductionObject": uuid
        }
        axios.post(LINK_API.SEND_RAW_CROP, rawtosend)
            .then(res => {
                if (res.data.errorCode === 1) {
                    setAlert(<Success title="Thành công!" onConfirm={() => handleDetail(uuid)} hideCancel={true} confirmBtnText={t('confirm')} />)
                } else {
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
                }
            })
            .catch(err => {
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
            })
    }

    React.useEffect(() => {
        renderProductList(1, 100);
        renderProcessList();
        renderAreaList(1, 100);
    }, [])

    React.useEffect(() => {
        if (productListState.data) {
            if (productListState.data.length > 0) {
                setProduct(productListState.data)
            }
        }
    }, [productListState])

    React.useEffect(() => {
        if (processListState.data) {
            if (processListState.data.length > 0) {
                setProcess(processListState.data)
                formik.setFieldValue("process", processListState.data[0].value)
            }
        }
    }, [processListState])

    React.useEffect(() => {
        if (areaListState.data) {
            if (areaListState.data.length > 0) {
                setArea(areaListState.data)
                formik.setFieldValue("area", areaListState.data[0].value)
            }
        }
    }, [areaListState])

    return (
        <>
        <Header/>
            {permission.agdqr ? <div> </div> :
                <Container className='mt--7' fluid>
                <Card className="card-box mb-5">
                    <div className="wizard-steps horizontal">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="p-4">
                                <h5 className="font-size-xl font-weight-bold">{t('oProduction.oInfo')}</h5>
                                <p className="text-black-50 mb-4">{t('oProduction.about')}</p>
                                <div className="form-row">
                                    <div className="form-group col-md-6 pr-3">
                                        <label className="font-weight-bold">{t('oProduction.name')} <span className="text-danger">(*)</span></label>
                                        <Input
                                            placeholder={t('oProduction.name')}
                                            type="text"
                                            value={formik.name}
                                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                            invalid={formik.touched.name && formik.errors.name ? true : false}
                                            {...formik.getFieldProps('name')}
                                        />
                                        {formik.touched.name && formik.errors.name ? (
                                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-6 pl-3">
                                        <label className="font-weight-bold">{t('oProduction.typeProcess')} <span className="text-danger">(*)</span></label>
                                        <CustomInput type="select" id="selectUnit" placeholder={t('oProduction.typeProcess')}
                                            value={formik.type}
                                            onChange={formik.handleChange}
                                            valid={formik.touched.type && formik.errors.type ? false : (formik.values.type || formik.values.type === 0 ? true : false)}
                                            invalid={formik.touched.type && formik.errors.type ? true : false}
                                            name="customSelect"
                                            {...formik.getFieldProps('type')}
                                        >
                                            {/* <option value="">{t('oProduction.typeProcess')}</option> */}
                                            <option value={0}>{t('oProduction.plot')}</option>
                                            {/* <option value={1}>{t('oProduction.tree')}</option>
                                    <option value={2}>{t('oProduction.all')}</option>
                                    <option value={3}>{t('oProduction.batch')}</option> */}
                                        </CustomInput>
                                        {formik.touched.type && formik.errors.type ? (
                                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.type}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-row ">
                                    <div className="form-group col-md-6 pr-3">
                                        <label className="font-weight-bold">{t('oProduction.area')} <span className="text-danger">(*)</span></label>
                                        <CustomInput
                                            type="select"
                                            name="customSelect"
                                            id="selectType"
                                            placeholder={t('oProduction.area')}
                                            value={formik.area}
                                            valid={formik.touched.area && formik.errors.area ? false : (formik.values.area ? true : false)}
                                            invalid={formik.touched.area && formik.errors.area ? true : false}
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps('area')}
                                        >
                                            <option value="">{t('oProduction.area')}</option>
                                            {listArea.map((item, idx) => (
                                                <option key={idx} value={item.value}>{item.label}</option>
                                            ))}
                                        </CustomInput>
                                        {formik.touched.area && formik.errors.area ? (
                                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.area}</p>
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-6 pl-3">
                                        <label className="font-weight-bold" >{t('oProduction.process')} <span className="text-danger">(*)</span></label>
                                        <CustomInput
                                            disabled={true}
                                            type="select"
                                            name="customSelect"
                                            id="selectType"
                                            placeholder={t('oProduction.process')}
                                            value={formik.process}
                                            valid={formik.touched.process && formik.errors.process ? false : (formik.values.process ? true : false)}
                                            invalid={formik.touched.process && formik.errors.process ? true : false}
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps('process')}
                                        >
                                            <option value="">{t('oProduction.process')}</option>
                                            {listProcess.map((item, idx) => (
                                                <option key={idx} value={item.value}>{item.name}</option>
                                            ))}
                                        </CustomInput>
                                        {formik.touched.process && formik.errors.process ? (
                                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.process}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-row ">
                                    <div className="form-group col-md-6 pr-3">
                                        <label className="font-weight-bold">{t('oProduction.material')} <span className="text-danger">(*)</span></label>
                                        {checked ?
                                            <CustomInput
                                                type="select"
                                                name="customSelect"
                                                id="selectType"
                                                placeholder={t('oProduction.material')}
                                                value={formik.values.material}
                                                valid={formik.touched.material && formik.errors.material ? false : (formik.values.material ? true : false)}
                                                invalid={formik.touched.material && formik.errors.material ? true : false}
                                                onChange={formik.handleChange}
                                                {...formik.getFieldProps('material')}
                                            >
                                                <option value="">{t('oProduction.material')}</option>
                                                {listProduct.map((item, idx) => (
                                                    <option key={idx} value={item.value}>{item.name}</option>
                                                ))}
                                            </CustomInput>
                                            :
                                            <Input
                                                placeholder={t('oProduction.material')}
                                                type="text"
                                                value={formik.values.material}
                                                valid={formik.touched.material && formik.errors.material ? false : (formik.values.material ? true : false)}
                                                invalid={formik.touched.material && formik.errors.material ? true : false}
                                                {...formik.getFieldProps('material')}
                                            />}
                                        {formik.touched.material && formik.errors.material ? (
                                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.material}</p>
                                        ) : null}
                                        <div className="mt-3">
                                            <CustomInput
                                                onChange={handleCheckBox}
                                                className="mb-3" type="checkbox" id="exampleCustomCheckbox" value={checked}
                                                label={t('oProduction.exist')} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 pl-3">
                                        <label className="font-weight-bold">{t('oProduction.product')} <span className="text-danger">(*)</span></label>
                                        <CustomInput
                                            type="select"
                                            name="customSelect"
                                            id="selectType"
                                            placeholder={t('oProduction.product')}
                                            value={formik.values.product}
                                            valid={formik.touched.product && formik.errors.product ? false : (formik.values.product ? true : false)}
                                            invalid={formik.touched.product && formik.errors.product ? true : false}
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps('product')}
                                        >
                                            <option value="noProduct">{t('oProduction.none') + formik.values.name}</option>
                                            {listProduct.map((item, idx) => (
                                                <option key={idx} value={item.value}>{item.name}</option>
                                            ))}
                                        </CustomInput>
                                        {formik.touched.product && formik.errors.product ? (
                                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.product}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12 pr-3">
                                        <label className="font-weight-bold">{t('oProduction.image')}</label>
                                        <DropZone
                                            max={3}
                                            onConfirm={handleAva}
                                            data={listImage}
                                        />
                                    </div>
                                </div>
                                <div className="form-row ">
                                    <div className="form-group col-md-12">
                                        <label className="font-weight-bold">{t('oProduction.description')}</label>
                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor borderEditor"
                                            onEditorStateChange={handleChangeEditor}
                                            toolbar={{
                                                options: ["inline", 'image', 'embedded', 'textAlign', "link",]
                                            }}
                                        />
                                        <p id="desNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                                    </div>
                                </div>
                                <div className="form-row ">
                                    <div className="form-group col-md-6">
                                        <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                                    </div>
                                    <div className="form-group col-md-6 d-flex justify-content-end">
                                        <Button
                                            size="lg"
                                            type="submit"
                                            className="text-uppercase font-weight-bold font-size-sm"
                                            color="info">
                                            {t('confirm')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Card>
                </Container>
            }
            {alert}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        productList: state.renderProduct,
        processList: state.renderProcess,
        areaList: state.renderArea,
        userInfo: state.userInfo,
        permission: state.permission
    }
}

const mapDispatchToProps = (dispatch) => ({
    renderProductList: (page, size) => dispatch({ type: "RENDER_PRODUCT_LIST", page: page, size: size }),
    renderAreaList: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
    renderProcessList: () => dispatch({ type: "RENDER_PROCESS_LIST" }),
    createObject: (data) => dispatch({ type: "CREATE_OBJECT_PROCESS", data: data })
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateObjectProduction));