import axios from 'axios';
import clsx from "clsx";
import { LINK_API } from 'constants/API.js';
import PageTitle from 'layout-components/PageTitle/index.js';
import AreaIcon from "mdi-react/MountainIcon";
import React from "react";
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Nav, NavItem, Card, NavLink as NavLinkStrap, TabContent, TabPane, Container } from "reactstrap";
import "./css/edit.css";
import Step1 from "./steps/stepOne";
import Step2 from "./steps/stepTwo";
import Header from "components/Headers/Header"
import Loading from "components/alert/loading"
import Confirm from "components/alert/confirmAlert"
import Success from "components/alert/success"
import Error from "components/alert/error"
function EditProduct(props) {
    axios.defaults.headers.common['Authorization'] = props.userInfo.data.token;
    const stepOneRef = React.createRef();
    const uuid = cookie.load("uuidProduct");
    const stepTwoRef = React.createRef();
    const dataUpdate = props.dataUpdate;
    const permission = props.permission;
    const t = props.t;
    const [activeTab, setActiveTab] = React.useState('1');
    const [dataProduct, setProduct] = React.useState('');
    const [alert, setAlert] = React.useState(null)
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    React.useEffect(() => {
        axios.get(LINK_API.GET_PRODUCT_DETAIL + uuid)
            .then(async response => {
                if (response.data.errorCode === 1) {
                    setProduct(response.data.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [uuid])
    React.useEffect(() => {
        if (dataUpdate.dataOne && dataUpdate.dataTwo) {
            let dataOne = dataUpdate.dataOne;
            let dataTwo = dataUpdate.dataTwo;
            let data = {
                uuid: uuid,
                name: {
                    vi: dataOne.name,
                },
                category: dataOne.type,
                //description: ,
                gtin: dataOne.gtin,
                status: 1,
                unit: dataOne.unit,
                proposedPrice: dataOne.price,
                // "options": {},
                dynamicContent: {
                    vi: dataOne.htmlString,
                },
                language: "vi",
                showOrigin: dataTwo.checked,
            }
            if (dataTwo.checked) {
                data.origin = {
                    name: {
                        "vi": dataTwo.nameManu
                    }
                    ,
                    email: dataTwo.email,
                    address: { vi: dataTwo.address },
                    phone: dataTwo.phone,
                    description: {
                        "vi": dataTwo.companyDes
                    },
                    banner: dataTwo.banner,
                    certificates: dataTwo.companyCert,
                    tax: dataTwo.tax,
                    website: dataTwo.website
                }
            }
            props.updateProduct(data);
            props.history.push('/user/productType');
            props.saveData('', '', true)
        }
    }, [dataUpdate])
    const hideAlert = () => {
        setAlert(null)
    }
    const handleChangeStatusProduct = (info) => {
        setAlert(<Loading/>);
        let data = {
            "hideList": []
        }
        let temp = {
            "ownedBy": info.ownedBy,
            "uuidOfProduct": info.uuidOfProduct,
            "hide": true
        };
        data.hideList.push(temp);
        axios.post(LINK_API, data)
        .then(res=>{
            if(res.data.errorCode === 1){
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
                
            }else{
                setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)

            }
        })
        .catch(err=> {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        })
    }
    const handleGetData = async () => {
        await stepOneRef.current.handleMergeData()
        await stepTwoRef.current.handleMergeData()
        // setTimeout(handleSubmit, 5000)
    }
    return (
        <>
        <Header/>
        <Container className="mt--7" fluid>
        <PageTitle
                titleHeading="Loại sản phẩm"
                titleDescription="Chỉnh sửa thông tin loại sản phẩm"
                pageTitleDescription={true}
                titleIcon={<AreaIcon className="textSuccess" style={{ fill: "#4FB779" }} />}
                children={<Button color="info" size="sm" onClick={handleGetData}>{t('save')}</Button>}
            />
        <Card className="card-box my-5">
            
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div className="bg-white">
                        <Step1
                            data={dataProduct}
                            ref={stepOneRef} />
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <div className="bg-white">
                        <Step2 data={dataProduct}
                            ref={stepTwoRef} />
                    </div>
                </TabPane>
            </TabContent>
            </Card>
            </Container>
            {alert}
        </>
    )
}
const mapStateToProps = (state) => ({
    dataUpdate: state.dataEditProduct,
    userInfo: state.userInfo,
    permission: state.permission.data
})
const mapDispatchToProps = (dispatch) => ({
    updateProduct: (data) => dispatch({ type: "UPDATE_INFO_PRODUCT", data: data }),
    saveData: (dataOne, dataTwo, check) => dispatch({ type: "SAVE_DATA_EDIT_PRODUCT", dataOne: dataOne, dataTwo: dataTwo, check: check }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(EditProduct));