import DropZone from "components/dropZone";
import { LINK_API } from "constants/API";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import * as htmlToImage from 'html-to-image';
import download from "downloadjs"
//formik
import { useFormik } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import DownloadIcon from "mdi-react/DownloadIcon";
import PrintIcon from "mdi-react/PrinterIcon";
import SearchIcon from "mdi-react/SearchIcon";
import React from "react";
import cookie from "react-cookies";
import { Editor } from 'react-draft-wysiwyg';
import { withTranslation } from 'react-i18next';
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import PrintObject from "../printTemplate"
import HelpBoxIcon from "mdi-react/HelpBoxIcon";
import InfoIcon from "mdi-react/InfoCircleIcon";
import { Alert, Button, CustomInput, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledPopover } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';

const CreateObjectProduction = React.forwardRef((props, ref) => {
    const t = props.t;
    const productListState = props.productList;
    const processListState = props.processList;
    const areaListState = props.areaList;
    const componentRef = React.useRef();
    const uuid = cookie.load("uuidOProcessing");
    const {
        renderProductList,
        renderProcessList,
        renderAreaList
    } = props;

    const [listProduct, setProduct] = React.useState([]);
    const [listProcess, setProcess] = React.useState([]);
    const [listArea, setArea] = React.useState([])
    const [listImage, setImage] = React.useState([]);
    const [editorState, setEditor] = React.useState(EditorState.createEmpty());
    const [modal, setModal] = React.useState(false);
    const toggleModal = () => {
        setModal(!modal)
    }
    const downloadQR = async () => {
        document.getElementById("downloadBtn").innerHTML = "Đang tải...";
        var node = document.getElementById('qrObject');

        htmlToImage.toPng(node)
            .then(async function (dataUrl) {
                await download(dataUrl, 'qrCodeObject.png');
                toggleModal();
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    };
    const handleChangeEditor = async (editorState) => {
        document.getElementById("desNoti").innerHTML = "";
        setEditor(editorState)
    }
    const handleAva = (data) => {
        setImage(data);
        // document.getElementById("avaNoti").innerHTML = "";
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            product: '',
            area: '',
            process: '',
            type: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Thông tin bắt buộc"),
            product: Yup.string()
                .required("Thông tin bắt buộc"),
            area: Yup.string()
                .required("Thông tin bắt buộc"),
            process: Yup.string()
                .required("Thông tin bắt buộc"),
            type: Yup.string()
                .required("Thông tin bắt buộc"),
        }),
        onSubmit: async (values) => {
            // console.log("values")
            // if (listImage.length === 0) {
            //     if (listImage.length === 0) {
            //         document.getElementById("avaNoti").innerHTML = "Vui lòng tải lên ảnh lô hàng (ít nhất 1 ảnh)";
            //     }
            //     return;
            // }
            let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
            dynamicContent = await dynamicContent.toString()
            dynamicContent = await dynamicContent.replace("↵", '')
            let data = {
                "name": {
                    "vi": values.name
                },
                "zoneUuid": values.area,
                "processCode": values.process,
                "productType": values.product,
                "type": values.type,
                "options": [],
                "dynamicContent": {
                    "vi": dynamicContent
                },
                "images": [],
                "uuid": uuid
            }
            await props.updateObject(data);
            // setTimeout(props.getData(), 3000)

            // props.history.push("/oProduction/list")
        },
    });
    React.useEffect(() => {
        renderProductList(1, 100);
        renderProcessList();
        renderAreaList(1, 100);
    }, [])

    React.useEffect(() => {
        if (productListState.data) {
            if (productListState.data.length > 0) {
                setProduct(productListState.data)
            }
        }
    }, [productListState])

    React.useEffect(() => {
        if (processListState.data) {
            if (processListState.data.length > 0) {
                setProcess(processListState.data)
            }
        }
    }, [processListState])

    React.useEffect(() => {
        if (areaListState.data) {
            if (areaListState.data.length > 0) {
                setArea(areaListState.data)
            }
        }
    }, [areaListState])
    const dataStepOne = props.data;
    React.useEffect(() => {
        if (dataStepOne) {
            formik.setFieldValue("name", dataStepOne.name)
            formik.setFieldValue("type", dataStepOne.type)
            formik.setFieldValue("area", dataStepOne.zoneUuid)
            formik.setFieldValue("process", dataStepOne.processCode)
            formik.setFieldValue("product", dataStepOne.productType)
        }

        let temp = [];
        if (dataStepOne.images) {
            for (let i = 0; i < dataStepOne.images.length; i++) {
                temp.push(dataStepOne.images[i].secure_url)
            }
        }
        setImage(temp)
        if (dataStepOne.dynamicContent) {
            let dynamicContent = dataStepOne.dynamicContent;
            let contentBlock = htmlToDraft(dynamicContent);
            if (contentBlock && dataStepOne.dynamicContent && dataStepOne.dynamicContent !== "") {
                let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                let editorState = EditorState.createWithContent(contentState);
                setEditor(editorState)
            }
        }

    }, [dataStepOne])
    React.useImperativeHandle(ref, () => ({
        handleSaveData() {
            formik.submitForm()
        }
    }));
    return (
        <>

            <div className="wizard-steps horizontal">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-4">
                        <h5 className="font-size-xl font-weight-bold">{t('oProduction.oInfo')}</h5>
                        <p className="text-black-50 mb-4">{t('oProduction.about')}</p>
                        <div className="form-row">
                            <div className="form-group col-md-6 pr-3 mb-0">
                                <div className="form-group">
                                    <label className="font-weight-bold mr-1" >{t("qrCode")}</label>
                                    <HelpBoxIcon id="helpObject" className="text-info ml-2 cursor-pointer card-box-hover  card-box-hover-rise" />
                                        <UncontrolledPopover placement="top" trigger="hover" target="helpObject" className="popover-custom-wrapper popover-custom-lg">
                                            <ListGroup flush className="text-left bg-transparent">
                                                <ListGroupItem className="rounded">
                                                    <div className="align-box-row">
                                                        <div className="pl-2">
                                                            <span className="pb-1 d-block">
                                                                <span className="font-weight-bold">
                                                                    Hướng dẫn sử dụng
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <Alert color="info" className="mt-2">
                                                        <div className="d-flex align-items-center align-content-center mt-1">
                                                            <span className="font-size-lg d-block btn-icon mr-3 text-center rounded-sm">
                                                                <InfoIcon icon={['far', 'building']} />
                                                            </span>
                                                            <span>
                                                                Tải ứng dụng Agridential về điện thoại và quét mã QR bên dưới để có thể thêm nhật ký sản xuất!
                                                            </span>
                                                        </div>
                                                    </Alert>
                                                    <div className="align-box-row">
                                                        <div className="pl-2">
                                                            <span className="pb-1 d-block">
                                                                <span className="font-weight-bold">
                                                                    Link tải ứng dụng: <a href="https://qrs.ly/asaqj32" className="text-info" target="_blank">https://qrs.ly/asaqj32</a>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ListGroupItem>
                                            </ListGroup>
                                        </UncontrolledPopover>
                                    <div className="text-center mt-3 d-flex flex-row w-100">
                                        <QRCode value={"https://txngdanang.vn/q/" + props.userInfo.uuid + props.data.uuid} size={200} />
                                        <div className="text-left ml-3">
                                            <div className="form-group mb-0">
                                                <label className="font-weight-bold mr-1 sizeCode" >Mã lô hàng:</label>
                                                <label className="font-weight-bold mr-1 sizeCode text-info" >{props.userInfo.uuid + uuid}</label>
                                            </div>
                                            <div className="form-group ">
                                                <Button onClick={toggleModal} className="hover-scale-sm btn-block" color="neutral-info" id="oProductionDownload" >
                                                    <span className="btn-wrapper--icon">
                                                        <DownloadIcon size={18} />
                                                    </span>
                                                    <span className="btn-wrapper--label">{t('download')}</span>
                                                </Button>
                                            </div>
                                            <div className="form-group ">
                                                {/* <Button className="hover-scale-sm btn-block" color="neutral-info" id="oProductionDownload" >
                                                    <span className="btn-wrapper--icon">
                                                        <PrintIcon size={18} />
                                                    </span>
                                                    <span className="btn-wrapper--label">{t('print')}</span>
                                                </Button> */}
                                                <ReactToPrint
                                                    trigger={() => <Button className="hover-scale-sm btn-block" color="neutral-info" id="oProductionDownload" >
                                                        <span className="btn-wrapper--icon">
                                                            <PrintIcon size={18} />
                                                        </span>
                                                        <span className="btn-wrapper--label">{t('print')}</span>
                                                    </Button>}
                                                    content={() => componentRef.current}
                                                />
                                                <div id="printObject" style={{ display: "none" }}>
                                                    <PrintObject
                                                        data={dataStepOne}
                                                        name={props.userInfo.name}
                                                        uuid={props.userInfo.uuid + uuid}
                                                        ref={componentRef}
                                                        logo={props.userInfo.logo ? props.userInfo.logo.secure_url : ""} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <Button className="hover-scale-sm btn-block" color="neutral-info" id="oProductionDownload" onClick={() => window.open("https://txngdanang.vn/q/" + props.userInfo.uuid + uuid)} >
                                                    <span className="btn-wrapper--icon">
                                                        <SearchIcon size={18} />
                                                    </span>
                                                    <span className="btn-wrapper--label">{t('search')}</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group col-md-6 pl-3 mb-0">
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('oProduction.name')} <span className="text-danger">(*)</span></label>
                                    <Input
                                        placeholder={t('product.name')}
                                        type="text"
                                        value={formik.name}
                                        valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                        invalid={formik.touched.name && formik.errors.name ? true : false}
                                        {...formik.getFieldProps('name')}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('oProduction.typeProcess')} <span className="text-danger">(*)</span></label>
                                    <CustomInput type="select" id="selectUnit" placeholder={t('oProduction.typeProcess')}
                                        value={formik.type}
                                        onChange={formik.handleChange}
                                        valid={formik.touched.type && formik.errors.type ? false : (formik.values.type || formik.values.type === 0 ? true : false)}
                                        invalid={formik.touched.type && formik.errors.type ? true : false}
                                        name="customSelect"
                                        {...formik.getFieldProps('type')}
                                    >
                                        <option value="">{t('oProduction.typeProcess')}</option>
                                        <option value={0}>{t('oProduction.row')}</option>
                                        <option value={1}>{t('oProduction.tree')}</option>
                                        <option value={2}>{t('oProduction.all')}</option>
                                        <option value={4}>{t('oProduction.farm')}</option>
                                        <option value={5}>{t('oProduction.house')}</option>
                                    </CustomInput>
                                    {formik.touched.type && formik.errors.type ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.type}</p>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('oProduction.area')} <span className="text-danger">(*)</span></label>
                                    <CustomInput
                                        type="select"
                                        name="customSelect"
                                        id="selectType"
                                        placeholder={t('oProduction.area')}
                                        value={formik.values.area}
                                        valid={formik.touched.area && formik.errors.area ? false : (formik.values.area ? true : false)}
                                        invalid={formik.touched.area && formik.errors.area ? true : false}
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps('area')}
                                    >
                                        <option value="">{t('oProduction.area')}</option>
                                        {listArea.map((item, idx) => (
                                            <option key={idx} value={item.value}>{item.label}</option>
                                        ))}
                                    </CustomInput>
                                    {formik.touched.area && formik.errors.area ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.area}</p>
                                    ) : null}
                                </div>

                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6 pr-3">
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('oProduction.image')} </label>
                                    <div className="mt-3">
                                        <DropZone
                                            max={3}
                                            onConfirm={handleAva}
                                            data={listImage}
                                            api={true}
                                            path={LINK_API.UPDATE_PICTURE_OBJECT}
                                            uuid={uuid}
                                        />
                                    </div>
                                    <p id="avaNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                                </div>
                            </div>
                            <div className="form-group col-md-6 pl-3">
                                <div className="form-group">
                                    <label className="font-weight-bold" >{t('oProduction.process')} <span className="text-danger">(*)</span></label>
                                    <CustomInput
                                        disabled={true}
                                        type="select"
                                        name="customSelect"
                                        id="selectType"
                                        placeholder={t('oProduction.process')}
                                        value={formik.process}
                                        valid={formik.touched.process && formik.errors.process ? false : (formik.values.process ? true : false)}
                                        invalid={formik.touched.process && formik.errors.process ? true : false}
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps('process')}
                                    >
                                        <option value="">{t('oProduction.process')}</option>
                                        {listProcess.map((item, idx) => (
                                            <option key={idx} value={item.value}>{item.name}</option>
                                        ))}
                                    </CustomInput>
                                    {formik.touched.process && formik.errors.process ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.process}</p>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('oProduction.product')} <span className="text-danger">(*)</span></label>
                                    <CustomInput
                                        type="select"
                                        name="customSelect"
                                        id="selectType"
                                        placeholder={t('oProduction.product')}
                                        value={formik.values.product}
                                        valid={formik.touched.product && formik.errors.product ? false : (formik.values.product ? true : false)}
                                        invalid={formik.touched.product && formik.errors.product ? true : false}
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps('product')}
                                    >
                                        <option value="">{t('oProduction.product')}</option>
                                        {listProduct.map((item, idx) => (
                                            <option key={idx} value={item.value}>{item.name}</option>
                                        ))}
                                    </CustomInput>
                                    {formik.touched.product && formik.errors.product ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.product}</p>
                                    ) : null}
                                </div>
                            </div>

                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">{t('oProduction.description')}</label>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor borderEditor"
                                    onEditorStateChange={handleChangeEditor}
                                    toolbar={{
                                        options: ["inline", 'image', 'embedded', 'textAlign', "link", ]
                                    }}
                                />
                                <p id="desNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>
                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-6">
                                <p><span className="text-danger">(*)</span> {t('require')}</p>
                            </div>
                        </div>
                    </div>
                </form>
                <Modal zIndex={2000} centered isOpen={modal} toggle={toggleModal} className="widthFrame">
                    <ModalHeader toggle={toggleModal}>{t('download')}</ModalHeader>
                    <ModalBody>
                        <div className="qrFrame" id="qrObject">
                            &nbsp;
                            <div className="outSide">
                                <QRCode value={"https://txngdanang.vn/q/" + props.userInfo.uuid + props.data.uuid} size={165} className="qrMargin" />
                            </div>
                            <div className="inSide">
                                <p className="text-white font-weight-bold font-size-xl">{props.data.name}</p>
                                <p className="text-white font-weight-bold font-size-xl">{props.userInfo.uuid + props.data.uuid}</p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="neutral-danger" className="btn-link-dark" onClick={toggleModal}>{t('cancel')}</Button>{' '}
                        <Button id="downloadBtn" color="info" className="ml-auto" onClick={downloadQR}>{t('save')}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    )
})
const mapStateToProps = (state) => {
    return {
        productList: state.renderProduct,
        processList: state.renderProcess,
        areaList: state.renderArea,
        userInfo: state.userInfo.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    renderProductList: (page, size) => dispatch({ type: "RENDER_PRODUCT_LIST", page: page, size: size }),
    renderAreaList: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
    renderProcessList: () => dispatch({ type: "RENDER_PROCESS_LIST" }),
    updateObject: (data) => dispatch({ type: "UPDATE_OBJECT_PROCESSING", data: data }),
});

export default withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CreateObjectProduction));