import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import "../../../css/reactTable.css";

function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  const genTable = React.useCallback((data) => {
    // let dataRender = data.map((item, index) => {
    //   let dataDetail = []
    //   if (item.CropInfo && item.CropInfo.length > 0) {
    //     dataDetail = item.CropInfo.map(itemCrop => {
    //       return {
    //         startTime: itemCrop.startTime,
    //         endTime: itemCrop.endTime,
    //         cropUuid: itemCrop.uuid,
    //         stampNo: itemCrop.stampNo,
    //         period: itemCrop.subTime
    //       }
    //     })
    //   }
    //   return {
    //     objectUuid: item.uuid,
    //     objectName: item.name,
    //     dataDetail: dataDetail
    //   }
    // })
    setActivity(data)
    setLoading(false)
  }, [])

  const getData = async (startDate, endDate) => {
    axios.defaults.headers.common.Authorization = userInfo.data.token
    const response = await axios({
      method: 'GET',
      url: LINK_API.MANUFACTURER_REPORT_ADMIN.concat('?date=', props.date)
    })
    if (response && response.data && response.data.data) {
      genTable(response.data.data)
    }
    else {
      genTable([])
    }
  }

  useEffect(() => {
    setLoading(true)
    getData(props.startDate, props.endDate)

  }, [props.startDate, props.endDate]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Mã doanh nghiệp',
        accessor: "uuid",
        sortable: false
      },
      {
        Header: 'Tên doanh nghiệp',
        accessor: "name",
        filterable: false
      },
      {
        Header: 'Email',
        accessor: "email",
        filterable: false
      },
      {
        Header: 'Số điện thoại',
        accessor: "phone",
        filterable: false
      }
    ],
    [t]
  );

  return (

    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={activityRender}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          // manual
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          // pages={totalPage}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
          // SubComponent={row => {
          //   return (
          //     <SubTable columns={columnSubTable} sizePerPage={5} data={row.original.dataDetail} />
          //   )
          // }}
        // onFetchData={async (state, instance) => {
        //   setLoading(true)
        //   let x = state.page + 1;
        //   const page = x;
        //   const size = state.pageSize;
        // }}
        />
      </div>
      {/* </Card> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    activatedStampCVS: state.activatedStampCVS
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllActivatedStamp: (data) => dispatch({ type: "GET_ALL_ACTIVATED_STAMP_CSV", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));