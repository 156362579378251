import { 
  GET_AREA_LIST,
  GET_AREA_LIST_FAILURE,
  GET_AREA_LIST_SUCCESS,
  GET_AREA_LIST_FILTER
 } from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo
const getFullAreaList = (state) => state.productArea.fullData
const getToTalItemArea = (state) => state.productArea.totalItem
//const getSaveSetting = (state) => state.settingList.data

function fetchArea (page, size, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_AREA_LIST.concat( '?page=' , page , '&size=' , size) 
  })
}

function * getAreaList (page) {
  try {
    const tokenInfo = yield select(getToken)
    const fullData = yield select(getFullAreaList)
    const totalItem = yield select(getToTalItemArea)
    //const setting_data = yield select(getSaveSetting)
    const size = page.size;
    if (fullData[`${page.page}`]) {
      yield put({
        type: GET_AREA_LIST_SUCCESS,
        data: fullData[`${page.page}`],
        fullData: fullData
      })
    }
    const response = yield call(fetchArea, page.page, size, tokenInfo.data.token)
    const data = response.data.data
    if (totalItem !== response.data.totalItem) {
      const newList = data
      fullData[`${page.page}`] = newList
      yield put({
        type: GET_AREA_LIST_SUCCESS,
        data: newList,
        errorCode: response.data.errorCode,
        totalItem: response.data.totalItem,
        totalPage: response.data.totalPage,
        fullData: fullData
      })
    } else {
      if (!fullData[`${page.page}`]) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_AREA_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      }else{
        if(fullData[`${page.page}`] !== data){
          const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_AREA_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
        }
      }
    }
  } catch (error) {
    yield put({ type: GET_AREA_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
// Get area list filter
function * getProductionAreaFilter (page) {
  try {
    const tokenInfo = yield select(getToken)
    const fullData = yield select(getFullAreaList)
    const totalItem = yield select(getToTalItemArea)
    const size = page.size

    if (fullData[`${page.page}`]) {
      yield put({
        type: GET_AREA_LIST_SUCCESS,
        data: fullData[`${page.page}`],
        fullData: fullData
      })
    }
    const response = yield call(fetchAreaFilter, page.page, page.name, page.value, tokenInfo.data.token, size)
    const data = response.data.data
    if (totalItem !== response.data.totalItem) {
      const newList = data
      fullData[`${page.page}`] = newList
      yield put({
        type: GET_AREA_LIST_SUCCESS,
        data: newList,
        errorCode: response.data.errorCode,
        totalItem: response.data.totalItem,
        totalPage: response.data.totalPage,
        fullData: fullData
      })
    } else {
      if (!fullData[`${page.page}`]) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_AREA_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      }
    }
  } catch (error) {
    yield put({ type: GET_AREA_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchAreaFilter (page, name, value, checktoken, size) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_AREA_LIST.concat('?page=' , page , '&size=' , size , '&' , name , '=' , value) 
  })
}
const productType = [
  takeLatest(GET_AREA_LIST, getAreaList),
  takeLatest(GET_AREA_LIST_FILTER, getProductionAreaFilter)
]
export default productType

