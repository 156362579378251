import axios from 'axios';
import clsx from "clsx";
import { LINK_API } from 'constants/API.js';
import PageTitle from 'layout-components/PageTitle/index.js';
import AreaIcon from "mdi-react/SpaIcon";
import React from "react";
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Nav, NavItem, NavLink as NavLinkStrap, TabContent, TabPane, UncontrolledTooltip, Container } from "reactstrap";
import "./css/edit.css";
import Step1 from "./steps/stepOne";
import Step2 from "./steps/stepTwo";
import Header from "components/Headers/Header"
function ObjectDetail(props) {
    axios.defaults.headers.common['Authorization'] = props.userInfo.data.token;
    const stepOneRef = React.createRef();
    const uuid = cookie.load("uuidOProduction");
    const permission = props.permission;
    const [checkPer, setPer] = React.useState(false);
    React.useEffect(() => {
        if (permission) {
            if(permission.maxCrop !== -1){
                if (permission.crop >= permission.maxCrop) {
                    setPer(true)
                }
            }
        }
    }, [permission])
    const stepTwoRef = React.createRef();
    const t = props.t;
    const [activeTab, setActiveTab] = React.useState('1');
    const [dataObject, setObject] = React.useState('');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    React.useEffect(() => {

        axios.get(LINK_API.GET_A_PRODUCTION + uuid)
            .then(async response => {
                if (response.data.errorCode === 1) {
                    setObject(response.data.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [uuid])
    const handleGetData = async () => {
        await stepOneRef.current.handleSaveData()
    }
    const handleCreateCrop = async () => {
        await stepTwoRef.current.handleAddCrop()
    }
    const getDetailObject = () => {
        axios.get(LINK_API.GET_A_PRODUCTION + uuid)
            .then(async response => {
                if (response.data.errorCode === 1) {
                    setObject(response.data.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const dataList = props.objectList;
    React.useEffect(() => {
        axios.get(LINK_API.GET_A_PRODUCTION + uuid)
            .then(async response => {
                if (response.data.errorCode === 1) {
                    setObject(response.data.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [dataList, uuid])
    return (
        <>
        <Header/>
        <Container className='mt--7' fluid>
        <div className="Hello">
            {
                permission.agdqr ? <div> </div> :
                    <>
                        <PageTitle
                            titleHeading={t('area.o-production')}
                            titleDescription={t('oProduction.info')}
                            pageTitleDescription={true}
                            titleIcon={<AreaIcon className="textSuccess" style={{ fill: "#4FB779" }} />}
                            children={activeTab === '1' ? <Button  color="info" onClick={handleGetData}>{t('save')}</Button> : <div className="d-inline-block" id="notCrop"><Button disabled={checkPer} color="info" onClick={handleCreateCrop}>{t('addCrop')}</Button></div>}
                        />
                        {dataObject.statusCurrentCrop && activeTab === '2' ?
                            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target="notCrop">
                                {t('oProduction.warningCrop')}
                            </UncontrolledTooltip>
                            : null}
                        <Nav tabs>
                        <NavItem className="w-50 pr-1">
                            <NavLinkStrap
                                className="bg-white noBorder"
                                onClick={() => {
                                    toggle('1');
                                }}
                            >
                                <li className={clsx("card-box",  { ' border-bottom-active-tab': activeTab === '1' })}><a href="# " onClick={(e) => e.preventDefault()}>
                                    <div className="step-indicator p-3">
                                        <div className="d-flex">
                                            <div className={activeTab === '1' ? "font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center bg-info text-white" : "font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center bg-gray-300"}>
                                                <span className="font-weight-bold">1</span>
                                            </div>
                                            <div>
                                                <div className={activeTab === '1' ? "font-weight-bold text-info text-transform-none lowcase" : "font-weight-bold lowcase"}>{t('oProduction.oInfo')}</div>
                                                <small className="text-black-50 lowcase">{t('oProduction.about')}</small></div>
                                        </div>
                                    </div>
                                </a></li>
                            </NavLinkStrap>
                        </NavItem>
                        <NavItem className="w-50 pl-1">
                            <NavLinkStrap
                                className='bg-white noBorder'
                                onClick={() => {
                                    toggle('2');
                                }}
                            >
                                <li className={clsx("card-box",  { ' border-bottom-active-tab': activeTab === '2' })}><a href="# " onClick={(e) => e.preventDefault()}>
                                    <div className="step-indicator p-3">
                                        <div className="d-flex">
                                            <div className={activeTab === '2' ? "font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center bg-info text-white" : "font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center bg-gray-300"}>
                                                <span className="font-weight-bold">2</span>
                                            </div>
                                            <div>
                                                <div className={activeTab === '2' ? "font-weight-bold text-info lowcase" : "font-weight-bold lowcase"}>Sổ nhật ký</div>
                                                <small className="text-black-50 lowcase">Danh sách sổ nhật ký</small></div>
                                        </div>
                                    </div>
                                </a></li>
                            </NavLinkStrap>
                        </NavItem>
                    </Nav>
                     <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className="bg-white">
                                    <Step1
                                        data={dataObject}
                                        ref={stepOneRef} />
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="bg-white">
                                    <Step2 data={dataObject}
                                        detail={getDetailObject}
                                        ref={stepTwoRef} 
                                        status={dataObject.statusCurrentCrop}/>
                                </div>
                            </TabPane>
                        </TabContent>
                    </>
            }
        </div>
        </Container>
        </>
    )
}
const mapStateToProps = (state) => ({
    dataUpdate: state.dataEditArea,
    userInfo: state.userInfo,
    objectList: state.objectList,
    permission: state.permission.data
})
const mapDispatchToProps = (dispatch) => ({
    updateArea: (data) => dispatch({ type: "UPDATE_INFO_AREA", data: data }),
    saveArea: (dataOne, dataTwo, check) => dispatch({ type: "SAVE_DATA_EDIT_AREA", dataOne: dataOne, dataTwo: dataTwo, check: check }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ObjectDetail));