import axios from 'axios';
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import { AGD_LOGO } from "constants/image";
import { useFormik } from 'formik';
import PenIcon from "mdi-react/LeadPencilIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import React, { useEffect } from 'react';
import cookie from 'react-cookies';
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTable from 'react-table-6';
import {
  Button, Card,
  CardBody, Col,
  Input,
  Modal,
  ModalBody, ModalHeader, Row,
  Container
} from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";
import Header from "components/Headers/Header"

const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";

function ProductAreaList(props) {
  const { t } = props;
  const permission = props.permission.data;
  const [dataPartnerList, setDataPartnerList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [totalBox, setTotalBox] = React.useState(0)
  const [totalUnit, setTotalUnit] = React.useState(0)
  const [namePartner, setNamePartner] = React.useState("")
  const [distributor, setDistributor] = React.useState({})
  const [canEdit, setCanEdit] = React.useState(false)
  const [submitedType, setSubmitedType] = React.useState("F");
  const [alert, setAlert] = React.useState(null)
  const [modal, setModal] = React.useState(false);
  const [type, setType] = React.useState({
    value: "F",
    label: t('partner.distributor')
  });

  const TypeList = [
    {
      value: "F",
      label: t('partner.distributor')
    },
    {
      value: "R",
      label: t('partner.retailer')
    },
    {
      value: "RC",
      label: t('partner.retailCustomer')
    },
    {
      value: "W",
      label: t('partner.wholesaleCustomers')
    },
  ]



  const handleChange_SelectType = (selectedOption) => {
    setSubmitedType(selectedOption.value)
    setType(selectedOption)
  }


  const handleSelectSuggest = (geocodedPrediction) => {
    formik.setFieldValue("address", geocodedPrediction.formatted_address)
    formik.setFieldValue("search", "")
  }

  const handleNoResult = () => {
    console.log('Không có kết quả')
  }

  const handleChangeAddress = name => event => {
    let values = event.target.value;
    if (values) {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
    } else {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
      formik.setFieldError("address", t('require'))
    }
  }

  const handleSelectSuggestModal = (geocodedPrediction) => {
    formikModal.setFieldValue("address", geocodedPrediction.formatted_address)
    formikModal.setFieldValue("search", "")
  }

  const handleChangeAddressModal = name => event => {
    let values = event.target.value;
    console.log("event", event)
    if (values) {
      formikModal.setFieldValue("search", values);
      formikModal.setFieldValue("address", values, true);
    } else {
      formikModal.setFieldValue("search", values);
      formikModal.setFieldValue("address", values, true);
      formikModal.setFieldError("address", t('require'))
    }
  }


  const handleModal = () => {
    setModal(!modal)
  }

  const handleRole = React.useCallback((type) => {
    if (type === 'RC') return (t('partner.retailCustomer'));
    if (type === 'R') return (t('partner.retailer'));
    if (type === 'W') return (t('partner.wholesaleCustomers'));
    return (t('partner.distributor'));
  }, [t])

  const genTable = React.useCallback((branchs) => {
    let data = branchs.map((prop, index) => {
      return {
        id: prop.uuid,
        branchName: prop.branchName,
        numberStampCase: prop.numberStampCase,
        numberStampUnit: prop.numberStampUnit
      }
    })
    setDataPartnerList(data)
    setLoading(false)
  }, [])


  const hideAlert = () => {
    setAlert(null)
    window.location.reload();
  }

  const formik = useFormik({
    initialValues: {
      name: distributor.distributorName,
      address: distributor.distributorAddress,
      phone: distributor.distributorPhone,
      website: distributor.distributorWebsite
      // email: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      address: Yup.string()
        .required(t('require')),
      phone: Yup.string()
        .required(t('require')),
      // email: Yup.string()
      //   .required(t('require')),
    }),
    onSubmit: async (values) => {
      setAlert(<Loading />)
      const uuid = cookie.load("uuidPartner");
      let body = values;
      body.partnerRole = submitedType;
      axios.put(LINK_API.UPDATE_INFO_PARTNER + uuid, body)
        .then(() => {
          setAlert(<Success onConfirm={hideAlert} hideCancel={true} confirmBtnText={t('close')} />)
        })
        .catch(err => {
          setAlert(<Error onConfirm={hideAlert} hideCancel={true} confirmBtnText={t('close')} />)
        })
    },
  });

  const createBranchForPartner = props.createBranchForPartner;
  const formikModal = useFormik({
    initialValues: {
      name: "",
      address: "",
      phone: "",
      website: "",
      description: "",
      images: [],
      uuid: cookie.load("uuidPartner")
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      address: Yup.string()
        .required(t('require')),
      phone: Yup.string()
        .required(t('require')),
    }),
    onSubmit: async (values) => {
      handleModal()
      createBranchForPartner(values)
    },
  });

  const getNumberProductToBranch = props.getNumberProductToBranch;
  useEffect(() => {
    const uuid = cookie.load("uuidPartner");
    if (uuid) {
      getNumberProductToBranch(uuid)
    }

  }, [getNumberProductToBranch]);

  const numProductToBranch = props.numProductToBranch;
  useEffect(() => {
    if (numProductToBranch.data) {
      if (numProductToBranch.data[0]) {
        if (numProductToBranch.data[0].branches) {
          genTable(numProductToBranch.data[0].branches)
        }
        else {
          genTable([])
        }

        setTotalBox(numProductToBranch.data[0].totalNumberCase || 0)
        setTotalUnit(numProductToBranch.data[0].totalNumberUnit || 0)
        setNamePartner(numProductToBranch.data[0].distributorName)
        setDistributor(numProductToBranch.data[0])
        formik.setFieldValue("address", numProductToBranch.data[0].distributorAddress)
        formik.setFieldValue("name", numProductToBranch.data[0].distributorName)
        formik.setFieldValue(
          "phone",
          numProductToBranch.data[0].distributorPhone
            && (numProductToBranch.data[0].distributorPhone[0] === '0' || numProductToBranch.data[0].distributorPhone[0] === "+")
            ? numProductToBranch.data[0].distributorPhone
            : `0${numProductToBranch.data[0].distributorPhone}`)
        formik.setFieldValue("website", numProductToBranch.data[0].distributorWebsite)
        const role = cookie.load("rolePartner");
        setSubmitedType(role)
        setType(
          {
            value: role,
            label: handleRole(role)
          })
      }
    }

  }, [numProductToBranch, genTable]);

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: t('branch.name'),
        accessor: "branchName",
        sortable: false
      },
      {
        Header: t('partner.shipmentNumberDelivery'),
        accessor: "numberStampCase",
        filterable: false
      },
      {
        Header: t('partner.productsNumberDelivery'),
        accessor: "numberStampUnit",
        filterable: false
      },
    ],
    [t]
  );


  const isEmptyObject = (obj) => {
    if (!obj) return true;
    if (obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }
  const role = cookie.load("rolePartner");

  const addNewBranch = () => {
    return (
      <Col xs={12} sm={12} md={12} lg={12}>
        <form onSubmit={formikModal.handleSubmit}>
          <div className="form-group">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <label className="font-weight-bold">{t('branch.name')} <span className="text-danger">(*)</span></label>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <Input
                  placeholder={t('branch.name')}
                  type="text"
                  name="name"
                  value={formikModal.values.name}
                  valid={formikModal.touched.name && formikModal.errors.name ? false : (formikModal.values.name ? true : false)}
                  invalid={formikModal.touched.name && formikModal.errors.name ? true : false}
                  {...formikModal.getFieldProps('name')}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <label className="font-weight-bold">{t('product.address')} <span className="text-danger">(*)</span></label>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <ReactGoogleMapLoader
                  params={{
                    key: MY_API_KEY,
                    libraries: "places,geocode",
                  }}
                  render={googleMaps =>
                    googleMaps && (
                      <div>
                        <ReactGooglePlacesSuggest
                          autocompletionRequest={{ input: formikModal.values.search }}
                          googleMaps={googleMaps}
                          onSelectSuggest={handleSelectSuggestModal}
                          displayPoweredByGoogle={false}
                          onNoResult={handleNoResult}

                        >
                          <Input
                            placeholder={t('product.address')}
                            onChange={handleChangeAddressModal("address")}
                            type="text"
                            name="address"
                            value={formikModal.values.address}
                            valid={formikModal.touched.address && formikModal.errors.address ? false : (formikModal.values.address ? true : false)}
                            invalid={formikModal.touched.address && formikModal.errors.address ? true : false}
                          />
                        </ReactGooglePlacesSuggest>
                      </div>
                    )
                  }
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <label className="font-weight-bold">{t('product.phone')} <span className="text-danger">(*)</span></label>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <Input
                  placeholder={t('product.phone')}
                  type="number"
                  name="phone"
                  value={formikModal.values.phone}
                  valid={formikModal.touched.phone && formikModal.errors.phone ? false : (formikModal.values.phone ? true : false)}
                  invalid={formikModal.touched.phone && formikModal.errors.phone ? true : false}
                  {...formikModal.getFieldProps('phone')}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <label className="font-weight-bold">{"Website"}</label>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <Input
                  placeholder={"Website"}
                  type="text"
                  name="website"
                  value={formikModal.values.website}
                  {...formikModal.getFieldProps('website')}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <label className="font-weight-bold">{t('product.description')}</label>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <Input
                  placeholder={t('product.description')}
                  type="text"
                  name="description"
                  value={formikModal.values.description}
                  {...formikModal.getFieldProps('description')}
                />
              </Col>
            </Row>
          </div>

          <div className="form-row">
            <div className="form-group d-flex">
              <Button
                size="lg"
                type="submit"
                className="text-uppercase font-weight-bold font-size-sm"
                color="neutral-success">
                {t('save')}
              </Button>
            </div>
          </div>
        </form>
      </Col>
    )

  }

  return (

    <>
      <Header />
      <Container className='mt--7' fluid>
        {
          permission.agdqr ? <div> </div> :
            <>
              <Row className="mb-5">
                <Col md="4">
                  <Card className="card-box">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="d-60 rounded-circle bg-success text-white mr-3 text-center">
                          {/* <FontAwesomeIcon
                          icon={['far', 'user']}
                          className="font-size-lg"
                        /> */}
                        </div>
                        <div className="flex-grow-1">
                          <div className="text-black pb-1 font-weight-bold font-size-lg">{t('partner.name')}</div>
                          <div>{namePartner}</div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-box">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="d-60 rounded-circle bg-warning text-white mr-3 text-center">
                          {/* <FontAwesomeIcon
                          icon={['fas', 'box-open']}
                          className="font-size-lg"
                        /> */}
                        </div>
                        <div className="flex-grow-1">
                          <div className="text-black pb-1 font-weight-bold font-size-lg">{t('partner.numberShipment')}</div>
                          <div>{totalBox}</div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-box">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div className="d-60 rounded-circle bg-first text-white mr-3 text-center">
                          {/* <FontAwesomeIcon
                          icon={['fas', 'poll-h']}
                          className="font-size-lg"
                        /> */}
                        </div>
                        <div className="flex-grow-1">
                          <div className="text-black pb-1 font-weight-bold font-size-lg">{t('partner.numberProduct')}</div>
                          <div>{totalUnit}</div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Card className="card-box mb-5">
                <div className="card-header pt-3 d-flex justify-content-between">
                  <div className="card-header--title">
                    <div className="font-weight-bold font-size-lg">{t('partner.partner')}</div>
                    <div>{namePartner}</div>
                  </div>
                  <Button onClick={() => handleModal()} color="info">
                    <span className="btn-wrapper--icon">
                      <PlusCircle size={16} />
                    </span>
                    <span className="btn-wrapper--label">{t('branch.addBranch')}</span>
                  </Button>
                </div>
                <div className="divider" />
                <div style={{ borderWidth: 0 }} className="card-body pt-2">
                  <Row>
                    <Col className="mt-4" md="12">
                      <div className="card-box">
                        <div>
                          <div className="font-weight-bold font-size-lg mb-4 d-flex align-items-center">
                            {t('partner.createPartner')}
                            {
                              distributor.canModified ?
                                <Button color="neutral-success" onClick={() => setCanEdit(true)} className="ml-4 mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" >
                                  <span className="btn-wrapper--icon d-flex">
                                    <PenIcon
                                      size={18}
                                      className="text-success font-size-sm"
                                      onClick={() => { }}
                                    />
                                  </span>
                                </Button>
                                : null
                            }

                          </div>
                          <Row>
                            <Col xs={12} sm={12} md={4} lg={3}>

                              {!isEmptyObject(distributor.images) && distributor.images.length > 0 && distributor.images[0].secure_url ? (
                                <img alt="profileAGD" src={distributor.images[0].secure_url} style={{ width: "100px" }} />
                              ) :
                                <img alt="profileAGD" src={AGD_LOGO} style={{ width: "100px" }} />}
                            </Col>
                            {
                              canEdit ?
                                <Col xs={12} sm={12} md={8} lg={9}>
                                  <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group">
                                      <Row>
                                        <Col xs={12} sm={12} md={4} lg={3}>
                                          <label className="font-weight-bold">{t('partner.name')} <span className="text-danger">(*)</span></label>

                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={9}>
                                          <Input
                                            placeholder={t('partner.name')}
                                            type="text"
                                            name="name"
                                            value={formik.values.name}
                                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                            invalid={formik.touched.name && formik.errors.name ? true : false}
                                            {...formik.getFieldProps('name')}
                                          />
                                        </Col>
                                      </Row>

                                      {formik.touched.name && formik.errors.name ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                      ) : null}
                                    </div>

                                    <div className="form-group ">
                                      <Row>
                                        <Col xs={12} sm={12} md={4} lg={3}>
                                          <label className="font-weight-bold" >{t('area.type')}<span className="text-danger">(*)</span></label>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={9}>
                                          <Select
                                            placeholder={t('area.type')}
                                            options={TypeList}
                                            onChange={handleChange_SelectType}
                                            value={type}
                                            theme={(theme) => ({
                                              ...theme,
                                              borderRadius: '0.29rem',
                                              borderWidth: 1,
                                              colors: {
                                                ...theme.colors,
                                                primary25: 'rgba(60,68,177,0.15)',
                                                primary50: 'rgba(60,68,177,0.15)',
                                                primary: '#4FB779'
                                              }
                                            })}
                                          />
                                        </Col>
                                      </Row>
                                    </div>

                                    <div className="form-group">
                                      <Row>
                                        <Col xs={12} sm={12} md={4} lg={3}>
                                          <label className="font-weight-bold">{t('product.address')} <span className="text-danger">(*)</span></label>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={9}>
                                          <ReactGoogleMapLoader
                                            params={{
                                              key: MY_API_KEY,
                                              libraries: "places,geocode",
                                            }}
                                            render={googleMaps =>
                                              googleMaps && (
                                                <div>
                                                  <ReactGooglePlacesSuggest
                                                    autocompletionRequest={{ input: formik.values.search }}
                                                    googleMaps={googleMaps}
                                                    onSelectSuggest={handleSelectSuggest}
                                                    displayPoweredByGoogle={false}
                                                    onNoResult={handleNoResult}
                                                  >
                                                    <Input
                                                      placeholder={t('product.address')}
                                                      onChange={handleChangeAddress('address')}
                                                      type="text"
                                                      name="address"
                                                      value={formik.values.address}
                                                      valid={formik.touched.address && formik.errors.address ? false : (formik.values.address ? true : false)}
                                                      invalid={formik.touched.address && formik.errors.address ? true : false}
                                                    // {...formik.getFieldProps('address')}
                                                    />
                                                  </ReactGooglePlacesSuggest>
                                                </div>
                                              )
                                            }
                                          />
                                        </Col>
                                      </Row>

                                      {formik.touched.address && formik.errors.address ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.address}</p>
                                      ) : null}
                                    </div>

                                    <div className="form-group">
                                      <Row>
                                        <Col xs={12} sm={12} md={4} lg={3}>
                                          <label className="font-weight-bold">{t('product.phone')} <span className="text-danger">(*)</span></label>

                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={9}>
                                          <Input
                                            placeholder={t('product.phone')}
                                            type="number"
                                            name="phone"
                                            value={formik.values.phone}
                                            valid={formik.touched.phone && formik.errors.phone ? false : (formik.values.phone ? true : false)}
                                            invalid={formik.touched.phone && formik.errors.phone ? true : false}
                                            {...formik.getFieldProps('phone')}
                                          />
                                        </Col>
                                      </Row>
                                      {formik.touched.phone && formik.errors.phone ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.phone}</p>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <Row>
                                        <Col xs={12} sm={12} md={4} lg={3}>
                                          <label className="font-weight-bold">{"Website"}</label>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={9}>
                                          <Input
                                            placeholder={"Website"}
                                            type="text"
                                            name="website"
                                            value={formik.values.website}
                                            {...formik.getFieldProps('website')}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="form-row ">
                                      <div className="form-group d-flex mr-5">
                                        <Button
                                          onClick={() => setCanEdit(false)}
                                          size="lg"
                                          className="text-uppercase font-weight-bold font-size-sm"
                                          color="neutral-success">
                                          {t('cancel')}
                                        </Button>
                                      </div>
                                      <div className="form-group d-flex">
                                        <Button
                                          size="lg"
                                          type="submit"
                                          className="text-uppercase font-weight-bold font-size-sm"
                                          color="neutral-success">
                                          {t('save')}
                                        </Button>
                                      </div>
                                    </div>
                                  </form>
                                </Col>
                                :
                                <Col xs={12} sm={12} md={8} lg={9}>
                                  <Row>
                                    <Col xs={12} sm={12} md={4} lg={3}>
                                      <div className="font-weight-bold">
                                        {`${t('area.type')}: `}
                                      </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={9}>
                                      <div>
                                        {
                                          role === "RC" ?
                                            <div>
                                              {
                                                t('partner.retailCustomer')
                                              }
                                            </div>
                                            :
                                            role === "R" ?
                                              <div>
                                                {
                                                  t('partner.retailer')
                                                }
                                              </div>
                                              :
                                              role === "W" ?
                                                <div>
                                                  {
                                                    t('partner.wholesaleCustomers')
                                                  }
                                                </div>
                                                :
                                                <div>
                                                  {
                                                    t('partner.distributor')
                                                  }
                                                </div>
                                        }
                                      </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={3}>
                                      <div className="font-weight-bold">
                                        {`${t('product.phone')}: `}
                                      </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={9}>
                                      <div>
                                        {distributor.distributorPhone ? (distributor.distributorPhone[0] === "0" || distributor.distributorPhone.includes("+")) ? distributor.distributorPhone : `0${distributor.distributorPhone}` : ""}
                                      </div>
                                    </Col>

                                    <Col xs={12} sm={12} md={4} lg={3}>
                                      <div className="font-weight-bold">
                                        {t('product.address')}
                                      </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={9}>
                                      <div>
                                        {distributor.distributorAddress}
                                      </div>
                                    </Col>

                                    <Col xs={12} sm={12} md={4} lg={3}>
                                      <div className="font-weight-bold">
                                        {"Website"}
                                      </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={9}>
                                      <div>
                                        {distributor.distributorWebsite}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                            }

                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* <div className=" font-weight-bold card-header--title font-size-lg">{t('partner.numberShipment')}: {totalBox}</div>
          <div className=" font-weight-bold card-header--title font-size-lg">{t('partner.numberProduct')}: {totalUnit}</div> */}
                </div>
                <div className="card-body pt-2 px-4 pb-4">

                  <ReactTable
                    noDataText={t('noData')}
                    data={dataPartnerList}
                    columns={columns}
                    previousText={"<"}
                    nextText={">"}
                    rowsText={t('row')}
                    ofText="/"
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    sortable={false}
                    resizable={true}
                    PaginationComponent={Pagination}
                    className="-striped -highlight"
                    loading={loadingState}
                    LoadingComponent={LoadingTable}
                  // onFetchData={async (state, instance) => {
                  //   setLoading(true)
                  //   let x = state.page + 1;
                  //   getPartnerList(x, state.pageSize)
                  // }}
                  />
                </div>
                {alert}
              </Card>

              <Modal zIndex={2000} centered isOpen={modal} toggle={handleModal}>
                <ModalHeader className="d-flex" toggle={handleModal}>
                  {t('branch.addBranch')}
                </ModalHeader>
                <ModalBody className="px-0">
                  {addNewBranch()}
                </ModalBody>
              </Modal>
            </>
        }
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    numProductToBranch: state.numProductToBranch,
    permission: state.permission
  }
}

const mapDispatchToProps = (dispatch) => ({
  getNumberProductToBranch: (uuid) => dispatch({ type: "GET_PRODUCT_TO_BRANCHS", id: uuid }),
  createBranchForPartner: (data) => dispatch({ type: "CREATE_BRANCH_FOR_PARTNER", data: data })
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProductAreaList));