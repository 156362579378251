import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import axios from 'axios';
import Loading from "components/alert/loading";
import el from "components/date-fns/locale/vi";
import Header from "components/Headers/Header";
import { LINK_API } from 'constants/API.js';
import CalenderIcon from "mdi-react/CalendarIcon";
import ExportIcon from "mdi-react/DownloadIcon";
import moment from "moment";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Select from 'react-select';
import { Button, Card, Container, FormGroup } from "reactstrap";
import { valueDataSheet_manu, valueDataSheet_printStamp, valueDataSheet_scannedStamp, valueDataSheet_activatedStamp, valueDataSheet_import } from "./dataExport";
import ScanStampReport from "./scan-stamp-report";
import StampPrintAdminManagerment from "./stamp-print-admin-managerment";
import ManuList from "./manu-report"
import ProductList from "./product-report"
import ImportReport from "./import-report"
import ActivatedStampReport from "./activated-stamp-report";

registerLocale("el", el);

function Harvest(props) {

  const {
    userInfo
  } = props;

  const [selectedReport, setSelectedReport] = React.useState(null)
  const [viewReport, setViewReport] = React.useState(null)
  const [exportReportData, setExportReportData] = React.useState(null)
  const [dataSheet, setDataSheet] = React.useState([])
  const [valueDataSheet, setValueDataSheet] = React.useState([])
  const [nameOfFile, setNameOfFile] = React.useState("")
  const [alert, setAlert] = React.useState(null)

  const listReport = [
    {
      value: 1,
      label: "Số lượng tem in ấn",
      url: "GET_PRINT_STAMP_LIST_ADMIN",
      dataSheetList: valueDataSheet_printStamp
    },
    {
      value: 2,
      label: "Số lượt quét mã",
      url: "SCAN_STAMP_ADMIN",
      dataSheetList: valueDataSheet_scannedStamp
    },
    {
      value: 3,
      label: "Hộ kinh doanh",
      url: "MANUFACTURER_REPORT_ADMIN",
      dataSheetList: valueDataSheet_manu
    },
    // {
    //   value: 4,
    //   label: "Sản phẩm",
    //   url: "GET_ALL_PRODUCT_TYPE",
    //   dataSheetList: valueDataSheet_product
    // },
    {
      value: 4,
      label: "Số lượng tem kích hoạt",
      url: "ACTIVATE_STAMP_REPORT_ADMIN",
      dataSheetList: valueDataSheet_activatedStamp
    },
    {
      value: 5,
      label: "Thống kê hàng nhập",
      url: "REPORT_IMPORT",
      dataSheetList: valueDataSheet_import
    },
  ]

  const [startDate, setStartDate] = React.useState(new Date(moment().subtract(6, 'months').format("llll")));
  const [endDate, setEndDate] = React.useState(new Date());

  const [startDateSend, setStartDateSend] = React.useState(new Date(moment().subtract(6, 'months').format("llll")));
  const [endDateSend, setEndDateSend] = React.useState(new Date());

  const handleView = () => {
    if (selectedReport) {
      setStartDateSend(startDate)
      setEndDateSend(endDate)
      setExportReportData(0)
      setViewReport(selectedReport.value)
    }
  }

  const ExampleCustomInput = ({ value, onClick }) => (
    <Button block color="neutral-success" size="sm" onClick={onClick}>
      <span className="btn-wrapper--icon">
        <CalenderIcon size={20} />
      </span>
      <span className="btn-wrapper--label">
        {moment(value).format("DD/MM/YYYY")}
      </span>
    </Button>
  );

  let _exporter = () => { };

  const exportData = async () => {
    setAlert(<Loading />)
    let url = "";
    let dataSheetList = "";
    let selectedReportRender = listReport.filter(item => item.value === selectedReport.value);
    url = selectedReport.value === 3 
    ? LINK_API[selectedReportRender[0].url].concat('?date=', moment(new Date(moment().subtract(1, 'day').format("llll"))).format("YYYY_MM_DD")) 
    : selectedReport.value === 2 || selectedReport.value === 4
    ? LINK_API[selectedReportRender[0].url].concat('/csv?date=', moment(new Date(moment().subtract(1, 'day').format("llll"))).format("YYYY_MM_DD")) 
    :LINK_API[selectedReportRender[0].url].concat('/csv?startTime=', moment(startDate).format("DD/MM/YYYY"), '&endTime=', moment(endDate).format("DD/MM/YYYY"));
    
    
    dataSheetList = selectedReportRender[0].dataSheetList;
    setNameOfFile(selectedReportRender[0].label)

    axios.defaults.headers.common.Authorization = userInfo.token
    let dataReponse = await axios({
      method: 'GET',
      url: url
    })

    if (dataReponse && dataReponse.data && dataReponse.data.data) {
      setDataSheet(dataReponse.data.data)
      setValueDataSheet(dataSheetList)
      _exporter.save();
    }
    setAlert(null)
  }

  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="card-header--title px-2 py-3 d-flex flex-row">
            <div className="col-md-5 pr-0">
              <small className="d-block text-uppercase mt-1 font-weight-bold">Thống kê</small>
              {/* <b style={{ fontSize: 15 }}>{"Hỗ trợ quản trị sản xuất cho doanh nghiệp"}</b> */}
            </div>
          </div>
          <div className="divider"></div>
          <div className="px-4 py-2 d-flex justify-content-center align-items-center w-100">
            <div className="w-100">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <img alt="" width={"80%"} src="https://res.cloudinary.com/agridential/image/upload/v1597682446/AGDImage/Data_report-pana_2_zwc9b1.png" />
                </div>
                <div className="form-group col-md-8 pl-3 mb-0">
                  <div className="form-row mt-3">
                    <div className="form-group col-md-6">
                      <FormGroup>
                        <label className="font-weight-bold">Thời gian bắt đầu</label>
                        <DatePicker className="form-control"
                          selected={startDate}
                          locale="el"
                          onChange={date => {
                            setStartDate(date)
                            handleView()
                          }}
                          customInput={<ExampleCustomInput />}
                        />
                      </FormGroup>
                    </div>
                    <div className="form-group col-md-6">
                      <FormGroup>
                        <label className="font-weight-bold">Thời gian kết thúc</label>
                        <DatePicker className="form-control"
                          selected={endDate}
                          locale="el"
                          onChange={date => {
                            setEndDate(date)
                            handleView()
                          }}
                          customInput={<ExampleCustomInput />}
                        />
                      </FormGroup>
                    </div>

                    <div className="form-group col-md-8">
                      <Select
                        placeholder="Chọn loại báo cáo"
                        options={listReport}
                        value={selectedReport}
                        onChange={(selectedOption) => {
                          setSelectedReport(selectedOption)
                          setStartDateSend(startDate)
                          setEndDateSend(endDate)
                          setExportReportData(0)
                          setViewReport(selectedOption.value)
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '0.29rem',
                          borderWidth: 1,
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(60,68,177,0.15)',
                            primary50: 'rgba(60,68,177,0.15)',
                            primary: '#4FB779'
                          }
                        })}
                      />
                    </div>

                    {/* <div className="form-group col-md-3 mt-auto">
                    <FormGroup>
                      <Button block color="neutral-success" className="px-0" size="sm" onClick={handleView}>
                        <span className="btn-wrapper--icon">
                          Xem báo cáo
                          <ViewIcon className="ml-2" style={{ marginTop: 3, marginBottom: 2 }} size={20} />
                        </span>
                      </Button>
                    </FormGroup>
                  </div> */}
                    <div className="form-group col-md-4 mt-auto">
                      <FormGroup>
                        <Button disabled={!selectedReport} color="info" size="sm" className="w-100" onClick={exportData}>
                          <span className="btn-wrapper--icon">
                            Xuất báo cáo
                          <ExportIcon className="ml-2" style={{ marginTop: 3, marginBottom: 2 }} size={20} />
                          </span>
                        </Button>
                        <ExcelExport
                          data={dataSheet}
                          fileName={nameOfFile}
                          ref={(exporter) => {
                            if (exporter) {
                              _exporter = exporter
                            }
                          }}
                        >
                          {valueDataSheet.map((field) =>
                            (<ExcelExportColumn field={field.value} title={field.name} width={200} />)
                          )}
                        </ExcelExport>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {
              viewReport && viewReport === 1 ?
                <StampPrintAdminManagerment startDate={moment(startDateSend).format("DD/MM/YYYY")} endDate={moment(endDateSend).format("DD/MM/YYYY")} exportReportData={exportReportData} />
                :
                viewReport && viewReport === 2 ?
                  <ScanStampReport startDate={moment(startDateSend).format("DD/MM/YYYY")} date={moment(new Date(moment().subtract(1, 'day').format("llll"))).format("YYYY_MM_DD")} />
                  :
                  viewReport && viewReport === 3 ?
                  <ManuList startDate={moment(startDateSend).format("DD/MM/YYYY")} date={moment(new Date(moment().subtract(1, 'day').format("llll"))).format("YYYY_MM_DD")} />
                  :
                  viewReport && viewReport === 4 ?
                  <ActivatedStampReport date={moment(new Date(moment().subtract(1, 'day').format("llll"))).format("YYYY_MM_DD")} />
                  :
                  viewReport && viewReport === 5 ?
                  <ImportReport startDate={moment(startDateSend).format("DD/MM/YYYY")} endDate={moment(endDateSend).format("DD/MM/YYYY")} />
                  :
                  null
            }
          </div>
          {alert}
        </Card>
      </Container>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo.data
  }
}

const mapDispatchToProps = (dispatch) => ({
});
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Harvest))