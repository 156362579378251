import Axios from 'axios';
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import Header from "components/Headers/Header.js";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import { AGD_LOGO } from "constants/image";
import { useFormik } from 'formik';
import { motion } from "framer-motion";
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";
import PlusCopy from "mdi-react/ContentCopyIcon";
import FilterIcon from "mdi-react/FilterIcon";
import PenIcon from "mdi-react/LeadPencilIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import ResetIcon from "mdi-react/RestoreIcon";
import React, { useEffect } from 'react';
import cookie from "react-cookies";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Button, Card, Col, Container, CustomInput, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink as NavLinkStrap, Row, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";
import moment from "moment"

function ProductList(props) {
  const getProductList = props.getProductTypeList;
  const getProductListFilter = props.getProductTypeListFilter;
  const productList = props.productList.data
  const productListToal = props.productList;
  const totalPageProps = props.productList.totalPage
  const permission = props.permission;
  const [checkPer, setPer] = React.useState(false);
  React.useEffect(() => {
    if (permission) {
      if (permission.maxProductType === -1) {
        setPer(false)
      } else {
        if (permission) {
          if (permission.productType >= permission.maxProductType) {
            setPer(true)
          }
        }
      }
    }
  }, [permission, productListToal])
  const [dataProductList, setDataProductList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true);
  const [filterStatus, setFilter] = React.useState(false)
  const { t, history } = props;
  const [alert, setAlert] = React.useState(null)
  const handleEdit = React.useCallback((uuid) => {
    cookie.save("uuidProduct", uuid, { path: "/" });
    history.push("/user/productEdit")
  }, [history])

  const handleCopy = React.useCallback((uuid) => {
    cookie.save("uuidProductCopy", uuid, { path: "/" });
    if (props.userInfo.data.role === 'Z') {
      history.push("/admin/createGuard")
    } else {
      history.push("/sadmin/createGuard")
    }
   
  }, [history])
  const hideAlert = () => {
    setAlert(null);
    getProductList(1, 5, 'actionType', 'CASE_IMPORT');
  }

  const genTable = React.useCallback((productList) => {
    const handleDataProductList = productList.map((prop, index) => {
      return {
        ...prop,
        id: prop.newValue ? prop.newValue.ownerOfCrop + prop.newValue.toId : '',
        time: moment(prop.date).format('DD/MM/YYYY HH:mm:ss'),
        step: 'Đóng thùng',
        boxName: prop.batchInfo && prop.batchInfo.ProductionObjectInfo && prop.batchInfo.ProductionObjectInfo.name,
        number: prop.newValue ? `${prop.newValue.quantity} thùng (${prop.newValue.startStampID} - ${prop.newValue.endStampID})`: '',
        detail: prop.newValue ? prop.newValue.detail : prop.step_desc,
        productType: prop.newValue ? prop.newValue.productName : '',
        owner: prop.ownerInfo ? prop.ownerInfo.name : '',
      }
    })
    setDataProductList(handleDataProductList)
    setLoading(false)
  }, [t, handleEdit, handleCopy])
  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      getProductListFilter(1, values.name, values.value, 5)
    }, []),
  });
  useEffect(() => {
    getProductList(1,5, 'actionType', 'ĐÓNG THÙNG');
  }, [getProductList]);


  useEffect(() => {
    if (productList && productList.length > 0) {
      genTable(productList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }
    if (!props.userInfo || props.userInfo.data === "") {
      props.history.push('/login')
    }
    else if (productList && productList.length > 0) {
      genTable(productList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }

  }, [totalPageProps, productList]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 150,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            Mã lô hàng
          </div>
        )
      },
      {
        accessor: "boxName",
        filterable: false,
        className: "center",
        maxWidth: 150,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            Tên lô hàng
          </div>
        )
      },
      {
        Header: <div style={{
          textAlign: "center"
        }}>
          Thời gian
        </div>,
        minWidth: 100,
        accessor: "time",
        filterable: false
      },
      {
        Header: 'Chủ sở hữu',
        accessor: "owner",
        filterable: false,
      },
      // {
      //   Header: 'Loại sản phẩm',
      //   accessor: "productType",
      //   filterable: false,
      // },
      {
        Header: 'Số lượng thùng',
        accessor: "number",
        filterable: false,
      },
     
      {
        Header: 'Miêu tả',
        accessor: "detail",
        filterable: false,
      },
      
    ],
    [t]
  );
  const handleResetData = () => {
    setFilter(false);
    getProductList(1, 5)
  }
  const handleClearFilter = () => {
    formik.resetForm();
  }

const handleChangeRoute = () => {
  if(props.userInfo.data.type === 'FA'){
    props.history.push('/farmer/addDiary')
  }else{
    props.history.push('/wholesaler/addDiary')
  }
}
  return (

    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header/>
        <Container className="mt--7" fluid>
        <Card className="card-box mb-5">
          <div className="card-header py-3 d-flex justify-content-between">
            <div className="font-weight-bold card-header--title font-size-lg">Lịch sử đóng thùng</div>
            <div className="card-header--actions d-flex flex-row">
              {/* <UncontrolledDropdown>
                <DropdownToggle id="filterButton" color="neutral-success" className="mx-2">
                  <FilterIcon size={20} /> {t('filter.filter')}
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-xxl p-0">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="p-3">
                      <Row>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.name')}
                          </small>
                          <CustomInput
                            type="select"
                            placeholder={t('filter.name')}
                            value={formik.values.name}
                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                            {...formik.getFieldProps('name')}
                          >
                            <option value="name">Mã lô hàng</option>
                            <option value="uuid">Loại sản phẩm</option>
                          </CustomInput>
                        </Col>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.value')}
                          </small>
                          {formik.values.name !== "status" ?
                            <Input
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                            </Input> : <CustomInput
                              type="select"
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value || formik.values.value === 0 ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                              <option value={1}>{t('product.inProduction')}</option>
                              <option value={0}>{t('product.stopProduction')}</option>
                            </CustomInput>}
                        </Col>
                      </Row>

                    </div>
                    <div className="divider" />
                    <div className="p-3 text-center bg-secondary">
                      <Button color="primary" type="submit">
                        {t('filter.search')}
                      </Button>
                    </div>
                  </form>
                  <div className="divider" />
                  <div className="p-3">
                    <Row>
                      <Col md="6">
                        <Nav className="nav-neutral-danger flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleClearFilter}>
                              <div className="nav-link-icon">
                                <CloseIcon />
                              </div>
                              <span>{t('filter.clear')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col md="6">
                        <Nav className="nav-neutral-info flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleResetData}>
                              <div className="nav-link-icon">
                                <ResetIcon />
                              </div>
                              <span>{t('filter.reset')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"filterButton"}> 
                {t('filter.filter')}
              </UncontrolledTooltip>*/}
              <div style={{ display: "inline-block" }} id="notiPlan">
                <Button disabled={checkPer} color="info" onClick={() => handleChangeRoute()} >
                  <span className="btn-wrapper--icon">
                    <PlusCircle size={16} />
                  </span>
                  <span className="btn-wrapper--label">Đóng thùng</span>
                </Button>
              </div>
              {checkPer ?
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
                  {t('notiPlan')}
                </UncontrolledTooltip> : null}
            </div>
          </div>

          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">

            <ReactTable
              noDataText={t('noData')}
              data={dataProductList}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              manual
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              pages={totalPage}
              className="-striped -highlight"
              onFetchData={async (state, instance) => {
                setLoading(true)
                let x = state.page + 1;
                if (filterStatus === false) {
                  getProductList(x, state.pageSize)
                } else {
                  getProductListFilter(x, formik.values.name, formik.values.value, state.pageSize)
                }
              }}
            />
          </div>
        </Card>
        </Container>
      </motion.div>
      {alert}

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    productList: state.activityAdmin,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProductTypeList: (page, size, name, value) => dispatch({ type: "GET_ACTION_LIST_ADMIN_FILTER", page: page, name: name, value: value, size: size }),

});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProductList));