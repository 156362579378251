import axios from "axios";
import CloseBoxIcon from "mdi-react/CloseBoxIcon";
import CloseIcon from "mdi-react/CloseCircleIcon";
import React from "react";
import { useDropzone } from 'react-dropzone';
import { Check, UploadCloud, X } from 'react-feather';
import { withTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from "reactstrap";
import "./css/dropZone.css";
import ConfirmAlert from "components/alert/confirmAlert"

const DropZone = React.forwardRef((props, ref) => {
  const maxImage = props.max;
  const sizeOut = props.sizeOut ? props.sizeOut : "120";
  const sizeIn = props.sizeIn ? props.sizeIn : "60"
  const sizeIcon = props.sizeIcon ? props.sizeIcon : "30"
  const disabled = props.disabled ? props.disabled : false;
  const apiStatus = props.api ? props.api : false;
  const t = props.t;
  const [list, setList] = React.useState([]);
  const propsData = props.data;
  const [logo, setLogo] = React.useState("")
  const [alert, setAlert] = React.useState(null)
  const {
    isDragActive,
    isDragAccept,
    isDragReject, getRootProps, getInputProps } = useDropzone({
      accept: 'image/jpeg, image/png, image/jpg',
      multiple: true,
      onDrop: async acceptedFiles => {
        let temp = await acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
        if (temp[0].size > 2097152) {
          handleWarning();
        } else {
          creatFile(temp[0].preview)
        }
        // setFiles(temp)
      }
    });

  const handleWarning = () => {
    setAlert(<ConfirmAlert
      child='Ảnh có kích thước lớn!'
      content='Vui lòng tải lên ảnh có kích thước nhỏ hơn 2MB'
      onConfirm={hideAlert}
      hideCancel={true}
    />)
  }

  const hideAlert = () => {
    setAlert(null)
  }

  React.useImperativeHandle(ref, () => ({
    handleUpload() {
      if (logo) {
        handleAddApi(logo)
      }
    }
  }));
  const handleAddApi = (data) => {
    if (data) {
      if (props.setLoad) {
        props.setLoad();
      }
      let data_send = {
        logo: data
      }
      axios.post(props.path, data_send)
        .then(async res => {
          if (res.data.errorCode === 1) {
            if (props.reload) {
              props.reload("logo")
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const creatFile = (link) => {
    var file_result = "";
    var xhr = new XMLHttpRequest();
    xhr.open('GET', link, true);
    xhr.responseType = 'blob';
    xhr.onload = (e) => {
      e.preventDefault()
      var reader = new FileReader();
      var recoveredBlob = xhr.response;
      reader.onloadend = () => {
        handleData(reader)
      };

      reader.readAsDataURL(recoveredBlob);
      file_result = (reader)
    };
    xhr.send();
    return file_result;
  }
  const handleData = async (data) => {
    let temp = list;
    setLogo(data.result)
    if (apiStatus) {
      handleAddApi(data.result)
    }
    await temp.push(data.result);
    await setList([...temp])
    if (props.onConfirm) {
      props.onConfirm(temp)
    }

  }

  React.useEffect(() => {
    if (propsData) {
      if (propsData.length > 0) {
        setList([...propsData])
      }
    }
  }, [propsData])
  return (
    <>

      <div className="d-130">
        {list.map((img, index) => (
          <a href="#/" onClick={e => e.preventDefault()} className="avatar-icon-wrapper  hover-scale-lg d-130">
            <div className="avatar-icon ">
              <div className="d-block p-0 avatar-icon-wrapper  m-0 border-3 border-white">
                <div className=" border-3 border-white overflow-hidden">
                  <img alt="..." className="img-fluid" src={img} />
                </div>
              </div>
            </div>
          </a>
        ))}
        {list.length < maxImage ?
          <div style={{ height: sizeOut, width: sizeOut, marginLeft: list.length > 0 ? "15px" : "0px" }}>
            {!disabled ?
              <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                <input {...getInputProps()} />
                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt border-3 border-dark  bg-white ">
                  {isDragAccept && (
                    <div>
                      <div className="d-90 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                        <Check className="d-50" />
                      </div>
                    </div>
                  )}
                  {isDragReject && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                        <X className="d-90" />
                      </div>

                    </div>
                  )}
                  {!isDragActive && (
                    <div className={"d-flex rounded-circle justify-content-center align-items-center d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                      <div className={"btn-icon  rounded-circle hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first d-" + sizeIn} style={{ height: sizeIn, width: sizeIn }}>
                        <UploadCloud className={"d-" + sizeIcon} style={{ height: sizeIcon, width: sizeIcon }} />
                      </div>
                    </div>
                  )}
                </div>
              </div> :
              <div className='dropzone-upload-wrapper'>
                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt notAllowedActions">
                  <div className={"d-flex justify-content-center align-items-center d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                    <div id="disabledIcon" className={"d-flex justify-content-center align-items-center hover-scale-rounded shadow-light-sm rounded-lg text-first notUpload d-" + sizeIn} style={{ height: sizeIn, width: sizeIn }}>
                      <CloseBoxIcon className={"text-danger d-" + sizeIcon} style={{ height: sizeIcon, width: sizeIcon }} />
                      <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"disabledIcon"}>
                        {t('notAllowed')}
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
          : null}

        {alert}
      </div>

    </>
  )
})

export default withTranslation('common', { withRef: true })(DropZone);