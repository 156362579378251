import axios from "axios";
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import Header from "components/Headers/Header";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API';
import { Formik } from 'formik';
import { motion } from "framer-motion";
import PenIcon from "mdi-react/PenIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import moment from 'moment';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, Container, Input, Modal, ModalBody, ModalHeader, UncontrolledTooltip, CustomInput } from 'reactstrap';
import "../../css/reactTable.css";
import KeyIcon from "mdi-react/KeyIcon"
import * as Yup from "yup"
const ethers = require('ethers');

function ProductAreaList(props) {
  const getActionsList = props.getActionsList;
  const [dataActionsList, setdataActionsList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [modal, setModal] = React.useState(false)
  const [modalEdit, setModalEdit] = React.useState(false)
  const [modalPass, setModalPass] = React.useState(false)
  const [stateEdit, setEdit] = React.useState('')
  const [alert, setAlert] = React.useState(null)
  const { t } = props;

  const genTable = React.useCallback((productAreaList) => {
    const dataReverse = productAreaList.reverse()
    let data = dataReverse.map((prop, index) => {
      return {
        username: prop.username,
        name: prop.fullname,
        time: moment(prop.date).format("DD/MM/YYYY"),
        status: prop.active ? 'Đang hoạt động' : "Đã khóa",
        role: prop.role === "U" ? "QTV Cổng TXNG" : "QTV Chợ đầu mối Hoà Cường",
        action: (
          <>
            <Button
              color="neutral-success"
              onClick={() => handleEdit(prop)}
              className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
              id={"productEdit" + index}>
              <span className="btn-wrapper--icon d-flex">
                <PenIcon
                  size={18}
                  className="text-success font-size-sm"
                  onClick={() => handleEdit(prop)}
                />
              </span>
            </Button>
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"productEdit" + index}>
              {t('edit')}
            </UncontrolledTooltip>
            <Button
              color="neutral-success"
              onClick={() => toggleChangePass(prop.uuid)}
              className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
              id={"pass" + index}>
              <span className="btn-wrapper--icon d-flex">
                <KeyIcon
                  size={18}
                  className="text-warning font-size-sm"
                  onClick={() => toggleChangePass(prop.uuid)}
                />
              </span>
            </Button>
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"pass" + index}>
              Cập nhật mật khẩu tài khoản
            </UncontrolledTooltip>
          </>
        )
        //  ...prop
      }
    })
    setdataActionsList(data)
    setLoading(false)
  }, [])

  useEffect(() => {
    getActionsList()
  }, [getActionsList]);

  const actionList = props.actionList.data
  useEffect(() => {
    if (actionList && actionList.length > 0) {
      genTable(actionList)
    } else {
      genTable([])
    }

  }, [actionList, genTable]);


  const columns = React.useMemo(
    () => [
      {
        Header: 'Tên',
        accessor: "name",
        filterable: false
      },
      {
        Header: 'Tên đăng nhập',
        accessor: "username",
        sortable: false
      },
      {
        Header: 'Vai trò',
        accessor: "role",
        filterable: false
      },
      {
        Header: 'Trạng thái',
        accessor: "status",
        filterable: false
      },
      {
        Header: 'Hành động',
        accessor: "action",
        filterable: false
      },
    ],
    [t]
  );


  const toggleModal = () => {
    setModal(!modal)
  }
  const toggleChangePass = (uuid) => {
    if (uuid) {
      setUuid(uuid)
    }
    setModalPass(!modalPass)
  }
  const handleEdit = (data) => {
    setEdit(data);
    toggleEdit();
  }
  const toggleEdit = () => {
    setModalEdit(!modalEdit)
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const reloadData = () => {
    getActionsList();
    hideAlert();
  }

  const handleCreate = async (values) => {
    setAlert(<Loading />)

    let temp = new Buffer(values.password).toString('hex')
    let encryptedPass = await ethers.utils.keccak256('0x' + temp)
    let data_signIn = {
      "email": values.email,
      "pw_hash": encryptedPass,
      "username": values.username
    }
    axios.post(LINK_API.CREATE_ADMIN, data_signIn)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)

        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
      })
  }
  const handleSubmitInfo = (data) => {
    toggleEdit();
    setAlert(<Loading />);
    let payload = {
      "phone": data.phone,
      "active": data.active === "false" ? false : true,
      "fullname": data.fullname,
      // "role": data.role
    }
    axios.put(LINK_API.EDIT_ADMIN + data.uuid, payload)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)

        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
      })
  }
  const [uuidAdmin, setUuid] = React.useState('')
  const handleResetPass = async (data) => {
    setAlert(<Loading />)
    toggleChangePass();
    let temp = new Buffer(data.newPass).toString('hex')
    let encryptedPass = await ethers.utils.keccak256('0x' + temp)
    let newData = {
      newPassword: encryptedPass
    }
    axios.put(LINK_API.CHANGE_PASS_ADMIN_ACCOUNT + uuidAdmin, newData)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)

        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
      })
  }
  const handleNext = () => {
    props.history.push('/sadmin/createAdmin')
  }
  return (

    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header />
        <Container className='mt--7' fluid>
          <Card className="card-box mb-5">
            <div className="card-header py-3 d-flex justify-content-between">
              <div className=" font-weight-bold card-header--title font-size-lg">Danh sách tài khoản quản trị viên</div>
              <Button onClick={handleNext} color="info" >
                <span className="btn-wrapper--icon">
                  <PlusCircle size={16} />
                </span>
                <span className="btn-wrapper--label">{t('product.add')}</span>
              </Button>
            </div>

            <div className="divider" />
            <div className="card-body pt-2 px-4 pb-4">

              <ReactTable
                noDataText={t('noData')}
                data={dataActionsList}
                columns={columns}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                className="-striped -highlight"
                loading={loadingState}
                LoadingComponent={LoadingTable}
              />
            </div>
          </Card>
        </Container>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <p className='font-size-lg mb-0'>Thêm tài khoản nhân viên mới</p>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ username: '', password: '', email: '', repass: '' }}
              onSubmit={(values, actions) => {
                handleCreate(values);
              }}
            >
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <label>Họ và tên</label>
                  <Input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.username}
                    name="username"
                    placeholder='Tên đăng nhập'
                  />
                  {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                  <label>Email</label>
                  <Input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                    name="email"
                    placeholder="Địa chỉ email"
                  />
                  {props.errors.email && <div id="feedback">{props.errors.email}</div>}
                  <label>Mật khẩu</label>
                  <Input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.password}
                    name="password"
                    placeholder='Mật khẩu'
                  />
                  <label>Nhập lại mật khẩu</label>
                  <Input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.repass}
                    name="repass"
                    placeholder='Nhập lại mật khẩu'
                  />
                  <div className='mt-3 d-flex justify-content-between'>
                    <Button color="danger" outline onClick={toggleModal}>Hủy</Button>
                    <Button type='submit' color="success" onClick={toggleModal}>Tạo mới</Button>{' '}
                  </div>
                </form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        {alert}
        <Modal isOpen={modalEdit} toggle={toggleEdit}>
          <ModalHeader toggle={toggleEdit}>
            <p className='font-size-lg mb-0'>Chỉnh sửa thông tin</p>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ ...stateEdit }}
              onSubmit={(values, actions) => {
                handleSubmitInfo(values);
              }}
            >
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <label className='font-weight-bold'>Họ và tên</label>
                  <Input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.fullname}
                    name="fullname"
                    placeholder='Họ và tên'
                  />
                  {/* <label className="font-weight-bold">Vai trò <span className="text-danger"></span></label>
                  <CustomInput type="select" id="selectUnit" placeholder='Vai trò'
                    value={props.values.role}
                    name="role"
                    onChange={props.handleChange}
                  >
                    <option value={'U'}>QTV cổng truy xuất nguồn gốc</option>
                    <option value={'Z'}>QTV chợ đầu mối Hòa Cường</option>
                  </CustomInput> */}
                  <label className='font-weight-bold'>Trạng thái</label>

                  <CustomInput type="select" id="selectUnit" placeholder='Trạng thái tài khoản'
                    value={props.values.active}
                    onChange={props.handleChange}
                    name="active"
                  >
                    <option value={true}>Đang hoạt động</option>
                    <option value={false}>Đã khóa</option>
                  </CustomInput>
                  <div className='mt-3 d-flex justify-content-between'>
                    <Button color="danger" outline onClick={toggleEdit}>Hủy</Button>
                    <Button type='submit' color="success">Xác nhận</Button>{' '}
                  </div>

                </form>
              )}
            </Formik>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalPass} toggle={toggleChangePass}>
          <ModalHeader toggle={toggleChangePass}>
            <p className='font-size-lg mb-0'>Thay đổi mật khẩu</p>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ newPass: '', rePass: '' }}
              onSubmit={(values, actions) => {
                handleResetPass(values);
              }}
              validationSchema={
                Yup.object().shape({
                  newPass: Yup.string().required('Thông tin bắt buộc'),
                  rePass: Yup.string()
                    .oneOf([Yup.ref('newPass'), null], 'Mật khẩu vừa nhập không trùng khớp')
                })
              }
            >
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <label className='font-weight-bold'>Mật khẩu mới</label>
                  <Input
                    type="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.newPass}
                    name="newPass"
                    placeholder='Mật khẩu mới'
                  />
                  {props.errors.newPass && <p id="feedback text-danger">{props.errors.newPass}</p>}

                  <label className='font-weight-bold'>Nhập lại mật khẩu</label>
                  <Input
                    type="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.rePass}
                    name="rePass"
                    placeholder='Nhập lại mật khẩu'
                  />
                  {props.errors.rePass && <p id="feedback text-danger">{props.errors.rePass}</p>}
                  <div className='mt-3 d-flex justify-content-between'>
                    <Button color="danger" outline onClick={toggleChangePass}>Hủy</Button>
                    <Button type='submit' color="success">Xác nhận</Button>{' '}
                  </div>
                </form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </motion.div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    actionList: state.adminList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getActionsList: () => dispatch({ type: "GET_ADMIN_LIST" }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProductAreaList));