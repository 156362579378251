import Axios from 'axios';
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import Header from "components/Headers/Header.js";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import { AGD_LOGO } from "constants/image";
import { useFormik } from 'formik';
import { motion } from "framer-motion";
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";
import PlusCopy from "mdi-react/ContentCopyIcon";
import FilterIcon from "mdi-react/FilterIcon";
import PenIcon from "mdi-react/LeadPencilIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import ResetIcon from "mdi-react/RestoreIcon";
import React, { useEffect } from 'react';
import cookie from "react-cookies";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Button, Card, Container, FormGroup, Form, Input, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";
import axios from "axios"
import { Formik, Field } from "formik"
import KeyIcon from "mdi-react/KeyIcon"
import CheckAllIcon from "mdi-react/CheckAllIcon"
import ConfirmAlert from "components/alert/confirmAlert"
import ContentAlert from "components/alert/contentAlert"
import DetailIcon from "mdi-react/InfoOutlineIcon"
import EyeIcon from "mdi-react/EyeIcon";
import EyeOffIcon from "mdi-react/EyeOffIcon";

function ProductList(props) {
  const getProductList = props.getProductTypeList;
  const getProductListFilter = props.getProductTypeListFilter;
  const productList = props.productList.data
  const productListToal = props.productList;
  const totalPageProps = props.productList.totalPage
  const permission = props.permission;
  const [checkPer, setPer] = React.useState(false);
  React.useEffect(() => {
    if (permission) {
      if (permission.maxProductType === -1) {
        setPer(false)
      } else {
        if (permission) {
          if (permission.productType >= permission.maxProductType) {
            setPer(true)
          }
        }
      }
    }
  }, [permission, productListToal])
  const [dataProductList, setDataProductList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true);
  const [filterStatus, setFilter] = React.useState(false)
  const { t, history } = props;
  const [alert, setAlert] = React.useState(null)
  const handleEdit = React.useCallback((uuid) => {
    cookie.save("uuidProduct", uuid, { path: "/" });
    if (props.userInfo.data.role === 'U') {
      props.history.push('/market/productEdit/' + uuid)
    } else {
      history.push("/user/productEdit")
    }
  }, [history])

  const handleCopy = React.useCallback((uuid) => {
    cookie.save("uuidProductCopy", uuid, { path: "/" });

    if (props.userInfo.data.role === 'U') {
      props.history.push('/market/productCreate/' + uuid)
    } else {
      history.push("/user/productCreate")
    }
  }, [history])
  const hideAlert = () => {
    setAlert(null);
    getProductList(1, 5);
  }


  const handleResetPassword = React.useCallback((values, uuid) => {
    setAlert(<Loading />)
    const data = {
      "password": values.newPass,
      "uuid": uuid
    }
    console.log("data", values)
    axios.post(LINK_API.CHANGE_PASS_ADMIN, data)
      .then((response) => {
        console.log("response", response)
        if (response.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
        }
        else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(() => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }, [t])

  const resetPassword = React.useCallback((uuid) => {
    const ChildComponent = (
      <div>
        <Formik
          validationSchema={
            Yup.object().shape({
              newPass: Yup.string()
                .min(6, t('tooShort'))
                .required(t('require'))
            })
          }
          initialValues={{ newPass: '', rePass: '' }}
        // onSubmit={values => handleResetPassword(values, uuid)}
        >
          {({ errors, touched, values }) => (
            <Form>
              <Field
                name="newPass"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('account.newPass')}
                    </label>
                    <Input {...props} type="password" {...props.field} placeholder={t('account.newPass')} />
                  </FormGroup>
                )}
              />
              {errors.newPass && touched.newPass ? <div>{errors.newPass}</div> : null}
              <Button color="info" onClick={() => handleResetPassword(values, uuid)}>{t('confirm')}</Button>
            </Form>
          )}
        </Formik>
      </div>
    )
    setAlert(
      <ContentAlert
        title={t('employee.enterInfo')}
        hideCancel={true}
        onCancel={hideAlert}
        hideConfirm={true}
        child={ChildComponent}
      />

    )
  }, [t, handleResetPassword])


  const confirmActive = React.useCallback((uuid) => {
    axios.put(LINK_API.CHANGE_STATUS_ACCOUNT + uuid + "/active")
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
          getProductList(1, 5)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }, [t])

  const handleActive = React.useCallback((uuid, title) => {
    let ChildComponent = <div>{title}</div>
    setAlert(
      <ConfirmAlert
        onClose={hideAlert}
        onConfirm={() => confirmActive(uuid)}
        confirmBtnText={t('confirm')}
        child={ChildComponent}
      />
    )
  }, [confirmActive, t])

  const handleDetail = (uuid) => {
    cookie.save("uuidManu", uuid, { path: "/" });
    if (props.userInfo.data.role === 'Z') {
      props.history.push('/admin/detailAccount/' + uuid)
    }
    else if (props.userInfo.data.role === 'U') {
      props.history.push('/market/detailAccount/' + uuid)
    } else {
      props.history.push('/sadmin/detailAccount/' + uuid)
    }
  }


  
  const genTable = React.useCallback((productList) => {
    const handleDataProductList = productList.map((prop, index) => {
      return {
        ...prop,
        id: prop.uuid,
        name: prop.name,
        username: prop.username ? prop.username : 'Chưa có',
        status: prop.active ? "Đang hoạt động" : "Ngưng hoạt động",
        actions: <div className="d-flex flex-row justify-content-center">
          {/* <Button onClick={() => resetPassword(prop.uuid)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipEdit" + index}>
          <span className="btn-wrapper--icon d-flex">
            <KeyIcon
              size={18}
              className="text-success font-size-sm"
            />
          </span>
        </Button>
        <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipEdit" + index}>
          {t('account.newPass')}
        </UncontrolledTooltip> */}
          <Button onClick={() => handleDetail(prop.uuid)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"detailAccount" + index}>
            <span className="btn-wrapper--icon d-flex">
              <DetailIcon
                size={18}
                className="text-info font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"detailAccount" + index}>
            Chi tiết tài khoản
          </UncontrolledTooltip>

          {
            !prop.active ?
              <>
                <Button onClick={() => handleActive(prop.uuid, t('employee.active'))} onClcolor="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipDeactive" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <CheckAllIcon
                      size={18}
                      className="text-info font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipDeactive" + index}>
                  {t('employee.active')}
                </UncontrolledTooltip>
              </>
              :
              <>
                <Button onClick={() => handleActive(prop.uuid, t('employee.deActive'))} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipDeactive" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <CloseIcon
                      size={18}
                      className="text-danger font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipDeactive" + index}>
                  {t('employee.deActive')}
                </UncontrolledTooltip>
              </>
          }
          {
            prop.hided ?
              <>
                <Button onClick={() => handleChangeStatus(prop.uuid, prop.hided)} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"active" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <EyeIcon
                      size={18}
                      className="text-success font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"active" + index}>
                  Hiển thị trên trang truy xuất
              </UncontrolledTooltip>
              </>
              :
              null}
          {
            !prop.hided ?
              <>
                <Button onClick={() => handleChangeStatus(prop.uuid, prop.hided)} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"productDeactive" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <EyeOffIcon
                      size={18}
                      className="text-danger font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"productDeactive" + index}>
                  Ẩn trên trang truy xuất nguồn gốc
              </UncontrolledTooltip>
              </>
              :
              null
          }
          <Button
            color="neutral-success"
            onClick={() => resetPassword(prop.uuid)}
            className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            id={"pass" + index}>
            <span className="btn-wrapper--icon d-flex">
              <KeyIcon
                size={18}
                className="text-warning font-size-sm"
                onClick={() => resetPassword(prop.uuid)}
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"pass" + index}>
            Cập nhật mật khẩu tài khoản
          </UncontrolledTooltip>
        </div>
      }
    })
    setDataProductList(handleDataProductList)
    setLoading(false)
  }, [t, handleEdit, handleCopy])

  const handleChangeStatus = (ownedBy, hide) => {
    const data = {
      hideList: [
        {
          [ownedBy]: !hide
        }
      ]
    }

    axios.post(LINK_API.HIDE_COMPANY_PORTAL, data)
      .then(async res => {
        getProductList(1, 5);
        setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }

  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      getProductListFilter(1, values.name, values.value, 5)
    }, []),
  });
  useEffect(() => {
    getProductList(1, 5)
    // }, [getProductList]);
    if (!props.userInfo || props.userInfo.data === "") {
      props.history.push('/login')
    }
    else {
      getProductList(1, 5)
    }
  }, [getProductList]);


  useEffect(() => {
    if (productList && productList.length > 0) {
      genTable(productList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }
    if (!props.userInfo || props.userInfo.data === "") {
      props.history.push('/login')
    }
    else if (productList && productList.length > 0) {
      genTable(productList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }

  }, [totalPageProps, productList]);


  const columns = React.useMemo(
    () => [
      {
        Header: "Tên",
        accessor: "name",
        sortable: false
      },
      {
        Header: "Tên đăng nhập",
        accessor: "username",
        filterable: false
      },
      {
        Header: "Trạng thái",
        accessor: "status",
        filterable: false
      },
      {
        Header: "Hành động",
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  const handleChangePage = () => {
    if (props.userInfo.data.role === 'S') {
      props.history.push('/sadmin/createSub')
    }
    else if (props.userInfo.data.role === 'U') {
      props.history.push('/market/createSub')
    }
    else {
      props.history.push('/admin/createSub')
    }

  }


  return (

    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header />
        <Container className="mt--7" fluid>
          <Card className="card-box mb-5">
            <div className="card-header py-3 d-flex justify-content-between">
              <div className="font-weight-bold card-header--title font-size-lg">Danh sách hộ kinh doanh</div>
              <div className="card-header--actions d-flex flex-row">
                <div style={{ display: "inline-block" }} id="notiPlan">
                  <Button color="info" onClick={() => handleChangePage()} >
                    <span className="btn-wrapper--icon">
                      <PlusCircle size={16} />
                    </span>
                    <span className="btn-wrapper--label">{t('product.add')}</span>
                  </Button>
                </div>
                {checkPer ?
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
                    {t('notiPlan')}
                  </UncontrolledTooltip> : null}
              </div>
            </div>

            <div className="divider" />
            <div className="card-body pt-2 px-4 pb-4">

              <ReactTable
                noDataText={t('noData')}
                data={dataProductList}
                columns={columns}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                loading={loadingState}
                LoadingComponent={LoadingTable}
                manual
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                pages={totalPage}
                className="-striped -highlight"
                onFetchData={async (state, instance) => {
                  setLoading(true)
                  let x = state.page + 1;
                  if (filterStatus === false) {
                    getProductList(x, state.pageSize)
                  } else {
                    getProductListFilter(x, formik.values.name, formik.values.value, state.pageSize)
                  }
                }}
              />
            </div>
          </Card>
        </Container>
      </motion.div>
      {alert}


    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    productList: state.allManu,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProductTypeList: (page, size) => dispatch({ type: "GET_ALL_MANU", page: page, size: size }),
  getProductTypeListFilter: (page, name, value, size) => dispatch({ type: "GET_ALL_MANU_FILTER", page: page, name: name, value: value, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProductList));