import Header from "components/Headers/Header.js";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { motion } from "framer-motion";
import PlusCircle from "mdi-react/PlusCircleIcon";
import React from 'react';
import cookie from "react-cookies";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, Container, UncontrolledTooltip } from 'reactstrap';
import "../../css/reactTable.css";

function ProductList(props) {
  const getProductList = props.getProductTypeList;
  const productListToal = props.productList;
  const permission = props.permission;
  const [checkPer, setPer] = React.useState(false);
  const getBoxList = props.getBoxList;
  const boxList = props.boxList;
  const [modal, setModal] = React.useState(false)
  const [idBox, setIdBox] = React.useState('')
  const handleChange = (e) => {
    setIdBox(e.target.value)
  }
  const toggleModal = () => {
    setModal(!modal)
  }
  React.useEffect(() => {
    getBoxList();
  }, [getBoxList])

  React.useEffect(() => {
    if (permission) {
      if (permission.maxProductType === -1) {
        setPer(false)
      } else {
        if (permission) {
          if (permission.productType >= permission.maxProductType) {
            setPer(true)
          }
        }
      }
    }
  }, [permission, productListToal])
  const [dataProductList, setDataProductList] = React.useState([])
  const [loadingState, setLoading] = React.useState(false);
  const { t, history } = props;
  const [alert, setAlert] = React.useState(null)
  const handleEdit = React.useCallback((uuid) => {
    cookie.save("uuidProduct", uuid, { path: "/" });
    history.push("/user/productEdit")
  }, [history])

  const handleCopy = React.useCallback((uuid) => {
    cookie.save("uuidProductCopy", uuid, { path: "/" });
    if (props.userInfo.data.role === 'Z') {
      history.push("/admin/createGuard")
    } else {
      history.push("/sadmin/createGuard")
    }
  }, [history])
  const hideAlert = () => {
    setAlert(null);
    getProductList(1, 5, 'actionType', 'CASE_IMPORT');
  }

  const genTable = React.useCallback((productList) => {
    console.log(productList)
    let data = productList.map((prop, index) => {
      return {
        ...prop,
        ownedBy: prop.OwnerInfo ? prop.OwnerInfo.name : '',
        uuid: prop.ownedBy + prop.productionObjectUuid
        //  ...prop
      }
    })

    setDataProductList(data)
    setLoading(false)
  }, [t, handleEdit, handleCopy])

  React.useEffect(() => {
    if (boxList.data) {
      genTable(boxList.data)
    }
  }, [boxList])
  const columns = React.useMemo(
    () => [
      {
        Header: 'Mã lô hàng',
        accessor: "uuid",
        filterable: false,
      },
      {
        Header: 'Tên lô hàng',
        accessor: "name",
        filterable: false,
      },
      {
        Header: 'Chủ lô hàng',
        accessor: "ownedBy",
        filterable: false,
      },

      // {
      //   Header: 'Hành động',
      //   accessor: "actions",
      //   filterable: false,
      // },
    ],
    [t]
  );



  return (

    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header />
        <Container className="mt--7" fluid>
          <Card className="card-box mb-5">
            <div className="card-header py-3 d-flex justify-content-between">
              <div className="font-weight-bold card-header--title font-size-lg">Danh sách lô hàng chưa nhận</div>
              <div className="card-header--actions d-flex flex-row">
                <div style={{ display: "inline-block" }} id="notiPlan">
                  <Button disabled={checkPer} color="info" onClick={() => props.history.push('/user/boxImport')} >
                    <span className="btn-wrapper--icon">
                      <PlusCircle size={16} />
                    </span>
                    <span className="btn-wrapper--label">Nhận hàng</span>
                  </Button>
                </div>
                {checkPer ?
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
                    {t('notiPlan')}
                  </UncontrolledTooltip> : null}
              </div>
            </div>

            <div className="divider" />
            <div className="card-body pt-2 px-4 pb-4">

              <ReactTable
                noDataText={t('noData')}
                data={dataProductList}
                columns={columns}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                loading={loadingState}
                LoadingComponent={LoadingTable}
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                className="-striped -highlight"
              />
            </div>
          </Card>
        </Container>
      </motion.div>
      {alert}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    productList: state.activityAdmin,
    permission: state.permission.data,
    boxList: state.historyImport
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProductTypeList: (page, size, name, value) => dispatch({ type: "GET_ACTION_LIST_ADMIN_FILTER", page: page, name: name, value: value, size: size }),
  getBoxList: () => dispatch({ type: 'GET_HISTORY_IMPORT' })
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProductList));