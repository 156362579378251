import DropZone from "components/dropZone";
import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { Input, Button, Card, Container, UncontrolledTooltip } from "reactstrap";
import "view/css/select.css";
import * as Yup from 'yup';
import Select from 'react-select';
import "./css/index.css"
import { connect } from 'react-redux';
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import Header from "components/Headers/Header"
import ReplayIcon from "mdi-react/ReplayIcon"
const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";

const CreatePartner = (props) => {
  const t = props.t;
  const permission = props.permission.data;

  const [listImage, setImage] = React.useState([]);

  const [submitedType, setSubmitedType] = React.useState("F");
  const [type, setType] = React.useState({
    value: "F",
    label: t('partner.distributor')
  });

  const [uuid, setUuid] = React.useState(null);
  const [errorUuid, setErrorUuid] = React.useState(false);

  const TypeList = [
    {
      value: "F",
      label: t('partner.distributor')
    },
    {
      value: "R",
      label: t('partner.retailer')
    },
    {
      value: "RC",
      label: t('partner.retailCustomer')
    },
    {
      value: "W",
      label: t('partner.wholesaleCustomers')
    },
  ]

  let linkUrl = "user/";
  if (props.userInfo && props.userInfo.data && props.userInfo.data.role === "F") {
    linkUrl = "distributor/"
  }

  const handleAva = (data) => {
    setImage(data);
  }

  const submitData = async (value) => {
    const data = {
      "name": uuid ? "" : value.name,
      "uuid": uuid ? uuid : "",
      "images": uuid ? "" : listImage,
      "address": uuid ? "" : value.address,
      "phone": uuid ? "" : value.phone,
      "website": uuid ? "" : value.website,
      "description": uuid ? "" : value.description,
      "partnerRole": uuid ? "" : submitedType
    }
    props.createPartner(data);
    props.history.push(`/${linkUrl}partner/list`);
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      phone: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      address: Yup.string()
        .required(t('require')),
      phone: Yup.string()
        .required(t('require')),
    }),
    onSubmit: async (values) => {
      submitData(values)
    },
  });

  const handleChange_SelectType = (selectedOption) => {
    setSubmitedType(selectedOption.value)
    setType(selectedOption)
  }

  const handleSelectSuggest = (geocodedPrediction) => {
    formik.setFieldValue("address", geocodedPrediction.formatted_address)
    formik.setFieldValue("search", "")
  }

  const handleNoResult = () => {
    console.log('Không có kết quả')
  }

  const handleChangeAddress = name => event => {
    let values = event.target.value;
    if (values) {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
    } else {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
      formik.setFieldError("address", t('require'))
    }
  }

  const handleRole = (role) => {
    if (role === "RC")
      return {
        value: "RC",
        label: t('partner.retailCustomer')
      }
    if (role === "R")
      return {
        value: "R",
        label: t('partner.retailer')
      };
    if (role === "W")
      return {
        value: "W",
        label: t('partner.wholesaleCustomers')
      }
    return {
      value: "F",
      label: t('partner.distributor')
    }
  }

  const handleFind = () => {
    if (formik.getFieldProps("uuid").value) {
      axios.get(LINK_API.GET_INFO_DISTRIBUTOR + formik.getFieldProps("uuid").value)
        .then(response => {
          if (response.data.errorCode === 1) {
            let numberPhone = ""
            if (response.data.data.phone) {
              if (response.data.data.phone.includes("+84")) {
                numberPhone = response.data.data.phone.split("+84").join("0");
                numberPhone = numberPhone.split(" ").join("")
              }
              else {
                numberPhone = response.data.data.phone
              }
            }
            formik.setValues({
              name: response.data.data.name,
              address: response.data.data.address,
              phone: numberPhone,
              website: response.data.data.website,
            });

            setUuid(formik.getFieldProps("uuid").value)
            setSubmitedType(response.data.data.role)
            setType(handleRole(response.data.data.role))
            setErrorUuid(false)
          }

        })
        .catch(err => {
          console.log("err", err)
          setErrorUuid(true)
        })
    }
  }
  const handleClean = () => {
    formik.resetForm();
    formik.setFieldValue('uuid', '')
  }
  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        {
          permission.agdqr ? <div> </div> :

            <Card className="card-box mb-5">
              <div className="wizard-steps horizontal">
                <form onSubmit={formik.handleSubmit}>
                  <div className="p-4">
                    <div className='d-flex'>
                      <h5 className="font-size-xl font-weight-bold">{t('partner.createPartner')}</h5>
                      <Button id='resetData' onClick={handleClean} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                        <span className="btn-wrapper--icon d-flex">
                          <ReplayIcon
                            size={18}
                            className="text-danger font-size-sm"
                          />
                        </span>
                      </Button>
                      <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"resetData"}>
                        Làm mới
          </UncontrolledTooltip>
                    </div>

                    <p className="text-black-50 mb-4"></p>
                    <div className="form-row ">
                      <div className="form-group col-md-7 pr-3">
                        <div className="form-group">
                          <label className="font-weight-bold">{t('partner.uuidPartner')}</label>
                          <div className="form-group ">
                            <div style={{ display: "flex" }}>
                              <Input
                                placeholder={t('partner.uuidPartner')}
                                type="text"
                                name="uuid"
                                value={formik.values.uuid}
                                invalid={formik.errors.uuid ? true : false}
                                {...formik.getFieldProps('uuid')}
                              />
                              <Button
                                onClick={() => handleFind()}
                                size="sm"
                                className="text-uppercase font-weight-bold font-size-sm styleButton"
                                color="info">
                                {t('partner.find')}
                              </Button>
                            </div>
                          </div>
                          {errorUuid ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{t('partner.notFound')}</p>
                          ) : null}
                        </div>

                        <div className="form-group">
                          <label className="font-weight-bold">{t('partner.name')} <span className="text-danger">(*)</span></label>
                          <Input
                            placeholder={t('partner.name')}
                            type="text"
                            name="name"
                            value={formik.values.name}
                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                            {...formik.getFieldProps('name')}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                          ) : null}
                        </div>

                        <div className="form-group ">
                          <label className="font-weight-bold" >{t('area.type')}</label>
                          <Select
                            placeholder={t('area.type')}
                            options={TypeList}
                            onChange={handleChange_SelectType}
                            value={type}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '0.29rem',
                              borderWidth: 1,
                              colors: {
                                ...theme.colors,
                                primary25: 'rgba(60,68,177,0.15)',
                                primary50: 'rgba(60,68,177,0.15)',
                                primary: '#4FB779'
                              }
                            })}
                          />
                        </div>

                        <div className="form-group">
                          <label className="font-weight-bold">{t('product.address')} <span className="text-danger">(*)</span></label>
                          <ReactGoogleMapLoader
                            params={{
                              key: MY_API_KEY,
                              libraries: "places,geocode",
                            }}
                            render={googleMaps =>
                              googleMaps && (
                                <div>
                                  <ReactGooglePlacesSuggest
                                    autocompletionRequest={{ input: formik.values.search }}
                                    googleMaps={googleMaps}
                                    onSelectSuggest={handleSelectSuggest}
                                    displayPoweredByGoogle={false}
                                    onNoResult={handleNoResult}
                                  >
                                    <Input
                                      placeholder={t('product.address')}
                                      onChange={handleChangeAddress('address')}
                                      type="text"
                                      name="address"
                                      value={formik.values.address}
                                      valid={formik.touched.address && formik.errors.address ? false : (formik.values.address ? true : false)}
                                      invalid={formik.touched.address && formik.errors.address ? true : false}
                                    />
                                  </ReactGooglePlacesSuggest>
                                </div>
                              )
                            }
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.address}</p>
                          ) : null}
                        </div>

                        <div className="form-group">
                          <label className="font-weight-bold">{t('product.phone')} <span className="text-danger">(*)</span></label>
                          <Input
                            placeholder={t('product.phone')}
                            type="number"
                            name="phone"
                            value={formik.values.phone}
                            valid={formik.touched.phone && formik.errors.phone ? false : (formik.values.phone ? true : false)}
                            invalid={formik.touched.phone && formik.errors.phone ? true : false}
                            {...formik.getFieldProps('phone')}
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.phone}</p>
                          ) : null}
                        </div>

                        <div className="form-group">
                          <label className="font-weight-bold">{"Website"}</label>
                          <Input
                            placeholder={"Website"}
                            type="text"
                            name="website"
                            value={formik.values.website}
                            {...formik.getFieldProps('website')}
                          />
                        </div>
                      </div>

                      <div
                        className="form-group d-flex justify-content-center col-md-5 pl-3"
                      >
                        <div className="form-group">
                          <label className="font-weight-bold">{t('image')}</label>
                          <DropZone
                            max={1}
                            onConfirm={handleAva}

                            data={listImage}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                    </div>

                    <div className="form-row ">
                      <div className="form-group col-md-6">
                        <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                      </div>
                    </div>

                    <div className="form-row ">
                      <div className="form-group col-md-6 d-flex">
                        <Button
                          size="lg"
                          type="submit"
                          className="text-uppercase font-weight-bold font-size-sm"
                          color="info">
                          {t('addVote')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Card>
        }
        {alert}
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    permission: state.permission
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPartner: (data) => dispatch({ type: "CREATE_DISTRIBUTOR", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreatePartner));