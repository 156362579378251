// const HOST = process.env.REACT_APP_ENV === 'dev' ?  'http://www.agridential.vn:8000' : 'https://www.agridential.vn' ;

const HOST = process.env.REACT_APP_ENV === 'dev' ? 'http://167.99.70.59:8001' : 'https://quanly.txngdanang.vn'
const HOST_PORTAL = process.env.REACT_APP_ENV === 'dev' ? 'https://portal-staging.agridential.vn/lookup/' : 'https://portal.agridential.vn/lookup/'
const HOST_STATISTIC = process.env.REACT_APP_ENV === 'dev' ? 'https://staging.agridential.vn/statistic' : 'https://staging.agridential.vn/statistic'

const LINK_API = {
  REGISTER: HOST + '/api/v1/users/register/',
  SIGN_IN: HOST + '/api/v1/users/signin/',
  SIGN_OUT: HOST + '/api/v1/users/logout',
  CHANGE_PWD: HOST + '/api/v1/users/changepassword',
  EDIT_PROFILE: HOST + '/api/v1/users/editprofile',
  GET_PROFILE: HOST + '/api/v1/users/profile',
  CREATE_PRODUCT: HOST + '/api/v1/producttypes',
  SEND_RAW_PRODUCT: HOST + '/api/v1/producttypes/sendraw',
  GET_ALL_PRODUCTS: HOST + '/api/v1/producttypes',
  STOP_PRODUCTION: HOST + '/api/v1/products/stopProduction',
  GET_ONE_PRODUCT_BY_INDEX: HOST + '/api/v1/products',
  GET_PRODUCE_DIARY: HOST + '/api/v1/cooperatives/diary',
  ADD_NEW_FARM: HOST + '/api/v1/cooperatives/farms',
  GET_FARM_LIST: HOST + '/api/v1/productionareas',
  GET_FARM_INFO: HOST + '/api/v1/farms',
  ADD_DIARY: HOST + '/api/v1/farms/diary',
  CREATE_TREE: HOST + '/api/v1/trees',
  GET_TREE_LIST: HOST + '/api/v1/trees',
  GET_TREE: HOST + '/api/v1/trees/info',
  CREATE_STAMP: HOST + '/api/v1/stamps',
  GET_STAMP_HISTORY: HOST + '/api/v1/stamps',
  REGISTER_TRIAL: HOST + '/api/v1/users/trial/register',
  VERIFY_TOKEN: HOST + '/api/v1/token',
  GET_PROCESS_LIST: HOST + '/api/v1/businessprocess/getall',
  GET_PROCESS_LIST_ALL: HOST + '/api/v1/businessprocess?lg=en',
  REGISTER_PROCESS: HOST + '/api/v1/businessprocess',
  STAMP_AUTHORIZE: HOST + '/api/v1/admin/stamp/authorize',
  GET_HISTORY_STAMP_AUTHORIZE: HOST + '/api/v1/admin/stamp',
  GET_COOPERATIVES: HOST + '/api/v1/admin/companies',
  GET_COMPANY: HOST + '/api/v1/admin/companies',
  GET_STATICTIS: HOST + '/api/v1/statistics',
  GET_TREE_OF_NH: HOST + '/api/v1/farms/trees?gln=',
  TRIAL: HOST + '/api/v1/trial',
  SEND_MAIL: HOST + '/api/v1/trial/sendemail',
  GET_IMPORT_LIST: HOST + '/api/v1/retailer/diary',
  GET_PAID_PRODUCT: HOST + '/api/v1/retailer/soldproduct?gln=',
  SELL_PRODUCT: HOST + '/api/v1/products/sell',
  GET_PRODUCT_STAMP: HOST + '/api/v1/diaries/baseinfo?gln=',
  CREATE_MATERIAL: HOST + '/api/v1/materials',
  SELL_MUL_PRODUCT: HOST + 'api/v1/retailer/sellmultiproduct',
  GET_PROCESS_LIST_PRODUCT: HOST + '/api/v1/businessprocess/getall',
  GET_STAMP_LIST_RETAILER: HOST + '/api/v1/retailer/diary?gln=',
  ADD_CERTIFICATES: HOST + '/api/v1/users/addcertificates',
  DELETE_CERTIFICATES: HOST + '/api/v1/users/deletecertificates',
  GET_PATH_USER: HOST + '/api/v1/users/path',
  GET_PATH_EMPLOY: HOST + '/api/v1/users/employees/path',
  CREATE_EMPLOYEE: HOST + '/api/v1/employees',
  GET_EMPLOYEE_LIST: HOST + '/api/v1/employees',
  CREATE_PRODUCTION_AREA: HOST + '/api/v1/productionareas',
  GET_AREA_LIST: HOST + '/api/v1/productionareas',
  CREATE_OBJECT_PRODUCTION: HOST + '/api/v1/productionobjects?page=',
  GET_A_PRODUCTION: HOST + '/api/v1/productionobjects/detail?uuidOfProductionObject=',
  GET_CROP_LIST: HOST + '/api/v1/productionobjects/crops?uuid=',
  ASSIGN_CROP: HOST + '/api/v1/productionobjects/crops/assign',
  STOP_CROP: HOST + '/api/v1/productionobjects/crops/finish',
  VERIFY_ACCOUNT: HOST + '/api/v1/users/verify',
  SEND_RAW: HOST + '/api/v1/users/register/rawtx',
  SEND_RAW_VERIFY: HOST + '/api/v1/users/verify/rawtx',
  SEND_RAW_EMPLOYEE: HOST + '/api/v1/employees/rawtx',
  SEND_RAW_CLAIM: HOST + '/api/v1/users/checkclaim',
  SEND_RAW_CROP: HOST + '/api/v1/productionobjects/crops/assign/rawtx',
  CURRENT_CROP: HOST + '/api/v1/productionobjects/currentcrops',
  EDIT_PROFILE_ADD_AVATAR: HOST + '/api/v1/users/addavatar',
  EDIT_PROFILE_DELETE_AVATAR: HOST + '/api/v1/users/deleteavatar',
  GET_ALL_CROPS: HOST + '/api/v1/productionobjects/croplist',
  GET_INFO_WEB3: HOST + '/api/v1/blockchain/web3',
  CREATE_OBJECT_PROCESS: HOST + '/api/v1/processingobjects',
  GET_OBJECT_PROCESS_DETAIL: HOST + '/api/v1/processingobjects/detail?uuidOfProductionObject=',
  GET_CROP_OBJECT_PROCESS: HOST + '/api/v1/processingobjects/batchs',
  CREATE_BATCH: HOST + '/api/v1/processingobjects/batchs',
  GET_RAW_BATCH: HOST + '/api/v1/processingobjects/batchs/pretx',
  EDIT_PROFILE_BANNER: HOST + '/api/v1/users/updatebanner',
  EDIT_PROFILE_LOGO: HOST + '/api/v1/users/updatelogo',
  CREATE_BRANCH: HOST + '/api/v1/companies/branchs',
  GET_BRANCH_LIST: HOST + '/api/v1/companies/branchs',
  GET_HARVEST_LIST: HOST + '/api/v1/productionobjects/harvests?flat=1',
  GET_DISTRIBUTOR_LIST: HOST + '/api/v1/companies/distributors',
  CREATE_DISTRIBUTOR: HOST + '/api/v1/companies/distributors',
  UPDATE_BANNER: HOST + '/api/v1/users/updatebanner',
  UPDATE_LOGO: HOST + '/api/v1/users/updatelogo',
  GET_INFO_DISTRIBUTOR: HOST + '/api/v1/users/getprofilebyid?uuid=',
  GET_RAW: HOST + '/api/v1/users/checkclaim/pretx',
  UPDATE_OBJECT_PRODUCTION: HOST + '/api/v1/productionobjects/updatebaseinfo',
  UPDATE_OBJECT_PROCESSING: HOST + '/api/v1/processingobjects/updatebaseinfo',
  SUBSCRIBE: HOST + '/api/v1/users/activation',
  GET_QUANTITIY: HOST + '/api/v1/statistics/stampWithDistributor?lg=vi',
  HARVEST_STATISTIC: HOST + '/api/v1/statistics/quantityHarvest',
  BRANCH_STATISTIC: HOST + '/api/v1/statistics/stampWithBranch?lg=vi',
  CUSTOMER_FEEDBACK: HOST + '/api/v1/feedback/?lg=vi',
  GET_SUBSCRIPTION: HOST + '/api/v1/subscription',
  RESET_PASSWORD_EMPLOYEE: HOST + '/api/v1/users/resetemployeepassword',
  GET_PLAN_LIST: HOST + '/api/v1/subscription/plan',
  CREATE_PLAN: HOST + '/api/v1/subscription/plan',
  POST_THIETLAP: HOST + '/api/v1/users/settings',
  CHECK_USERNAME: HOST + '/api/v1/users/username',
  GET_BOX_UNIT: HOST + '/api/v1/cases/owning',
  GET_INFO_SELL_PRODUCT: HOST + '/api/v1/products/sell/pretx',
  GET_CROP_STATISTIC: HOST + '/api/v1/statistics/cropOfProductObjectStatistic?productObjectId=',
  GET_QUANTITIY_HARVEST: HOST + '/api/v1/statistics/harvestQuantityOfPOStatistic',
  GET_NUM_HARVEST: HOST + '/api/v1/statistics/harvestNumberOfPOStatistic',
  GET_GENERAL_STATISTIC: HOST + '/api/v1/statistics/general/?',
  GET_PRODUCT_OBJECT: HOST + '/api/v1/statistics/productionObjectStatistic',
  GET_RAW_APPROVE: HOST + '/api/v1/users/settings/approve',
  SEND_APPROVE: HOST + '/api/v1/users/settings/approve/rawtx',
  GET_SETTING: HOST + '/api/v1/users/settings',
  IMPORT_BOX: HOST + '/api/v1/cases',
  GET_INFO_BOX: HOST + '/api/v1/cases/receive/pretx',
  RECEIVE_BOX: HOST + '/api/v1/cases/receive',
  GET_STATISTIC_NUM_OBJECT: HOST + '/api/v1/statistics/countPO',
  GET_NUMBER_ACCESSES: HOST + '/api/v1/statistics/accessInfo',
  GET_PRODUCT_TO_BRANCH: HOST + '/api/v1/statistics/stampWithBranch',
  GET_PRODUCT_TO_DISTRIBUTOR: HOST + '/api/v1/statistics/stampWithDistributor?lg=vi&type=0',
  GET_APPORVE_LIST: HOST + '/api/v1/companies/actions',
  GET_INFO_APPROVE: HOST + '/api/v1/companies/approves/pretx',
  GET_NUMBER_CROP_PRODUCT_TYPE: HOST + '/api/v1/statistics/numberCropOfPTStatistic?',
  GET_ACTIVE_STAMP_PRODUCT_TYPE: HOST + '/api/v1/statistics/producTypeStatistic',
  GET_STAMP_ACTIVE_GENERAL: HOST + '/api/v1/statistics/getActivatedStamp?',
  APPROVE_ACTIONS: HOST + '/api/v1/companies/approves',
  GET_PRODUCT_TYPE_STATISTIC: HOST + '/api/v1/statistics/producTypeStatistic',
  UPDATE_PRODUCT: HOST + '/api/v1/products/updatebaseinfo',
  GET_INFO_PROCESS: HOST + '/api/v1/businessprocess?bizProcessCode=',
  GET_HARVEST_NUM: HOST + '/api/v1/statistics/getHarvestInfo?',
  CHANGE_STATUS_EMPLOYEE: HOST + '/api/v1/employees/activation',
  LIST_OBJECT_PRODUCT: HOST + '/api/v1/productionobjects?zoneId=',
  UPDATE_PICTURE_OBJECT: HOST + '/api/v1/productionobjects/updateImage',
  UPDATE_PICTURE_OBJECT_PROCESSING: HOST + '/api/v1/processingobjects/updateImage',
  UPDATE_INFO_AREA: HOST + '/api/v1/productionareas',
  UPDATE_IMAGE_PRODUCT_ADD: HOST + '/api/v1/products/updateimage',
  UPDATE_IMAGE_PRODUCT_DELETE: HOST + '/api/v1/products/deleteimage',
  UPDATE_IMAGE_PRODUCT_ADD_CERTIFICATES: HOST + '/api/v1/products/updatecertificates',
  UPDATE_IMAGE_PRODUCT_DELETE_CERTIFICATES: HOST + '/api/v1/products/deletecertificates',
  UPDATE_INFO_EMPLOYEE: HOST + '/api/v1/employees/updatebaseinfo',
  GET_STAMP_WITH_DATE: HOST + '/api/v1/stamps/getWithDate',
  EDIT_PROCESS: HOST + '/api/v1/businessprocess',
  GET_OBJECT_IN_AREA: HOST + '/api/v1/productionobjects?zoneId=',
  GET_PRODUCT_DETAIL: HOST + '/api/v1/producttypes/detail?uuidOfProduct=',
  VERIFY_EMAIL: HOST + '/api/v1/users/verify?token=',
  GET_AREA_DETAIL: HOST + '/api/v1/productionareas/detail?uuidOfZone=',
  SEND_CODE_NUMBER: HOST + '/api/v1/users/phone/sendcode',
  VERIFY_NUMBER: HOST + '/api/v1/users/phone/verify',
  GET_LIST_PRODUCT_RETAILER: HOST + '/api/v1/retailers/producttypes',
  UPDATE_PRODUCT_RETAILER: HOST + '/api/v1/retailers/producttypes',
  GET_ALL_IMPORT_LIST: HOST + '/api/v1/cases/import?page=1&size=100',
  SEND_LINK_PASSWORD: HOST + '/api/v1/users/password/reset',
  UPDATE_PASSWORD: HOST + '/api/v1/users/password?token=',
  CHANGE_PASS_ADMIN: HOST + '/api/v1/admin/password',
  CHANGE_PASS_ADMIN_ACCOUNT: HOST + '/api/v1/superadmin/changePasswordAdmin?uuid=',
  GET_PLAN_REGISTER: HOST + "/api/v1/register/plan",
  ADD_PRODUCT_IMAGE360: HOST + "/api/v1/products/addImages360",
  ADD_PRODUCT_OBJECT_IMAGE360: HOST + "/api/v1/productionobjects/addImages360",
  ADD_PROCESSING_OBJECT_IMAGE360: HOST + "/api/v1/processingobjects/addImages360",
  DELETE_PRODUCT_IMAGE360: HOST + "/api/v1/products/deleteImages360",
  DELETE_PRODUCT_OBJECT_IMAGE360: HOST + "/api/v1/productionobjects/deleteImages360",
  DELETE_PROCESSING_OBJECT_IMAGE360: HOST + "/api/v1/processingobjects/deleteImages360",
  GET_BILL_LIST: HOST + "/api/v1/salesOrder/getList",
  GET_PRICE_LIST: HOST + "/api/v1/statistics/totalPriceOfRetailProductType",
  ORIGIN: HOST + "/api/v1/origins/",
  CHANGE_STATUS_ACCOUNT: HOST + "/api/v1/admin/users/",
  UPDATE_IMAGE_ORIGIN: HOST + "/api/v1/producttypes/updateOriginImage",
  RESEND_EMAIL: HOST + "/api/v1/users/verify/resend",
  SEND_SEED_MAIL: HOST + '/api/v1/users/emails',
  GET_USED_STAMP: HOST + "/api/v1/stamps/activation",
  UPDATE_IMG_AREA: HOST + "/api/v1/productionareas/updateImage",
  UPDATE_IMG_PRODUCT: HOST + "/api/v1/producttypes/updateImage",
  UPDATE_IMG_PRODUCT_CERT: HOST + "/api/v1/producttypes/certificate",
  UPDATE_CERT_MANU: HOST + "/api/v1/users/updateCert",
  UPDATE_LOGO_MANU: HOST + "/api/v1/users/updateAvatar",
  UPDATE_CERT_ORIGIN: HOST + '/api/v1/producttypes/updateOriginCert',
  UPDATE_BANNER_ORIGIN: HOST + '/api/v1/producttypes/updateOriginBanner',
  UPDATE_INFO_PARTNER: HOST + '/api/v1/partner/?uuid=',
  GET_VERSION: HOST + '/api/v1/users/versions',
  REGISTER_PACKAGE: HOST + '/api/v1/payments/plans/orders',
  GET_LIST_ORDER: HOST + '/api/v1/payments/plans/users/orders',
  GET_LIST_ORDER_ACCOUNT: HOST + '/api/v1/payments/plans/orders',
  GET_ORDER_DETAIL: HOST + '/api/v1/payments/plans/orders/',
  GET_PERMISSION: HOST + "/api/v1/subscription/limitation",
  CHANGE_STATUS_AREA: HOST + "/api/v1/productionareas/updatebaseinfo",
  ADD_BRANCH: HOST + '/api/v1/partner/branchs?uuid=',
  // GET_ALL_PRODUCT_TYPE: HOST + '/api/v1/ecommerce?language=vi',
  SEND_RAW_DIARY: HOST + "/api/v1/diaries",


  //api thong ke
  GET_SCAN_STAMP: HOST + "/api/v1/statistics/getScannedStamp?",
  GET_STAMP_LOCATION: HOST + "/api/v1/statistics/getScannedLocation?",
  GET_QUANTITY_HARVEST: HOST + "/api/v1/statistics/getHarvestInfo?",
  GET_NOTI_LIST: HOST + "/api/v1/notifications",
  PUSH_NOTI: HOST + "/api/v1/notifications",
  REVOKE_STAMP: HOST + '/api/v1/admin/stamps/revoke',
  CREATE_ADMIN: HOST + '/api/v1/superadmin/createAdmin',
  GET_ADMIN_LIST: HOST + '/api/v1/superadmin/getAdminList',
  GET_INFO_DIARY: HOST + "/api/v1/diaries/pretx",
  GET_ACTION_LIST_ADMIN: HOST + '/api/v1/companies/actions',

  //get account
  GET_DETAIL_ACCOUNT: HOST + '}/api/v1/admin/getUser?uuid=',

  //API FOR PORTAL
  PORTAL_SETTING: HOST + '/api/v1/portalsetting',
  ADS_SETTING:  HOST + '/api/v1/advertise/all',
  ADS_SETTING_BY_ID:  HOST + '/api/v1/advertise/detail',
  UPDATE_ADS_SETTING: HOST + '/api/v1/advertise/baseinfo',
  UPLOAD_ADS_IMAGES: HOST + '/api/v1/advertise/image',
  
  GET_ALL_DISTRIBUTOR: HOST + '/api/v1/companies/distributors',
  ADMIN_STATISTIC_ACTIVATED_STAMP: HOST + '/api/v1/admin/activatedStamp',
  OVERVIEW_COMPANY_ADMIN: HOST + '/api/v1/admin/companies?role=M',
  GET_ALL_MANU: HOST + '/api/v1/admin/companies?role=M',
  GET_SYSTEM_ACTION: HOST + '/api/v1/admin/actions',
  GET_ALL_ACCOUNT: HOST + '/api/v1/admin/companies',
  //get accounr
  GET_DETAIL_ACCOUNT: HOST + '}/api/v1/admin/getUser?uuid=',

  // sample stamp
  CREATE_SAMPLE_STAMP: HOST + '/api/v1/stampSample',
  GET_ALL_SAMPLE_STAMP: HOST + '/api/v1/stampSample/all',
  GET_DETAIL_STAMP: HOST + '/api/v1/stampSample/detail?id=',
  UPDATE_INFO_SAMPLE_STAMP: HOST + '/api/v1/stampSample/baseInfo?id=',
  UPDATE_LOGO_SAMPLE_STAMP: HOST + '/api/v1/stampSample/image?id=',
  GET_SAMPLE_GROUP_BY_USER: HOST + '/api/v1/stampSample/byuser',
  GET_SAMPLE_STAMP_FOR_USER: HOST + '/api/v1/stampSample/all/foruser',
  DELETE_SAMPLE_STAMP: HOST + '/api/v1/stampSample/delete',
  MAPPING_SAMPLE_STAMP_FOR_ENTERPRISE: HOST + '/api/v1/stampSample/mapping',

  GET_DETAIL_ACCOUNT: HOST + '/api/v1/admin/getUser?uuid=',
  EDIT_PROFILE_USER: HOST + '/api/v1/admin/editUser?uuid=',
  EDIT_ADMIN: HOST +'/api/v1/superadmin/editAdmin?uuid=',

  //Thống kê
  GET_ALL_ACTIVITY: HOST + '/api/v1/statistics/report/activity',
  GET_STAMP_WITH_CROP: HOST + '/api/v1/statistics/noStampByCrop',
  GET_ALL_SCAN_STAMP: HOST + '/api/v1/statistics/report/scannedStamp',
  GET_PERIOD_BY_CROP: HOST + '/api/v1/statistics/periodByCrop',
  STATISTIC_GENERAL: HOST + '/api/v1/statistics/general',
  GET_ALL_ACTIVATED_STAMP: HOST + '/api/v1/statistics/report/po/activatedStamp',
  GET_ALL_HARVEST: HOST + '/api/v1/statistics/report/po/harvest',
  GET_ALL_DIARY_STATISTIC: HOST + '/api/v1/statistics/report/po/activity',
  GET_ACTIVATED_STAMP: HOST + '/api/v1/statistics/report/activatedStamp',
  GENERAL_STATISTIC_OBJECT: HOST + '/api/v1/statistics/po/general',
  GET_HARVEST_CROP_STATISTIC: HOST + '/api/v1/statistics/harvestByCrop',
  GET_ACTIVITY_CROP_STATISTIC: HOST + '/api/v1/statistics/activityByCrop',
  GET_TOP_EMPLOYEE: HOST + '/api/v1/statistics/general/activityOfActor',
  GET_QUANTITY_PT: HOST + '/api/v1/statistics/general/quantityOfPT',
  GET_LASTEST_ACTIVITY: HOST + '/api/v1/statistics/genSeral/latestActivity',
  GET_TOP_LASTEST_ACTIVITY: HOST + '/api/v1/statistics/general/topLatestActivity',
  VALIDATION_EMAIL: HOST + '/api/v1/authen/validation',
  OBJECT_CROP_STATISTIC: HOST + '/api/v1/statistics/statisticByCrop',
  STATISTIC_BY_A_CROP: HOST + '/api/v1/statistics/statisticByOneCrop',
  GET_ALL_PRODUCT_TYPE: HOST + '/api/v1/ecommerce',

  // api statistic for admin
  ADMIN_STATISTIC_ACTIVITY: HOST + '/api/v1/admin/activity',
  ADMIN_STATISTIC_SCANED_STAMP: HOST + '/api/v1/admin/scannedStamp',
  GET_VALID_STAMPS_RANGE: HOST + "/api/v1/stamps/currentstamp",

  ACTIVATE_STAMP_GET_RAW: HOST + "/api/v1/stamps/activate/pretx",
  ACTIVATE_STAMP_SEND_RAW: HOST + "/api/v1/stamps/activate",
  
  CREATE_PRINT_STAMP: HOST + "/api/v1/printStamp",
  GET_PRINT_STAMP_LIST_ADMIN: HOST + "/api/v1/printStamp/admin",
  GET_ALL_OBJECT_PRODUCTION: HOST + "/api/v1/productionobjects",

  CREATE_DIARY_RECORD: HOST + "/api/v1/diaryLedger",
  GET_ALL_DIARY_RECORD: HOST + "/api/v1/diaryLedger/all",
  EXPORT_DATA_DIARY_RECORD: HOST + "/api/v1/diaryLedger/csv",
  GET_DIARY_RECORD_DETAIL: HOST + "/api/v1/diaryLedger/detail?uuid=",
  DELETE_DIARY_RECORD: HOST + "/api/v1/diaryLedger?id=",
  DELETE_DIARY_STEP: HOST + '/api/v1/diaries?diaryId=',
  HIDE_DIARY_STEP: HOST + '/api/v1/diaries/config',

  EXPORT_PRODUCTTYPE: HOST + "/api/v1/producttypes",
  DELETE_ACTIVITY: HOST + '/api/v1/admin/actions?activityId=',
  SHOW_PRODUCT_PORTAL: HOST + '/api/v1/ecommerce/config/gov',
  CREATE_GUARD: HOST + '/api/v1/admin/guard',
  GET_GUARD_LIST: HOST + '/api/v1/admin/guard',
  CREATE_GUARD_DIARY: HOST + '/api/v1/guard/import',
  CREATE_FARMER: HOST + '/api/v1/admin/farmer',
  GET_FARMER_LIST: HOST + '/api/v1/admin/farmer',
  GET_SHIP_LIST: HOST + "/api/v1/admin/wholesaler",
  PACKED_FARMER: HOST + '/api/v1/diaries/packed',
  SHIPPING_BOX_WHOLESALER: HOST + '/api/v1/diaries/ship',
  APPROVE_WHOLESALER: HOST + '/api/v1/users/enterprise/gantprivilege',
  APPROVE_FARMER: HOST + '/api/v1/users/wholesaler/gantprivilege',
  GET_BOX_LIST: HOST + '/api/v1/users/wholesaler/getgantedprivilegelist',
  GET_PRE_STEP: HOST + '/api/v1/diaries/getlatestactionofobject?',
  GET_HISTORY_IMPORT: HOST + '/api/v1/companies/nonReceivedBatch',
  GET_RECEIVED_BOX: HOST + '/api/v1/companies/receivedBatch',
  SEND_RAW_PACKED: HOST + '/api/v1/diaries/packed/sendtx',
  SEND_RAW_SHIPED: HOST + '/api/v1/diaries/ship/sendtx',
  SEND_RAW_IMPORTED: HOST + '/api/v1/guard/import/sendtx',
  GET_ALL_BOX_ID: HOST + '/api/v1/users/farmer/getgantedshipmentlist',
  GET_ALL_BOX_ID_FOR_WHOLESALER: HOST + '/api/v1/users/wholesaler/getgantedprivilegelist',
  GET_ALL_BOX_SYSTEM: HOST + '/api/v1/batch',
  GET_ALL_PRODUCT_ADMIN: HOST + '/api/v1/ecommerce/all',
  HIDE_COMPANY_PORTAL: HOST + '/api/v1/companies/config/gov',
  REPORT_IMPORT: HOST + '/api/v1/statistics/report/import',
  GET_CURRENT_STAMP_BY_OWNER: HOST + '/api/v1/stamps/currentstamp/user?ownedBy=',
  IMPORT_NO_DIARY: HOST + '/api/v1/guard/import/noDiary',
  SCAN_STAMP_ADMIN: HOST + '/api/v1/statistics/admin/scannedStamp',
  MANUFACTURER_REPORT_ADMIN: HOST + '/api/v1/statistics/admin/general',
  ACTIVATE_STAMP_REPORT_ADMIN: HOST + '/api/v1/statistics/admin/activatedStamp',
  ADMIN_UPDATE_AVATAR_MAU: HOST + '/api/v1/users/admin/updateAvatar?ownedBy=',
  ADMIN_UPDATE_CERT_MAU: HOST + '/api/v1/users/admin/updateCertificate?ownedBy='
}

export {
  LINK_API,
  HOST_PORTAL
}

export default {
  LINK_API,
  HOST_PORTAL

}
