const AGD = "https://res.cloudinary.com/agridential/image/upload/v1590468487/New_agridential/agd_bzmiec.png"

const AGD_VERTICAL = "https://res.cloudinary.com/agridential/image/upload/v1590468480/New_agridential/agdnew_c8rbis.png"

const VBC = "https://res.cloudinary.com/agridential/image/upload/v1590468575/New_agridential/vbcblack_zgwwig.png"

const IMG_LOADING = "https://res.cloudinary.com/agridential/image/upload/v1608020069/%C4%90N/new_loading_s55ott.gif"

const IMG_LOADING_LOADING = "https://res.cloudinary.com/agridential/image/upload/v1608020069/%C4%90N/new_loading_s55ott.gif"

const AGD_LOGO =  "https://res.cloudinary.com/agridential/image/upload/v1590569683/New_agridential/agd_black_text_wvvrqr.png"
export {
    AGD, 
    AGD_VERTICAL,
    VBC,
    IMG_LOADING,
    AGD_LOGO,
    IMG_LOADING_LOADING
}

export default {
    AGD, 
    AGD_VERTICAL,
    VBC,
    AGD_LOGO,
    IMG_LOADING_LOADING
}