import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { AGD_LOGO } from "constants/image";
import PenIcon from "mdi-react/LeadPencilIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import CloseIcon from "mdi-react/CloseIcon"
import moment from 'moment';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Button, CustomInput, Input, Card, Col, Container, Row, UncontrolledPopover, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, ListGroup, Nav, NavItem, NavLink as NavLinkStrap, ListGroupItem } from 'reactstrap';
import "../../css/reactTable.css";
import cookie from 'react-cookies';
import axios from 'axios';
import { LINK_API } from 'constants/API'

import ResetIcon from "mdi-react/RestoreIcon"
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FilterIcon from "mdi-react/FilterIcon"
import Loading from 'components/alert/loading'
import Error from 'components/alert/error'
import Success from 'components/alert/success'
import ConfirmAlert from "components/alert/confirmAlert.js";
import CheckAllIcon from "mdi-react/CheckAllIcon";
import { motion } from "framer-motion";
import Header from "components/Headers/Header.js";

function ProductAreaList(props) {
  const getProductAreaList = props.getArea;
  const getAreaListFilter = props.getAreaListFilter;
  const permission = props.permission.data;
  const [dataProductAreaList, setDataProductAreaList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [filterStatus, setFilter] = React.useState(false)
  const [alert, setAlert] = React.useState(null)
  const { t } = props;

  const history = props.history;
  const handleEdit = React.useCallback((uuid) => {
    cookie.save("uuidArea", uuid, { path: "/" });
    history.push("/user/areaEdit")
  }, [history])
  const handleAdd = (type) => {
    if (type === 0 || type === 2) {
      props.history.push('/user/createObjectProduction')
    }
    if (type === 1) {
      props.history.push('/user/createObjectProcessing')
    }
  }
  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      getAreaListFilter(1, values.name, values.value, 5)
    }, []),
  });
  const handleResetData = () => {
    setFilter(false);
    getProductAreaList(1, 5)
  }
  const handleClearFilter = () => {
    formik.resetForm();
  }

  const hideAlert = () => {
    setAlert(null)
  }

  const confirmActive = React.useCallback((uuid) => {
    setAlert(<Loading />)
    let body = { "uuid": uuid };
    axios.put(LINK_API.CHANGE_STATUS_AREA, body)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} onConfirm={hideAlert} hideCancel={true} confirmBtnText={t('close')} />)
          getProductAreaList(1, 5)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }, [t, getProductAreaList])

  const handleActive = React.useCallback((uuid, title) => {
    let ChildComponent = <div>{title}</div>
    setAlert(
      <ConfirmAlert
        onClose={hideAlert}
        onConfirm={() => confirmActive(uuid)}
        confirmBtnText={t('confirm')}
        child={ChildComponent}
      />
    )
  }, [confirmActive, t])

  const genTable = React.useCallback((productAreaList) => {
    let data = productAreaList.map((prop, index) => {
      return {
        id: prop.uuid,
        name:
          <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={5} style={{ textAlign: "start" }}>
                  {prop.image && prop.image.length > 0 ? (
                    <img alt="profileAGD" src={prop.image[0].secure_url} style={{ height: "37px", width: "37px" }} />
                  ) :
                    <img alt="profileAGD" src={AGD_LOGO} style={{ height: "37px", width: "37px" }} />}
                </Col >
                <Col xs={12} sm={12} md={7} style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
                  <Truncate lines={2} ellipsis={<span>...</span>}>
                    {prop.name}
                  </Truncate>
                </Col>
              </Row>
            </Container>
          </div>,
        time: moment(prop.createdAt).format("DD/MM/YYYY"),
        city: prop.address ?
          <>
            <div id={"address" + index} style={{ cursor: "pointer" }}>
              <Truncate lines={2} ellipsis={<span>...</span>}>
                {prop.address ? prop.address.city : ""}
              </Truncate>
            </div>
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"address" + index}>
              {prop.address ? prop.address.city : ""}
            </UncontrolledTooltip>
          </> : null,
        description:
          <div>
            <Truncate lines={2} ellipsis={<span>...</span>}>
              {prop.description}
            </Truncate>
          </div>,
        number: <div className="d-flex flex-row justify-content-center align-items-center">
          <p className="mr-2 mb-0">{prop.objectRegistered}</p>
          {prop.type === 0 || prop.type === 1 ?
            <><Button onClick={() => handleAdd(prop.type)} color="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"addObject" + index}>
              <span className="btn-wrapper--icon d-flex">
                <PlusCircle
                  size={18}
                  className="text-info font-size-sm"
                />
              </span>
            </Button>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"addObject" + index}>
                {t('area.addObject')}
              </UncontrolledTooltip></> :
            <>
              <Button color="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"addObject" + index}>
                <span className="btn-wrapper--icon d-flex">
                  <PlusCircle
                    size={18}
                    className="text-info font-size-sm"
                  />
                </span>
              </Button>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"addObject" + index}>
                {t('area.addObject')}
              </UncontrolledTooltip>
              <UncontrolledPopover placement="top" trigger="legacy" target={"addObject" + index} className="popover-custom-wrapper popover-custom-lg">
                <ListGroup flush className="text-left ">
                  <ListGroupItem className="rounded">
                    <Nav pills className="flex-column">
                      <NavItem>
                        <NavLinkStrap onClick={() => props.history.push('/user/createObjectProduction')}>
                          {t('area.o-production')}
                        </NavLinkStrap>
                      </NavItem>
                      <NavItem>
                        <NavLinkStrap onClick={() => props.history.push('/user/createObjectProcessing')}>
                          {t('area.o-processing')}
                        </NavLinkStrap>
                      </NavItem>
                    </Nav>
                  </ListGroupItem>
                </ListGroup>
              </UncontrolledPopover>
            </>
          }
        </div>,
        type: (prop.type === 0) ? <span id={"type" + index}>Vùng nuôi trồng</span> : (prop.type === 1) ? <span id={"type" + index}>Vùng chế biến</span> : (prop.type === 2) ? <span id={"type" + index}>Vùng nuôi trồng và chế biến</span> : (prop.type === 3) ? <span id={"type" + index}>Khác</span> : "",
        actions: <div>
          <Button disabled={permission.agdqr} onClick={() => handleEdit(prop.uuid)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"areaEdit" + index}>
            <span className="btn-wrapper--icon d-flex">
              <PenIcon
                size={18}
                className="text-success font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"areaEdit" + index}>
            {t('edit')}
          </UncontrolledTooltip>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"type" + index}>
            {(prop.type === 0) ? "Vùng nuôi trồng" : (prop.type === 1) ? "Vùng chế biến" : (prop.type === 2) ? "Vùng nuôi trồng và chế biến" : (prop.type === 3) ? "Khác" : ""}
          </UncontrolledTooltip>
          {
            prop.lock ?
              <>
                <Button disabled={permission.agdqr} onClick={() => handleActive(prop.uuid, t('area.active'))} onClcolor="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipDeactive" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <CheckAllIcon
                      size={18}
                      className="text-info font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipDeactive" + index}>
                  {t('area.active')}
                </UncontrolledTooltip>
              </>
              :
              <>
                <Button disabled={permission.agdqr} onClick={() => handleActive(prop.uuid, t('area.deActive'))} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipDeactive" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <CloseIcon
                      size={18}
                      className="text-danger font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipDeactive" + index}>
                  {t('area.deActive')}
                </UncontrolledTooltip>
              </>
          }
        </div>
      }
    })
    setDataProductAreaList(data)
    setLoading(false)
  }, [t, handleEdit])

  useEffect(() => {
    getProductAreaList(1, 5)
  }, [getProductAreaList]);

  const productAreaList = props.productAreaList.data
  const totalPageProps = props.productAreaList.totalPage
  useEffect(() => {
    if (productAreaList && productAreaList.length > 0) {
      genTable(productAreaList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }

  }, [totalPageProps, productAreaList, genTable]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            {t('area.idArea')}
          </div>
        )
      },
      {
        Header: t('area.name'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('area.createTime'),
        accessor: "time",
        filterable: false
      },
      {
        Header: t('area.type'),
        accessor: "type",
        filterable: false
      },
      {
        Header: t('area.address'),
        accessor: "city",
        filterable: false
      },
      {
        Header: t('area.totalobject'),
        accessor: "number",
        filterable: false
      },
      {
        Header: t('area.actionHeader'),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );



  return (
    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header />
        <Container className='mt--7' fluid>
          <Card className="card-box mb-5">
            <div className="card-header py-3 d-flex justify-content-between">
              <div className=" font-weight-bold card-header--title font-size-lg">{t('area.list')}</div>
              <div className="card-header--actions d-flex flex-row">
                <UncontrolledDropdown>
                  <DropdownToggle id="filterButton" color="neutral-success" className="mx-2">
                    <FilterIcon size={20} /> {t('filter.filter')}
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-xxl p-0">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="p-3">
                        <Row>
                          <Col md="6">
                            <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                              {t('filter.name')}
                            </small>
                            <CustomInput
                              type="select"
                              placeholder={t('filter.name')}
                              value={formik.values.name}
                              valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                              invalid={formik.touched.name && formik.errors.name ? true : false}
                              {...formik.getFieldProps('name')}
                            >
                              <option value="name">{t('area.name')}</option>
                              <option value="uuid">{t('area.idArea')}</option>
                              <option value="address">{t('area.address')}</option>
                            </CustomInput>
                          </Col>
                          <Col md="6">
                            <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                              {t('filter.value')}
                            </small>
                            {formik.values.name !== "status" ?
                              <Input
                                placeholder={t('filter.value')}
                                value={formik.values.value}
                                valid={formik.touched.value && formik.errors.value ? false : (formik.values.value ? true : false)}
                                invalid={formik.touched.value && formik.errors.value ? true : false}
                                {...formik.getFieldProps('value')}
                              >
                              </Input> : <CustomInput
                                type="select"
                                placeholder={t('filter.value')}
                                value={formik.values.value}
                                valid={formik.touched.value && formik.errors.value ? false : (formik.values.value || formik.values.value === 0 ? true : false)}
                                invalid={formik.touched.value && formik.errors.value ? true : false}
                                {...formik.getFieldProps('value')}
                              >
                                <option value={1}>{t('product.inProduction')}</option>
                                <option value={0}>{t('product.stopProduction')}</option>
                              </CustomInput>}
                          </Col>
                  `</Row>

                      </div>
                      <div className="divider" />
                      <div className="p-3 text-center bg-secondary">
                        <Button color="primary" type="submit">
                          {t('filter.search')}
                        </Button>
                      </div>
                    </form>
                    <div className="divider" />
                    <div className="p-3">
                      <Row>
                        <Col md="6">
                          <Nav className="nav-neutral-danger flex-column p-0">
                            <NavItem>
                              <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleClearFilter}>
                                <div className="nav-link-icon">
                                  <CloseIcon />
                                </div>
                                <span>{t('filter.clear')}</span>
                              </NavLinkStrap>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col md="6">
                          <Nav className="nav-neutral-info flex-column p-0">
                            <NavItem>
                              <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleResetData}>
                                <div className="nav-link-icon">
                                  <ResetIcon />
                                </div>
                                <span>{t('filter.reset')}</span>
                              </NavLinkStrap>
                            </NavItem>
                          </Nav>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"filterButton"}>
                  {t('filter.filter')}
                </UncontrolledTooltip>
                <div className="d-inline-block" id="notiPlan">
                  <Button onClick={() => props.history.push("/user/areaCreate")} color="info" disabled={permission.agdqr}>
                    <span className="btn-wrapper--icon">
                      <PlusCircle size={16} />
                    </span>
                    <span className="btn-wrapper--label">{t('product.add')}</span>
                  </Button>
                  {permission.agdqr ?
                    <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
                      {t('notiPlan')}
                    </UncontrolledTooltip> : null}
                </div>
              </div>
            </div>
            <div className="divider" />
            <div className="card-body pt-2 px-4 pb-4">
              <ReactTable
                noDataText={t('noData')}
                data={dataProductAreaList}
                columns={columns}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                manual
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                pages={totalPage}
                className="-striped -highlight"
                loading={loadingState}
                LoadingComponent={LoadingTable}
                onFetchData={async (state, instance) => {
                  setLoading(true)
                  let x = state.page + 1;

                  if (filterStatus === false) {
                    getProductAreaList(x, state.pageSize)
                  } else {
                    getAreaListFilter(x, formik.values.name, formik.values.value, state.pageSize)
                  }
                }}
              />
            </div>
            {alert}
          </Card>
        </Container>
      </motion.div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    productAreaList: state.productArea,
    permission: state.permission
  }
}

const mapDispatchToProps = (dispatch) => ({
  getArea: (page, size) => dispatch({ type: "GET_AREA_LIST", page: page, size: size }),
  getAreaListFilter: (page, name, value, size) => dispatch({ type: "GET_AREA_LIST_FILTER", page: page, name: name, value: value, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProductAreaList));