import {
  UPDATE_INFO_AREA,
  PUSH_NOTI_PRODUCT_SUCCESS,
  DEACTIVE_NOTI_PRODUCT,
  GET_AREA_LIST
} from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import responseCode from 'constants/errorCode.js'

const getToken = (state) => state.userInfo


function * watchUpdateInfoArea (data) {
  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(2000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  const token = yield select(getToken)
  const { response, error } = yield call(updateInfoArea, data.data, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin vùng trồng thành công", color: 'success' })
      yield put({ type: GET_AREA_LIST, page: 1, size: 5 })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      if (responseCode[`${result.name}`].vi) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'error' })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin vùng trồng thất bại", color: 'error' })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  } else {
    if (error.response.data.name) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin vùng trồng thất bại", color: 'error' })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
}
function updateInfoArea (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.put(LINK_API.CREATE_PRODUCTION_AREA, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

const handleUpdateEmployee = [
  takeLatest(UPDATE_INFO_AREA, watchUpdateInfoArea)
]
export default handleUpdateEmployee

