import axios from "axios";
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import DropZone from "components/dropZone";
import Header from "components/Headers/Header";
import { LINK_API } from "constants/API";
//formik
import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Card, Container, CustomInput, Input } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import Accounts from 'web3-eth-accounts';
import Contract from 'web3-eth-contract';
import utils from 'web3-utils';
import GenieToken from "../employObject/abi/GenieToken.js";
import Identity from "../employObject/abi/Identity.js";
import ConfirmAlert from "components/alert/confirmAlert"
import Select from "react-select"
import DateTimePicker from 'react-datetime-picker'

const account = new Accounts();

const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');
const ethers = require('ethers');

const CreateEmployee = (props) => {
  const t = props.t;
  const userInfo = props.userInfo;
  const [alert, setAlert] = React.useState(null)
  const [startID, setStart] = React.useState('')
  const [endID, setEnd] = React.useState('')
  const [harvestID, setHarvest] = React.useState('')
  const boxList = props.boxList
  const [listBox, setList] = React.useState([])
  const [boxSelected, setSelected] = React.useState('')
  const [listImage, setImage] = React.useState([])
  const [harvestTime, setHarvestTime] = React.useState(new Date());

  const handleAva = (data) => {
    setImage(data);
  }
  React.useEffect(() => {
    setList(boxList)
  }, [boxList])
  const formik = useFormik({
    initialValues: {
      id: '',
      startID: '',
      endID: ''
    },
    validationSchema: Yup.object({
      id: Yup.string()
        .required(t('require')),
    }),
    onSubmit: async (values) => {
      setAlert(<Loading />);
      await getStampID(values)

    },
  });
  const getStampID = async (value) => {
    let objectID = value.id.substring(7, value.id.length);
    let ownerID = value.id.substring(0, 7);
    let path = LINK_API.GET_PRE_STEP + 'uuidOfObject=' + objectID + '&ownedBy=' + ownerID;
    let response = await axios.get(path)
    if (response.data.startStampID) {
      await setStart(response.data.startStampID)
      formik.setFieldValue('startID', response.data.startStampID)
    }
    if (response.data.endStampID) {
      await setEnd(response.data.endStampID)
      formik.setFieldValue('endID', response.data.endStampID)

    }
    await handleGetInfoDiary(objectID, response.data.startStampID, response.data.endStampID, value)
  }
  const handleGetInfoDiary = (id, startStamp, endStamp, value) => {
    setAlert(<Loading />);
    let data = {
      "processCode": 'D1',
      "toId": id,
      "step": 0,
      "moreInfo": {},
      "detail": value.description,
      "images": [],
      "description": "Đây là quá trình vận chuyển hàng hóa",
      "images": listImage,
      "date": Date.parse(harvestTime)
    }
    axios.post(LINK_API.GET_INFO_DIARY, data)
      .then(async res => {
        if (res.data.errorCode === 1) {
          var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
          const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
          const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
          const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
          const tx = new EthereumTx(res.data.data.raw, { chain: 4 });
          tx.sign(Buffer_privatekey)
          const rawTx = '0x' + tx.serialize().toString('hex');
          let dataX = {
            ...res.data.data,
            raw: rawTx,
            "images": listImage,
          }
          handleSendRaw(dataX, startStamp, endStamp)
        } else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
      })
  }
  const handleSendRaw = (data, startStamp, endStamp) => {
    axios.post(LINK_API.SEND_RAW_DIARY, data)
      .then(async res => {
        if (res.data.errorCode === 1) {
          await setHarvest(res.data.data.harvestId)
          handleCreateLedger(formik.values.id, startStamp, endStamp, res.data.data.harvestId)
        } else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
      })
  }
  const handleCreateLedger = async (objectID, startStamp, endStamp, harvestStamp) => {
    let cropId = await getIdOwner(objectID);
    let idWholesaler = await getIdWholesaler(objectID);
    let dataSend = {
      cropId: cropId,
      description: "Nhận hàng",
      distributorId: idWholesaler,
      name: "Hồ sơ nhận hàng",
      process_code: "D1",
      productionObjectId: objectID,
      stampSampleId: "74c4466e-31b6-48b3-aae8-b410f6edf2cd",
    }
    axios.post(LINK_API.CREATE_DIARY_RECORD, dataSend)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<ConfirmAlert
            child='Bạn có muốn kích hoạt tem?'
            content=''
            confirmBtnText='Có'
            cancleBtnText="Không"
            onConfirm={() => handleActiveStamp(res.data.data.uuid, startStamp, endStamp, harvestStamp)}
            onClose={handleBack}
          />)
          // setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
        } else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
      })
  }
  const creatRawActiveStamp = async (
    code,
    from,
    to,
    uri = '',
    contractAddress,
    genieTokenAddress,
    nonce,
  ) => {
    try {
      const genieTokenABI = GenieToken.abi;
      const genieTokenDeployed = new Contract(
        genieTokenABI,
        genieTokenAddress,
      );
      const data = genieTokenDeployed.methods
        .Non_FungibleAsset_NewNon_FungibleProductBatchCreated(
          code,
          from,
          to,
          uri,
          contractAddress,
        )
        .encodeABI();

      const txParam = {
        from: props.userInfo.data.bcAddress,
        nonce,
        gasPrice: 0,
        gasLimit: 8000000,
        to: genieTokenAddress,
        data,
      };

      const tx = await new EthereumTx(txParam, { chain: 4 });
      var bytes = CryptoJS.AES.decrypt(props.userInfo.data.privateEncrypted.toString(), props.userInfo.password)
      const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
      const privateKey = decrypt_privatekey.substring(2, decrypt_privatekey.length)
      await tx.sign(Buffer.from(privateKey, 'hex'));
      const rawTx = await `0x${tx.serialize().toString('hex')}`;
      return rawTx;
    } catch (err) {
      setAlert(<Error onClose={() => setAlert(null)} title="Khoảng tem không hợp lệ" confirmBtnText={t('confirm')} />)
    }
  };

  const handleSendRawStamp = async (dataRaw, boxId) => {
    // const {
    //   productTypeId,
    //   contractAddress,
    //   genieToken,
    //   nonce,
    //   uri,
    // } = dataRaw;
    // const { startId, endId } = dataRaw.stampUnitNonGs1;
    // const raw = await creatRawActiveStamp(
    //   productTypeId,
    //   startId,
    //   endId,
    //   '',
    //   contractAddress,
    //   genieToken,
    //   nonce,
    // );
    // let data = {};
    //   data = {
    //     ...dataRaw,
    //     raw,
    //     sign: 'a',
    //   };
    // try {
    //   const response_send_raw = await axios({
    //     method: 'POST',
    //     url: LINK_API.ACTIVATE_STAMP_SEND_RAW,
    //     data: data
    //   })

    //   if (response_send_raw.data.errorCode === 1) {
    //     setAlert(<Success onClose={() => setAlert(null)} hideCancel={true} onConfirm={handleBack} confirmBtnText={t('close')} />)
    //   } else {
    //     setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
    //   }
    // }
    // catch (err) {
    //   setAlert(<Error onClose={() => setAlert(null)} title="Khoảng tem không hợp lệ" confirmBtnText={t('confirm')} />)
    // }
    var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
    const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
    const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
    const tx = new EthereumTx(dataRaw.raw, { chain: 4 });
    tx.sign(Buffer_privatekey)
    const rawTx = '0x' + tx.serialize().toString('hex');
    let data = {
      ...dataRaw,
      raw: rawTx,
    }
    axios.post(LINK_API.ACTIVATE_STAMP_SEND_RAW, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={() => setAlert(null)} hideCancel={true} onConfirm={handleBack} confirmBtnText={t('close')} />)
        } else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
      })
  }
  const handleActiveStamp = (idLedger, startStamp, endStamp, harvestStamp) => {
    setAlert(<Loading />)
    let dataStamp = {
      "startId": startStamp,
      "endId": endStamp,
      "idOfObject": harvestStamp,
      "date": new Date(),
      "moreInfo": {},
      "boxId": '',
      "diaryLedgerUuid": idLedger
    }
    axios.post(LINK_API.ACTIVATE_STAMP_GET_RAW, dataStamp)
      .then(res => {
        if (res.data.errorCode === 1) {
          handleSendRawStamp(res.data.data, '')
        } else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
      })
  }
  const handleBack = () => {
    hideAlert()
    props.history.push('/user/import')
  }
  const getIdOwner = async (id) => {

    let owner = id.substring(0, 7)
    let idObject = id.substring(7, id.length)
    let responseData = await axios.get(LINK_API.GET_CROP_LIST + idObject + '&ownedBy=' + owner);
    let dataRes = responseData.data.data;
    let uuid = dataRes[0].uuid;
    return uuid
  }
  const getIdWholesaler = async (id) => {

    let owner = id.substring(0, 7)
    let idObject = id.substring(7, id.length)
    let responseData = await axios.get(LINK_API.GET_A_PRODUCTION + idObject);
    let dataRes = responseData.data.data;
    let uuid = dataRes.listWholeSalers[0];
    return uuid
  }
  const reloadData = () => {
    props.history.push('/user/import')
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const dataBlockchain = async (
    identitierAddress,
    productName,
    action,
    fromId,
    toId,
    detail,
    date,
    genieToken,
    contractAddress, // dia chi loai san pham
    tokenId,
  ) => {

    try {
      const _organization = identitierAddress; // địa chỉ identifier address của tổ chức của nhân viên đó
      const _productType = productName || ''; //Tên sản phẩm
      const _cetegories = 1; // loại sản phẩm tuwj theem
      // const action = action;
      const fromID = fromId;
      const toID = toId;
      const description = detail; // không bắt buộc detail
      // const date = date;
      const hashedDataToSign = utils.soliditySha3(
        _organization,
        _productType,
        _cetegories,
        action,
        fromID,
        toID,
        description,
        date,
      );
      //await web3.eth.personal.unlockAccount("0xEd376914fFb2D7672c78080A3800cD74d1a7EDd4", "nam114111", 600);
      var bytes = CryptoJS.AES.decrypt(props.userInfo.data.privateEncrypted.toString(), props.userInfo.password)
      const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
      const privateKey = decrypt_privatekey.substring(2, decrypt_privatekey.length)
      var signature = await account.sign(
        hashedDataToSign,
        privateKey, // privateKey of Employee
      );
      //console.log('signature', signature.signature);

      // const recover = await web3.eth.accounts.recover(signature)
      // //console.log('recover', recover)
      // var claimIssuer = '0x103cd9d78b561a7ad62051cfe2c2a8ccdf06600f'; // Danh tinh Issuer
      // //console.log('claimIssuer', claimIssuer)
      const GenieTokenABI = GenieToken.abi;
      const GenieTokenDeployed = new Contract(
        GenieTokenABI,
        genieToken,
      );

      const diaryABI = await GenieTokenDeployed.methods
        .Non_FungibleAsset_ActionToDiary(
          tokenId,
          _organization,
          _productType,
          _cetegories,
          signature.signature,
          hashedDataToSign,
          action,
          fromID,
          toID,
          description,
          date,
          contractAddress,
        )
        .encodeABI();
      return diaryABI;
    } catch (err) {
      console.log('err_dataBlockchain', err);
    }
  };
  const Execute = async (
    tokenId,
    action,
    fromId,
    toId,
    detail,
    date,
    nonce,
    genieToken,
    contractAddress,
    accountAddress,
    productName,
    identitierAddress,
  ) => {
    const genieTABI = Identity.abi;
    const genieIdentityDeployed = new Contract(
      genieTABI,
      identitierAddress,
    );
    // const genieIdentityDeployed = {};
    const _data = await dataBlockchain(
      identitierAddress,
      productName,
      action,
      fromId,
      toId,
      detail,
      date,
      genieToken,
      contractAddress,
      tokenId,
    );
    const data = genieIdentityDeployed.methods
      .execute(genieToken, 0, _data)
      .encodeABI();
    const txParam = {
      from: accountAddress,
      nonce,
      gasPrice: 0,
      gasLimit: 8000000,
      to: identitierAddress,
      data,
    };
    try {
      const tx = await new EthereumTx(txParam, { chain: 4 });
      var bytes = CryptoJS.AES.decrypt(props.userInfo.data.privateEncrypted.toString(), props.userInfo.password)
      const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
      const privateKey = decrypt_privatekey.substring(2, decrypt_privatekey.length)
      await tx.sign(Buffer.from(privateKey, 'hex'));
      const rawTx = await `0x${tx.serialize().toString('hex')}`;
      return rawTx;
    } catch (err) {
      //Tao lai raw khi tao raw bi loi
      try {
        const tx = await new EthereumTx(txParam, { chain: 4 });
        const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
        const privateKey = decrypt_privatekey.substring(2, decrypt_privatekey.length)
        await tx.sign(Buffer.from(privateKey, 'hex'));
        const rawTx = await `0x${tx.serialize().toString('hex')}`;
        return rawTx;
      } catch { }
    }
  };

  React.useEffect(() => {
    axios.get(LINK_API.GET_HISTORY_IMPORT)
      .then(res => {
        const data = res.data.data.map(item => {
          return {
            value: item.ownedBy + item.productionObjectUuid,
            label: `${item.name} - ${item.ownedBy + item.productionObjectUuid}`
          }
        });

        //   setListBoxSelect(data)
      })
  }, [])


  return (
    <>
      <Header></Header>
      <Container className=' mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="wizard-steps horizontal">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-4">
                <h5 className="font-size-xl font-weight-bold">Nhận hàng</h5>
                <p className="text-black-50 mb-4">Thông tin nhận hàng hóa</p>
                <div className="form-row ">
                  <div className="form-group col-md-12 pr-3 mb-0">
                    <div className="form-group">
                      <label className="font-weight-bold">Mã lô hàng <span className="text-danger">(*)</span></label>
                      <Select
                        placeholder="Chọn mã lô hàng"
                        options={listBox}
                        value={boxSelected}
                        onChange={(selectedOption) => {
                          setSelected(selectedOption);
                          formik.setFieldValue('id', selectedOption.value)
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '0.29rem',
                          borderWidth: 0.5,
                          height: 30,
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(60,68,177,0.15)',
                            primary50: 'rgba(60,68,177,0.15)',
                            primary: '#50b77a'
                          }
                        })}
                      />
                      {formik.touched.id && formik.errors.id ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.id}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group col-md-12 pr-3 mb-0">
                    <div className="form-group">
                      <label className="font-weight-bold"> Thông tin lô hàng</label>
                      <Input
                        placeholder='Thông tin lô hàng'
                        type="text"
                        name="description"
                        value={formik.values.description}
                        valid={formik.touched.description && formik.errors.description ? false : (formik.values.description ? true : false)}
                        invalid={formik.touched.description && formik.errors.description ? true : false}
                        {...formik.getFieldProps('description')}

                      />
                      {formik.touched.description && formik.errors.description ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.description}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group col-md-12 pr-3 mb-0">
                    <div className="form-group">
                      <label className="font-weight-bold"> Thời gian</label>
                      <DateTimePicker
                        className='form-control'
                        onChange={setHarvestTime}
                        value={harvestTime}
                        format="dd/MM/y HH:mm"
                        disableClock={true}
                        disableCalendar={true}
                      />
                      {harvestTime ? null : (
                        <p className="font-weight-regular font-size-sm text-danger" >Thông tin bắt buộc</p>
                      )}
                    </div>
                  </div>

                  <div className="form-group col-md-12 pr-3">
                    <div className="form-group">
                      <label className="font-weight-bold">Hình ảnh</label>
                      <DropZone
                        max={2}
                        onConfirm={handleAva}
                        data={listImage}
                      />
                    </div>
                  </div>

                </div>
                <div className="form-row ">
                  <div className="form-group col-md-6">
                    <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                  </div>

                  <div className="form-group col-md-6 d-flex justify-content-end">
                    <Button
                      size="lg"
                      type="submit"
                      className="text-uppercase font-weight-bold font-size-sm"
                      color="info">
                      Nhận hàng
                    </Button>
                  </div>
                </div>

              </div>

            </form>
          </div>
        </Card>
      </Container>
      {alert}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    areaList: state.renderArea,
    objectList: state.objectList,
    boxList: state.historyImport.options
  }
}

const mapDispatchToProps = (dispatch) => ({
  createEmployee: (data, image) => dispatch({ type: "CREATE_EMPLOYEE", data: data, avatar: image }),
  renderArea: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
  getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateEmployee));