import axios from 'axios';
import ConfirmAlert from "components/alert/confirmAlert.js";
import ContentAlert from 'components/alert/contentAlert';
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import Header from "components/Headers/Header";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API';
import { Field, Form, Formik } from 'formik';
import CheckAllIcon from "mdi-react/EyeIcon";
import CloseIcon from "mdi-react/EyeOffIcon";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, Container, FormGroup, Input, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";

function EmployeeList(props) {
  const [cooperatives, setCooperatives] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [alert, setAlert] = React.useState(null)
  const { t } = props;
  // let linkUrl = "";
  // if (props.userInfo && props.userInfo.data && props.userInfo.data.role === "F") {
  //   linkUrl = "distributor/"
  // }


  const hideAlert = () => {
    setAlert(null)
  }

  const confirmActive = React.useCallback((uuid) => {
    axios.put(LINK_API.CHANGE_STATUS_ACCOUNT + uuid + "/active")
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
          getCooperatives(1, 5)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }, [t])

  const handleActive = React.useCallback((uuid, title) => {
    let ChildComponent = <div>{title}</div>
    setAlert(
      <ConfirmAlert
        onClose={hideAlert}
        onConfirm={() => confirmActive(uuid)}
        confirmBtnText={t('confirm')}
        child={ChildComponent}
      />
    )
  }, [confirmActive, t])

  const handleResetPassword = React.useCallback(async (values, uuid) => {
    setAlert(<Loading />)
    const data = {
      "password": values.newPass,
      "uuid": uuid
    }
    axios.post(LINK_API.CHANGE_PASS_ADMIN, data)
      .then((response) => {
        if (response.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
        }
        else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(() => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }, [t])

  const resetPassword = React.useCallback((uuid) => {
    const ChildComponent = (
      <div>
        <Formik
          validationSchema={
            Yup.object().shape({
              newPass: Yup.string()
                .min(6, t('tooShort'))
                .required(t('require'))
            })
          }
          initialValues={{ newPass: '', rePass: '' }}
          onSubmit={values => handleResetPassword(values, uuid)}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name="newPass"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('account.newPass')}
                    </label>
                    <Input {...props} type="password" {...props.field} placeholder={t('account.newPass')} />
                  </FormGroup>
                )}
              />
              {errors.newPass && touched.newPass ? <div>{errors.newPass}</div> : null}
              <Button color="success" type="submit">{t('confirm')}</Button>
            </Form>
          )}
        </Formik>
      </div>
    )
    setAlert(
      <ContentAlert
        title={t('employee.enterInfo')}
        hideCancel={true}
        onCancel={hideAlert}
        hideConfirm={true}
        child={ChildComponent}
      />

    )
  }, [t, handleResetPassword])

  const handleChangeStatus = (uuidOfProduct, ownedBy, hide) => {
    const data = {
      hideList: [
        {
          "ownedBy": ownedBy,
          "uuidOfProduct": uuidOfProduct,
          "hide": !hide
        }
      ]
    }

    axios.post(LINK_API.SHOW_PRODUCT_PORTAL, data)
      .then(async res => {
        getCooperatives()
        setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }
  const genTable = React.useCallback((listData) => {
    let data = listData.map((prop, index) => {
      let loai = ""
      if (prop.role === "M") {
        loai = "Nhà sản xuất";
      }
      else if (prop.role === "F") {
        loai = "Vựa sỉ";
      }
      else if (prop.role === "R") {
        loai = "Nhà bán lẻ";
      }
      else if (prop.role === "D") {
        loai = "Nhà vận chuyển";
      }

      return {
        id: index + 1,
        name: prop.nameOfProduct ? prop.nameOfProduct : '',
        manu: prop.nameOfEnterprise,
        address: prop.addressOfEnterprise && prop.addressOfEnterprise.city ? prop.addressOfEnterprise.city : prop.addressOfEnterprise,
        action: (
          <>
            {
              prop.hide ?
                <>
                  <Button onClick={() => handleChangeStatus(prop.uuidOfProduct, prop.ownedBy, prop.hide)} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"active" + index}>
                    <span className="btn-wrapper--icon d-flex">
                      <CheckAllIcon
                        size={18}
                        className="text-success font-size-sm"
                      />
                    </span>
                  </Button>
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"active" + index}>
                    Hiển thị trên trang truy xuất
              </UncontrolledTooltip>
                </>
                :
                null}
            {
              !prop.hide ?
                <>
                  <Button onClick={() => handleChangeStatus(prop.uuidOfProduct, prop.ownedBy, prop.hide)} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"productDeactive" + index}>
                    <span className="btn-wrapper--icon d-flex">
                      <CloseIcon
                        size={18}
                        className="text-danger font-size-sm"
                      />
                    </span>
                  </Button>
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"productDeactive" + index}>
                    Ẩn sản phẩm
              </UncontrolledTooltip>
                </>
                : null
            }


          </>
        )
      }
    })
    setCooperatives(data)
    setLoading(false)
  }, [t, resetPassword, handleActive])

  const getCooperatives = async () => {
    try {
      let response = await axios.get(LINK_API.GET_ALL_PRODUCT_ADMIN);
      if (response.data.errorCode === 1) {
        genTable(response.data.data);
      }
      else {
        genTable([]);
      }
    }
    catch (error) {
      setCooperatives([])
    }
  }


  useEffect(() => {
    getCooperatives()
  }, []);

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: t('product.name'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('product.manufact'),
        accessor: "manu",
        filterable: false
      },
      {
        Header: t('address'),
        accessor: "address",
        filterable: false
      },
      {
        Header: 'Hành động',
        accessor: "action",
        filterable: false
      },
    ],
    [t]
  );

  const handleEnterData = React.useCallback(() => {
    props.history.push("/adminonly/subscription/create")
  }, [])

  return (

    <>
      <Header></Header>
      <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="card-header py-3">
            <div className=" font-weight-bold card-header--title font-size-lg">{t('product.list')}</div>
            <div className="card-header--actions">
              {/* <Button onClick={() => handleEnterData()} color="success">
              <span className="btn-wrapper--icon">
                <PlusCircle size={16} />
              </span>
              <span className="btn-wrapper--label">{t('product.add')}</span>
            </Button> */}
            </div>
          </div>

          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">

            <ReactTable
              noDataText={t('noData')}
              data={cooperatives}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"

              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              className="-striped -highlight"
              loading={loadingState}
              LoadingComponent={LoadingTable}
            />
          </div>
        </Card>
      </Container>
      {alert}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    employeeList: state.employeeList
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEmployee: (page, size) => dispatch({ type: "GET_EMPLOYEE_LIST", page: page, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(EmployeeList));