// /*!

// =========================================================
// * Argon Dashboard React - v1.1.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
// /*eslint-disable*/
// // nodejs library to set properties for components
// import { PropTypes } from "prop-types";
// import React from "react";
// import { Link, NavLink as NavLinkRRD } from "react-router-dom";
// // reactstrap components
// import {
//   Col, Collapse,
//   Container, DropdownItem, DropdownMenu,
//   DropdownToggle,
//   Form,
//   Input,
//   InputGroup, InputGroupAddon,
//   InputGroupText,
//   Media,
//   Nav, Navbar, NavbarBrand,
//   NavItem,
//   NavLink,
//   Row, UncontrolledDropdown,
//   UncontrolledCollapse
// } from "reactstrap";
// import ChevronRight from "mdi-react/DotsHorizontalIcon"
// import axios from 'axios';
// import Error from 'components/alert/error';
// import Loading from 'components/alert/loading';
// import { LINK_API } from 'constants/API';
// var ps;
// class Sidebar extends React.Component {
//   state = {
//     collapseOpen: false
//   };
//   constructor(props) {
//     super(props);
//     this.activeRoute.bind(this);
//     this.handleSignOut.bind(this);
//     this.hanldeChangeRoute.bind(this);
//   }
//   // verifies if routeName is the one active (in browser input)
//   activeRoute(routeName) {
//     return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
//   }
//   // toggles collapse between opened and closed (true/false)
//   toggleCollapse = () => {
//     this.setState({
//       collapseOpen: !this.state.collapseOpen
//     });
//   };
//   // closes the collapse
//   closeCollapse = () => {
//     this.setState({
//       collapseOpen: false
//     });
//   };
//   // creates the links that appear in the left menu / Sidebar
//   createLinks = routes => {
//     return routes.map((prop, key) => {
//       return (
//         prop.hide ? null : prop.expand ? <>
//           <NavItem key={key}>
//             <NavLink
//               to={prop.layout + prop.path}
//               tag={NavLinkRRD}
//               onClick={this.closeCollapse}
//               activeClassName="active"
//               id={prop.id}
//               // className='d-flex justify-content-between'
//             >
//                 <i className={prop.icon} />
//                 <span className='font-weight-bold'>{prop.name}</span>
//               <span className="ml-auto">
//                 <ChevronRight />
//               </span>
//             </NavLink>
//             <UncontrolledCollapse toggler={"#" + prop.id}>
//               <ul>
//                 {prop.child.map((item, idx) => (
//                   <li className='pl-2' key={idx}>
//                     <NavLink
//                       onClick={this.closeCollapse}
//                       activeClassName="active"
//                       tag={NavLinkRRD}
//                       to={prop.layout + item.path}
//                       className='cursor-pointer'
//                     >
//                       {item.name}
//                     </NavLink>
//                   </li>
//                 ))}
//               </ul>
//             </UncontrolledCollapse>
//           </NavItem>
//         </>
//           :
//           <NavItem key={key}>
//             <NavLink
//               to={prop.layout + prop.path}
//               tag={NavLinkRRD}
//               onClick={this.closeCollapse}
//               activeClassName="active"
//             >
//               <i className={prop.icon} />
//               <span className='font-weight-bold'>{prop.name}</span>
//             </NavLink>
//           </NavItem>
//       );
//     });
//   };
//   handleSignOut = async () => {
//     this.hanldeChangeRoute();
//   }
//   hanldeChangeRoute = () => {
//     axios.defaults.headers.common['Authorization'] = userInfo.token;
//     axios.get(LINK_API.SIGN_OUT)
//       .then(res => {
//         if (res.data.errorCode === 1) {
//           props.cleanStore();
//           props.history.push('/auth/login')
//         } else {
//           // setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
//         }
//       })
//       .catch(err => {
//         setAlert(null)
//         props.history.push('/PageError505')
//       })
//   }

//   render() {
//     const { bgColor, routes, logo } = this.props;
//     let navbarBrandProps;
//     if (logo && logo.innerLink) {
//       navbarBrandProps = {
//         to: logo.innerLink,
//         tag: Link
//       };
//     } else if (logo && logo.outterLink) {
//       navbarBrandProps = {
//         href: logo.outterLink,
//         target: "_blank"
//       };
//     }
//     return (
//       <Navbar
//         className="navbar-vertical fixed-left navbar-light bg-white"
//         expand="md"
//         id="sidenav-main"
//       >
//         <Container fluid>
//           {/* Toggler */}
//           <button
//             className="navbar-toggler"
//             type="button"
//             onClick={this.toggleCollapse}
//           >
//             <span className="navbar-toggler-icon" />
//           </button>
//           {/* Brand */}
//           {logo ? (
//             <NavbarBrand className="pt-0" {...navbarBrandProps}>
//               <img
//                 alt={logo.imgAlt}
//                 className="navbar-brand-img"
//                 src={'https://res.cloudinary.com/agridential/image/upload/v1602647664/Logo/image_x4dg6f.png'}
//               />
//             </NavbarBrand>
//           ) : null}
//           {/* User */}
//           <Nav className="align-items-center d-md-none">

//             <UncontrolledDropdown nav>
//               <DropdownToggle nav>
//                 <Media className="align-items-center">
//                   <span className="avatar avatar-sm rounded-circle">
//                     <img
//                       alt="..."
//                       src={require("assets/img/theme/team-1-800x800.jpg")}
//                     />
//                   </span>
//                 </Media>
//               </DropdownToggle>
//               <DropdownMenu className="dropdown-menu-arrow" right>
//                 <DropdownItem className="noti-title" header tag="div">
//                   <h6 className="text-overflow m-0">Xin chào!</h6>
//                 </DropdownItem>
//                 <DropdownItem to="/admin/user-profile" tag={Link}>
//                   <i className="ni ni-single-02" />
//                   <span>Tài khoản</span>
//                 </DropdownItem>
//                 <DropdownItem divider />
//                 <DropdownItem href="#pablo" onClick={() => this.handleSignOut()}>
//                   <i className="ni ni-user-run" />
//                   <span>Đăng xuất</span>
//                 </DropdownItem>
//               </DropdownMenu>
//             </UncontrolledDropdown>
//           </Nav>
//           {/* Collapse */}
//           <Collapse navbar isOpen={this.state.collapseOpen}>
//             {/* Collapse header */}
//             <div className="navbar-collapse-header d-md-none">
//               <Row>
//                 {logo ? (
//                   <Col className="collapse-brand" xs="6">
//                     {logo.innerLink ? (
//                       <Link to={logo.innerLink}>
//                         <img alt={logo.imgAlt} src={'https://res.cloudinary.com/agridential/image/upload/v1602647664/Logo/image_x4dg6f.png'} />
//                       </Link>
//                     ) : (
//                         <a href={logo.outterLink}>
//                           <img alt={logo.imgAlt} src={'https://res.cloudinary.com/agridential/image/upload/v1602647664/Logo/image_x4dg6f.png'} />
//                         </a>
//                       )}
//                   </Col>
//                 ) : null}
//                 <Col className="collapse-close" xs="6">
//                   <button
//                     className="navbar-toggler"
//                     type="button"
//                     onClick={this.toggleCollapse}
//                   >
//                     <span />
//                     <span />
//                   </button>
//                 </Col>
//               </Row>
//             </div>
//             {/* Form */}
//             <Form className="mt-4 mb-3 d-md-none">
//               <InputGroup className="input-group-rounded input-group-merge">
//                 <Input
//                   aria-label="Search"
//                   className="form-control-rounded form-control-prepended"
//                   placeholder="Search"
//                   type="search"
//                 />
//                 <InputGroupAddon addonType="prepend">
//                   <InputGroupText>
//                     <span className="fa fa-search" />
//                   </InputGroupText>
//                 </InputGroupAddon>
//               </InputGroup>
//             </Form>
//             {/* Navigation */}
//             <Nav navbar>{this.createLinks(routes)}</Nav>

//           </Collapse>
//         </Container>
//       </Navbar>
//     );
//   }
// }

// Sidebar.defaultProps = {
//   routes: [{}]
// };

// Sidebar.propTypes = {
//   // links that will be displayed inside the component
//   routes: PropTypes.arrayOf(PropTypes.object),
//   logo: PropTypes.shape({
//     // innerLink is for links that will direct the user within the app
//     // it will be rendered as <Link to="...">...</Link> tag
//     innerLink: PropTypes.string,
//     // outterLink is for links that will direct the user outside the app
//     // it will be rendered as simple <a href="...">...</a> tag
//     outterLink: PropTypes.string,
//     // the image src of the logo
//     imgSrc: PropTypes.string.isRequired,
//     // the alt for the img
//     imgAlt: PropTypes.string.isRequired
//   })
// };

// export default Sidebar;


/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from 'axios';
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import { LINK_API } from 'constants/API';
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// reactstrap components
import {
  Container, DropdownItem, DropdownMenu,
  DropdownToggle,
  Media, Nav, Navbar, UncontrolledDropdown,
  Col, Row, Collapse, Form, Input, InputGroup, InputGroupAddon, InputGroupText,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledCollapse
} from "reactstrap";
import { NavLink as NavLinkRRD } from "react-router-dom";
import ChevronRight from "mdi-react/DotsHorizontalIcon"
// import './index.css';
toast.configure();
const AdminNavbar = (props) => {
  const userInfo = props.userInfo;
  // const data_user = props.userInfo.data;
  const cleanStore = props.cleanStore;
  const history = props.history;
  const getUserProfile = props.getUserProfile;
  const getPermission = props.getPermission;
  const [alert, setAlert] = React.useState(null)
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const t = props.t;
  const [info, setInfo] = React.useState({
    name: "",
    ava: "", role: ""
  })
  const userProfile = props.userProfile
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen)
  };

  const closeCollapse = () => {
    setCollapseOpen(false)
  };
  React.useEffect(() => {
    getUserProfile();
  }, [getUserProfile])
  React.useEffect(() => {
    getPermission();
  }, [getPermission])
  // React.useEffect(() => {
  //   if (permissionData) {
  //     if (permissionData.planName) {
  //       setPlan(permissionData.planName)
  //     }
  //   }
  // }, [permissionData])
  React.useEffect(() => {
    async function handleSetInfo(data, userProfile) {
      if (data.uuid !== data.ownedBy) {
        data.role = 'SM'
      }
      if (data) {
        setInfo({
          name: userProfile.name,
          ava: userProfile.avatar && userProfile.avatar[0] ? userProfile.avatar[0].secure_url : userProfile.avatar && userProfile.avatar.secure_url ? userProfile.avatar.secure_url : 'https://res.cloudinary.com/agridential/image/upload/v1603252735/Logo/logo_sg7qlp.png',
        })
      }
    }
    handleSetInfo(userInfo, userProfile)
  }, [userInfo, userProfile])
  React.useEffect(() => {
    if (!userInfo.token) {
      cleanStore();
      history.push("/auth/login")
    }
  }, [userInfo, cleanStore, history])
  const pushNoti = props.pushNoti
  React.useEffect(() => {
    if (pushNoti.tr) {
      if (pushNoti.color === "success") {
        toast.success(pushNoti.message, { transition: Zoom, position: "top-right" });
      } else {
        if (pushNoti.color === "danger") {
          toast.error(pushNoti.message, { transition: Zoom, position: "top-right" });
        }
        if (pushNoti.color === "warning") {
          toast.warning(pushNoti.message, { transition: Zoom, autoClose: 2000, position: "top-right" });
        }
      }
    }
  }, [pushNoti])
  const handleSignOut = async () => {
    await setAlert(<Loading />)
    hanldeChangeRoute();
  }
  const hanldeChangeRoute = () => {
    axios.defaults.headers.common['Authorization'] = userInfo.token;
    axios.get(LINK_API.SIGN_OUT)
      .then(res => {
        if (res.data.errorCode === 1) {
          props.cleanStore();
          props.history.push('/auth/login')
        } else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        }
      })
      .catch(err => {
        setAlert(null)
        props.history.push('/PageError505')
      })
  }

  const {
    logo,
    routes
  } = props;

  const createLinks = routes => {
    return routes.map((prop, key) => {
      return (
        prop.hide ? null : prop.expand ? <>
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
              id={prop.id}
            // className='d-flex justify-content-between'
            >
              <i className={prop.icon} />
              <span className='font-weight-bold'>{prop.name}</span>
              <span className="ml-auto">
                <ChevronRight />
              </span>
            </NavLink>
            <UncontrolledCollapse toggler={"#" + prop.id}>
              <ul>
                {prop.child.map((item, idx) => (
                  <li className='pl-2' key={idx}>
                    <NavLink
                      onClick={closeCollapse}
                      activeClassName="active"
                      tag={NavLinkRRD}
                      to={prop.layout + item.path}
                      className='cursor-pointer'
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </UncontrolledCollapse>
          </NavItem>
        </>
          :
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
            >
              <i className={prop.icon} />
              <span className='font-weight-bold'>{prop.name}</span>
            </NavLink>
          </NavItem>
      );
    });
  };

  return (
    <>
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0">
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={'https://res.cloudinary.com/agridential/image/upload/v1602647664/Logo/image_x4dg6f.png'}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">

            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={'https://res.cloudinary.com/agridential/image/upload/v1602647664/Logo/image_x4dg6f.png'}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Xin chào!</h6>
                </DropdownItem>
                <DropdownItem to={
                  props.userInfo.type === 'M' ? "/user/profile" :
                    props.userInfo.type === 'F' ? "/distributor/profile" :
                      props.userInfo.type === 'Z' ? "/admin/profile" :
                        props.userInfo.type === 'S' ? '/sadmin/profile' :
                          props.userInfo.type === 'U' ? '/market/profile' :
                            props.userInfo.type === 'G' ? '/guard/profile' :
                              props.userInfo.type === 'W' ? '/wholesaler/profile' :
                                props.userInfo.type === 'FA' ? '/farmer/profile' :
                                  '/auth/profile'} tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Tài khoản</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={() => handleSignOut()}>
                  <i className="ni ni-user-run" />
                  <span>Đăng xuất</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={'https://res.cloudinary.com/agridential/image/upload/v1602647664/Logo/image_x4dg6f.png'} />
                      </Link>
                    ) : (
                        <a href={logo.outterLink}>
                          <img alt={logo.imgAlt} src={'https://res.cloudinary.com/agridential/image/upload/v1602647664/Logo/image_x4dg6f.png'} />
                        </a>
                      )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            {/* Navigation */}
            <Nav navbar>{createLinks(routes)}</Nav>

          </Collapse>
        </Container>
      </Navbar>
      {alert}

    </>
  );
}

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  pushNoti: state.pushNoti,
  userInfo: state.userInfo.data,
  userProfile: state.userProfile.data,
});

const mapDispatchToProps = (dispatch) => ({
  saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
  cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
  getUserProfile: () => dispatch({ type: "GET_USER_PROFILE" }),
  getPermission: () => dispatch({ type: "GET_PERMISSION" })
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
