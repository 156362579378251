import React from "react"
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Input, Button } from "reactstrap"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import "view/css/select.css"
import DropZone from "components/dropZone/index"
import Success from "components/alert/success"
import Error from "components/alert/error"
import Loading from "components/alert/loading"
import { LINK_API } from "constants/API";
import Axios from "axios"
import Confirm from "components/alert/confirmAlert"
import cookie from "react-cookies"
const Info = React.forwardRef((props, ref) => {
  const uuidManu = cookie.load("uuidManu");
  Axios.defaults.headers.common['Authorization'] = props.userInfo.data.token;
  const t = props.t;
  const userProfile = props.userProfile;
  const [isChange, setChange] = React.useState(false)
  const [editorState, setEditor] = React.useState(EditorState.createEmpty());
  const [certList, setCert] = React.useState([])
  const [avaList, setAva] = React.useState([])
  const [alert, setAlert] = React.useState(null)
  const handleWarning = () => {
    setAlert(<Confirm
      child={"Lưu lại thông tin đã thay đổi ?"}
      cancleBtnText={t('no')}
      confirmBtnText={t("yes")}
      onConfirm={handleSaveData}
      onClose={hideAlert}
    />)
  }
  const handleChangeEditor = async (editorState) => {
    // console.log("editorState 1", editorState)
    setEditor(editorState)
    setChange(true)
  }
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      uuid: '',
    },
    validationSchema: Yup.object({
      //   nameArea: Yup.string()
      //     .required(t('require')),
      //   address: Yup.string()
      //     .required(t('require')),
      //   type: Yup.string()
      //     .required(t('require')),
    }),
    onSubmit: async (values) => {

    },
  });
  const hideAlert = () => {
    setChange(false)
    setAlert(null)
    if (props.onChange) {
      props.onChange(false);
    }
  }
  const handleConfirm = () => {
    hideAlert();
  }
  const handleReload = () => {
    props.getUserProfile(uuidManu);
  }
  const handleChangeField = name => async event => {
    await formik.setFieldValue(name, event.target.value, true);
    await formik.setFieldTouched(name, true, true);
    setChange(true)
    if (props.onChange) {
      props.onChange(true);
    }
  }
  const handleSaveData = async () => {
    setAlert(<Loading />)
    let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
    dynamicContent = await dynamicContent.toString()
    dynamicContent = await dynamicContent.replace("↵", '')
    let data = {
      name: formik.values.name,
      address: formik.values.address,
      standard: "GS1",
      website: userProfile.website,
      localtion_gps: "",
      description: "",
      dynamicDescription: dynamicContent,
    }
    Axios.put(LINK_API.EDIT_PROFILE_USER + uuidManu, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onConfirm={handleConfirm} hideCancel={true} confirmBtnText={t('confirm')} />)
          props.getUserProfile()
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }
  React.useImperativeHandle(ref, () => ({
    handleSaveInfo(tab) {
      setAlert(<Confirm
        child={"Lưu lại thông tin đã thay đổi ?"}
        cancelBtnText={t('no')}
        confirmBtnText={t("yes")}
        onConfirm={handleSaveData}
        onClose={() => handleChangeTab(tab)}
      />)
    }
  }));
  const handleChangeTab = (tab) => {
    hideAlert();
    if (props.changeTab) {
      props.changeTab(tab);
    }
  }
  React.useEffect(() => {
    formik.setValues({
      name: userProfile.name,
      uuid: userProfile.uuid
    })
    if (userProfile.certificates) {
      setCert(userProfile.certificates)
    }
    if (userProfile.avatar) {
      let temp = [];
      for (let i = 0; i < userProfile.avatar.length; i++) {
        temp.push(userProfile.avatar[i].secure_url)
      }
      setAva(temp)
    }
    if (userProfile.dynamicDescription) {
      let dynamicContent = userProfile.dynamicDescription;
      let contentBlock = htmlToDraft(dynamicContent);
      if (contentBlock && userProfile.dynamicDescription && userProfile.dynamicDescription !== "") {
        let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        let editorState = EditorState.createWithContent(contentState);
        setEditor(editorState)
      }
    }
  }, [userProfile])
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-4">
          <div className="form-row ">
            <div className="form-group col-md-8 mb-0">
              <h5 className="font-size-xl font-weight-bold">{t('profile.info')}</h5>
              <p className="text-black-50 mb-0">{t('profile.aboutInfo')}</p>
            </div>
            {isChange ? <div className="form-group col-md-4 text-right mb-0">
              <Button color="info" type="submit" onClick={handleWarning}>{t('save')}</Button>
            </div> : null}
          </div>
          <div className="divider my-4" />
          <div className="form-row ">
            <div className="form-group col-md-12">
              <label className="font-weight-bold">{t('profile.name')} <span className="text-danger">(*)</span></label>
              <Input
                placeholder={t('profile.name')}
                type="text"
                name="name"
                value={formik.values.name}
                valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                invalid={formik.touched.name && formik.errors.name ? true : false}
                onChange={handleChangeField("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
              ) : null}
            </div>
            <div className="form-group col-md-12">
              <label className="font-weight-bold">{t('profile.des')}</label>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor borderEditor"
                onEditorStateChange={handleChangeEditor}
                toolbar={{
                  options: ["inline", 'image', 'embedded', 'textAlign', "link",]
                }}
              />
              {formik.touched.des && formik.errors.des ? (
                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.des}</p>
              ) : null}
            </div>

          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="font-weight-bold">{t('profile.image')}</label>
              <DropZone data={avaList} max={3} api={true} path={LINK_API.ADMIN_UPDATE_AVATAR_MAU + uuidManu} reload={handleReload} />
            </div>
            <div className="form-group col-md-6">
              <label className="font-weight-bold">{t('profile.cert')}</label>
              <DropZone max={3} data={certList} api={true} path={LINK_API.ADMIN_UPDATE_CERT_MAU + uuidManu} reload={handleReload} />
            </div>
          </div>
        </div>
      </form>
      {alert}
    </>
  )
})
const mapStateToProps = (state) => {
  return {
    userProfile: state.detailAccount.data,
    userInfo: state.userInfo
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: (uuid) => dispatch({ type: "GET_DETAIL_ACCOUNT", uuid: uuid }),
    updateBanner: (data) => dispatch({ type: "UPDATE_BANNER", data: data }),
    updateLogo: (data) => dispatch({ type: "UPDATE_LOGO", data: data }),
    cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
    getSetting: () => dispatch({ type: "GET_SETTING" }),

  }
}
export default withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Info));
