import axios from "axios";
import CloseBoxIcon from "mdi-react/CloseBoxIcon";
import CloseIcon from "mdi-react/CloseCircleIcon";
import React from "react";
import { useDropzone } from 'react-dropzone';
import { Check, UploadCloud, X } from 'react-feather';
import { withTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from "reactstrap";
import ConfirmAlert from "components/alert/confirmAlert"
import Loading from "components/alert/loading"
import "./css/dropZone.css";
import Error from "components/alert/error"

const DropZone = (props) => {
    const maxImage = props.max;
    const indexOfStep = props.index;
    const editState = props.editState ? props.editState : false;
    const sizeOut = props.sizeOut ? props.sizeOut : "90";
    const sizeIn = props.sizeIn ? props.sizeIn : "60"
    const sizeIcon = props.sizeIcon ? props.sizeIcon : "30"
    const mTop = props.mTop ? props.mTop : "-28px"
    const mLeft = props.mLeft ? props.mLeft : "53px"
    const disabled = props.disabled ? props.disabled : false;
    const apiStatus = props.api ? props.api : false;
    const t = props.t;
    const [list, setList] = React.useState([]);
    const [alert, setAlert] = React.useState(null)
    const propsData = props.data;
    
    const {
        isDragActive,
        isDragAccept,
        isDragReject, getRootProps, getInputProps } = useDropzone({
            accept: 'image/jpeg, image/png, image/jpg',
            multiple: true,
            onDrop: async acceptedFiles => {
                let temp = await acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
                if (temp[0].size > 2097152) {
                    handleWarning();
                } else {
                    creatFile(temp[0].preview)
                }
            }
        });       
    const handleWarning = () => {
        setAlert(<ConfirmAlert
            child='Ảnh có kích thước lớn!'
            content='Vui lòng tải lên ảnh có kích thước nhỏ hơn 2MB'
            onConfirm={hideAlert}
            hideCancel={true}
        />)
    }
    const handleAddApi = (data) => {
        console.log("Data", data)
        setAlert(<Loading />)
        let temp = [];
        temp.push(data);
        let data_send = {
            "uuid": props.uuid,
            "addImageList": temp,
            "delImageList": []
        }
        axios.put(props.path, data_send)
            .then(async res => {
                if (res.data.errorCode === 1) {
                    let imgTemp = await list.filter((item, idxx) => idxx !== list.length - 1);
                    await imgTemp.push(res.data.data.addImageList[0])
                    setList(imgTemp)
                    setAlert(null)
                    if (props.reload) {
                        props.reload();
                    }
                }
            })
            .catch(err => {
                console.log(err)
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
            })
    }
    const hideAlert = () => {
        setAlert(null)
    }
    const handleDeleteApi = (data, index) => {
        let temp = list.filter((item, idx) => idx !== index);
        console.log(temp)
        setList(temp)
        if (props.onConfirm) {
            props.onConfirm(temp)
        }
        hideAlert();
        let strings = "https://res.cloudinary.com/"
        let check = data.includes(strings);
        if (check && apiStatus) {
            setAlert(<Loading />)
            let temp = [];
            temp.push(data);
            let data_send = {
                "uuid": props.uuid,
                "addImageList": [],
                "delImageList": temp
            }
            axios.put(props.path, data_send)
                .then(res => {
                    if (res.data.errorCode === 1) {
                        setAlert(null)
                        if (props.reload) {
                            props.reload();
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
                })
        }
    }

    const creatFile = (link) => {
        console.log('link DropZone', link)
        var file_result = "";
        var xhr = new XMLHttpRequest();
        xhr.open('GET', link, true);
        xhr.responseType = 'blob';
        xhr.onload = (e) => {
            e.preventDefault()
            var reader = new FileReader();
            var recoveredBlob = xhr.response;
            reader.onloadend = () => {
                handleData(reader)
            };

            reader.readAsDataURL(recoveredBlob);
            file_result = (reader)
        };
        xhr.send();
        return file_result;
    }
    const handleData = async (data) => {
        let temp = list;
        if (apiStatus) {
            handleAddApi(data.result)
        }
        await temp.push(data.result);
        await setList([...temp])
        if (props.onConfirm) {
            props.onConfirm(temp)
        }
    }
    const handleDeleteImage = async (index) => {
        console.log("index", index)
        for (let i = 0; i < list.length; i++) {
            if (index === i) {
                setAlert(<ConfirmAlert child="Bạn muốn xỏa ảnh vừa chọn ?" onClose={hideAlert} onConfirm={() => handleDeleteApi(list[i], index)} />)
            }
        }

        // if (props.onConfirm) {
        //     props.onConfirm(temp)
        // }
    }


    React.useEffect(() => {
        if (propsData && propsData.length > 0) {
            setList([...propsData])
        } else setList([])
    }, [propsData])
    return (
        <>
            <div className="d-flex flex-wrap mt-1">
                {list.map((img, index) => (
                    <div key={index} className={index === 0 || index === maxImage - 1 ? "p-2 mr-2 bg-white shadow-xxl border-dark card-box d-flex unSetOverflow rounded-sm d-" + sizeOut : "p-2 mx-3 bg-white shadow-xxl border-dark card-box d-flex unSetOverflow rounded-sm d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                        <div className="img-fluid img-fit-container rounded-sm containImage" style={{ backgroundImage: `url(${img})` }} >
                            <div style={{ marginTop: mTop, marginLeft: mLeft }}>
                                <Button onClick={() => handleDeleteImage(index)} color="neutral-danger" className="btn-icon btn-animated-icon btn-transition-none  d-40 p-0 m-2" id={"deleteImageAva" + index}>
                                    <span className="btn-wrapper--icon d-flex">
                                        <CloseIcon className="font-size-xs text-danger" />
                                    </span>
                                </Button>
                                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"deleteImageAva" + index}>
                                    {t('deleteImage')}
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </div>
                ))}
                {list.length < maxImage ?
                    <div style={{ height: sizeOut, width: sizeOut, paddingLeft: list.length > 0 ? "20px" : "0px" }}>
                        {!disabled ?
                            <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                                <input {...getInputProps()} />
                                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt borderBlack">
                                    {isDragAccept && (
                                        <div>
                                            <div className="d-90 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                                                <Check className="d-50" />
                                            </div>
                                        </div>
                                    )}
                                    {isDragReject && (
                                        <div>
                                            <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                                                <X className="d-90" />
                                            </div>
                                        </div>
                                    )}
                                    {!isDragActive && (
                                        <div className={"d-flex justify-content-center align-items-center d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                                            <div className={"btn-icon hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first d-" + sizeIn} style={{ height: sizeIn, width: sizeIn }}>
                                                <UploadCloud className={"d-" + sizeIcon} style={{ height: sizeIcon, width: sizeIcon }} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div> :
                            <div className='dropzone-upload-wrapper'>
                                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt borderBlack notAllowedActions">
                                    <div className={"d-flex justify-content-center align-items-center d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                                        <div id="disabledIcon" className={"d-flex justify-content-center align-items-center hover-scale-rounded shadow-light-sm rounded-lg text-first notUpload d-" + sizeIn} style={{ height: sizeIn, width: sizeIn }}>
                                            <CloseBoxIcon className={"text-danger d-" + sizeIcon} style={{ height: sizeIcon, width: sizeIcon }} />
                                            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"disabledIcon"}>
                                                {t('notAllowed')}
                                            </UncontrolledTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    : null}
            </div>
            {alert}
        </>
    )
}
export default withTranslation('common')(DropZone);