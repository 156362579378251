import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Input, Card, Container } from "reactstrap";
//style
import "view/css/select.css";
import { LINK_API } from "constants/API";
import axios from "axios"
import Select from 'react-select';
import moment from "moment"
import Header from "components/Headers/Header"
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import cookie from "react-cookies";

const CreateRecord = (props) => {
  const t = props.t;
  const userInfo = props.userInfo;
  const [alert, setAlert] = React.useState(null);
  const [listObject, setObjectList] = React.useState([]);
  const [selectedObject, setSelectedObject] = React.useState([]);
  const [listCrop, setListCrop] = React.useState([]);
  const [selectedCrop, setSelectedCrop] = React.useState([]);
  const [listStamp, setListStamp] = React.useState([]);
  const [selectedStamp, setSelectedStamp] = React.useState([]);
  const [listDistributor, setListDistributor] = React.useState([]);
  const [selectedDistributor, setSelectedDistributor] = React.useState([]);
  const [recordName, setRecordName] = React.useState("");
  const [description, setDescription] = React.useState("");

  React.useEffect(() => {
    const isDuplicate = cookie.load("isDuplicate");
    if (isDuplicate) {
      axios.defaults.headers.common['Authorization'] = userInfo.data.token;
      axios.get(LINK_API.GET_DIARY_RECORD_DETAIL + isDuplicate)
        .then(res => {
          const data = res.data.data;
          setRecordName(data.name)
          setDescription(data.description)
          setSelectedCrop({
            value: data.CropInfo.uuid,
            label: `${data.CropInfo.ProductTypeInfo.name} - ${moment(data.CropInfo.createdAt).format("DD/MM/YYYY")}`,
            data: {
              nameOfProcess: data.BusinessProcessInfo ? data.BusinessProcessInfo.process_name : "Quy trình nhận hàng",
              processCode: data.BusinessProcessInfo ? data.BusinessProcessInfo.process_code : "D1"
            }
          })
          setSelectedStamp({
            value: data.StampSampleInfo.uuid,
            label: data.StampSampleInfo.name
          })
          if(data.DistributorInfo){
            setSelectedDistributor({
              value: data.DistributorInfo.uuid,
              label: data.DistributorInfo.name
            })
          }

          setSelectedObject({
            value: data.ProductionObjectInfo.uuid,
            label: data.ProductionObjectInfo.name
          })
        })
        .catch(err => {
        })

    }

  }, [])

  React.useEffect(() => {
    axios.defaults.headers.common['Authorization'] = userInfo.data.token;
    axios.get(LINK_API.GET_ALL_OBJECT_PRODUCTION)
      .then(res => {
        const data = res.data.data.map(item => {
          return {
            value: item.uuid,
            label: item.name
          }
        })
        setObjectList(data)
      })
      .catch(err => {
        setObjectList([])
      })

    axios.get(LINK_API.GET_SAMPLE_STAMP_FOR_USER)
      .then(res => {
        const data = res.data.data.map(item => {
          return {
            value: item.uuid,
            label: item.name
          }
        })
        setListStamp(data)
      })
      .catch(err => {
        setListStamp([])
      })

    axios.get(LINK_API.GET_DISTRIBUTOR_LIST)
      .then(res => {
        const data = res.data.data.map(item => {
          return {
            value: item.uuid,
            label: item.name
          }
        })
        setListDistributor(data)
      })
      .catch(err => {
        setListDistributor([])
      })
  }, [])

  const handleSelectedObject = (selectedOption) => {
    setSelectedObject(selectedOption)
    setSelectedCrop([])
    const objectId = selectedOption.value;
    axios.defaults.headers.common['Authorization'] = userInfo.data.token;
    axios.get(LINK_API.GET_CROP_LIST + objectId)
      .then(res => {
        const data = res.data.data.map(item => {
          return {
            value: item.uuid,
            label: `${item.nameOfProductType} - ${moment(item.createdAt).format("DD/MM/YYYY")}`,
            data: item
          }
        })
        setListCrop(data)
      })
      .catch(err => {
        setListCrop([])
      })
  }

  const handleSubmit = () => {
    setAlert(<Loading />)
    const body = {
      "name": recordName,
      "description": description,
      "cropId": selectedCrop.value,
      "distributorId": selectedDistributor.value,
      "stampSampleId": selectedStamp.value,
      "process_code": selectedCrop.data.processCode,
      "productionObjectId": selectedObject.value
    }

    axios.defaults.headers.common['Authorization'] = userInfo.data.token;
    axios.post(LINK_API.CREATE_DIARY_RECORD, body)
      .then(res => {
        setAlert(<Success onClose={() => setAlert(null)} hideCancel={true} onConfirm={() => {
          setAlert(null)
          props.history.push("/user/record")
        }} confirmBtnText={t('close')} />)
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
      })
  }

  const handleReset = () => {
    setRecordName("")
    setDescription("")
    setSelectedCrop([])
    setSelectedObject([])
    setSelectedStamp([])
    setSelectedDistributor([])
  }

  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Card className="p-5">
        <div className="card-header py-3 px-0 d-flex justify-content-between">
        <div className="font-weight-bold mb-3" style={{ fontSize: 20 }}>Thêm thông tin hồ sơ truy xuất</div>
        <Button
                      onClick={() => handleReset()}
                      size="lg"
                      className="text-uppercase font-weight-bold font-size-sm"
                      color="neutral-info">
                      Làm lại
                  </Button>
          </div>
          
         
          <div className="d-flex">
            <form className="w-100">
              <div className="p-0">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">
                      Tên hồ sơ
                    <span className="text-danger ml-1">(*)
                    </span>
                    </label>
                    <Input
                      value={recordName}
                      onChange={(e) => setRecordName(e.target.value)}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">
                      Mô tả
                    </label>
                    <Input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">
                      Lô hàng
                  <span className="text-danger ml-1">(*)
                  </span>
                    </label>
                    <Select
                      placeholder="Chọn lô hàng"
                      options={listObject}
                      value={selectedObject}
                      onChange={(selectedOption) => handleSelectedObject(selectedOption)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 0.5,
                        height: 30,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#50b77a'
                        }
                      })}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">
                      Sổ nhật ký
                    <span className="text-danger ml-1">(*)
                    </span>
                    </label>
                    <Select
                      isDisabled={!selectedObject.value}
                      placeholder="Chọn sổ nhật ký"
                      options={listCrop}
                      value={selectedCrop}
                      onChange={(selectedOption) => {
                        setSelectedCrop(selectedOption)
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 0.5,
                        height: 30,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#50b77a'
                        }
                      })}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">
                      Nhà sản xuất
                    <span className="text-danger ml-1">(*)
                    </span>
                    </label>
                    <Input
                      disabled={true}
                      value={userInfo.data.name}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">
                      Quy trình
                    <span className="text-danger ml-1">(*)
                    </span>
                    </label>
                    <Input
                      disabled={true}
                      value={selectedCrop && selectedCrop.data && selectedCrop.data.nameOfProcess ? selectedCrop.data.nameOfProcess : ""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">
                      Tem mẫu
                    </label>
                    <Select
                      placeholder="Chọn tem mẫu"
                      options={listStamp}
                      value={selectedStamp}
                      onChange={(selectedOption) => {
                        setSelectedStamp(selectedOption)
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 0.5,
                        height: 30,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#50b77a'
                        }
                      })}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold">
                      Vựa sỉ
                    </label>
                    <Select
                      placeholder="Chọn vựa sỉ"
                      options={listDistributor}
                      value={selectedDistributor}
                      onChange={(selectedOption) => {
                        setSelectedDistributor(selectedOption)
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 0.5,
                        height: 30,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#50b77a'
                        }
                      })}
                    />
                  </div>
                </div>
                <div className="form-row ">
                  <div className="form-group col-md-12 mb-0">
                    <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                  </div>
                </div>
                <div className="form-row ">
                  <div className="form-group col-md-12 mb-0">
                    <Button
                      disabled={!recordName || !selectedCrop.value}
                      onClick={() => handleSubmit()}
                      size="lg"
                      className="text-uppercase font-weight-bold font-size-sm"
                      color="info">
                      Tạo mới
                  </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {alert}
        </Card>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    permission: state.permission,
  }
}

const mapDispatchToProps = (dispatch) => ({
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateRecord));