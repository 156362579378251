import DropZone from "components/dropZone";
//formik
import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Input, Card , CustomInput} from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import { Container } from "reactstrap"
import Header from "components/Headers/Header"
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import axios from "axios"
import {LINK_API} from "constants/API"
const ethers = require('ethers');

const CreateEmployee = (props) => {
    const t = props.t;
    const passwordRandom = Math.floor(100000 + Math.random() * 900000);
    const [alert, setAlert] = React.useState(null)
    const formik = useFormik({
        initialValues: {
            name: '',
            username: '',
            password: passwordRandom,
            role: ''
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t('require')),
            username: Yup.string()
                .required(t('require')),
            password: Yup.string()
                .required(t('require')),
                role: Yup.string()
                .required(t('require')),
        }),
        onSubmit: async (values) => {
            setAlert(<Loading/>);
            let temp = new Buffer(values.password).toString('hex')
            let encryptedPass = await ethers.utils.keccak256('0x' + temp)
            let data_signIn = {
              "email": values.username + '@vietnamblockchain.asia',
              "pw_hash": encryptedPass,
              "username": values.username,
              "role": values.role,
              "fullname": values.name
            }
            axios.post(LINK_API.CREATE_ADMIN, data_signIn)
              .then(res => {
                if (res.data.errorCode === 1) {
                  setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)
        
                } else {
                  setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
                }
              })
              .catch(err => {
                setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
              })

        },
    });
    const reloadData = () => {
        props.history.push('/sadmin/adminList')
    }
    const hideAlert = () => {
        setAlert(null)
    }
    const handleVietName = (str) => {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        return str;
    }

    const handleChange = async (event) => {
        if (event.target) {
            const value = event.target.value;
            let newStr = handleVietName(value);
            let temp = newStr.split(" ");
            let no_space = "";
            for (let i = 1; i < temp.length; i++) {
                no_space = no_space + temp[i]
            }
            let low_case = no_space.toLowerCase();
            let low_case1 = value[0] ? handleVietName(value[0].toLowerCase()) : "";
            let username = low_case1 + low_case;
            formik.setValues({ username: username, name: value, password: passwordRandom, role: formik.values.role })
            if (!username) {
                formik.setErrors({ username: false })
            }
        }
    }


    return (
        <>
            <Header></Header>
            <Container className=' mt--7' fluid>
                <Card className="card-box mb-5">
                    <div className="wizard-steps horizontal">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="p-4">
                                <h5 className="font-size-xl font-weight-bold">Thông tin admin</h5>
                                <p className="text-black-50 mb-4">Thông tin tài khoản admin</p>
                                <div className="form-row ">
                                    <div className="form-group col-md-12 pr-3">
                                        <div className="form-group">
                                            <label className="font-weight-bold">Họ và tên <span className="text-danger">(*)</span></label>
                                            <Input
                                                placeholder='Họ và tên'
                                                type="text"
                                                name="name"
                                                value={formik.values.name}
                                                valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                                invalid={formik.touched.name && formik.errors.name ? true : false}
                                                {...formik.getFieldProps('name')}
                                                onChange={(event) => handleChange(event)}
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-bold">Vai trò <span className="text-danger">(*)</span></label>
                                            <CustomInput 
                                            type="select" id="selectUnit" 
                                                value={formik.values.role}
                                                name="role"
                                                valid={formik.touched.role && formik.errors.role ? false : (formik.values.role ? true : false)}
                                                invalid={formik.touched.role && formik.errors.role ? true : false}
                                                {...formik.getFieldProps('role')}
                                            >
                                                <option value={''} disabled>Chọn vai trò</option>
                                                <option value={'Z'}>QTV chợ đầu mối Hòa Cường</option>
                                                <option value={'U'}>QTV cổng truy xuất nguồn gốc</option>
                                            </CustomInput>
                                            {formik.touched.role && formik.errors.role ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.role}</p>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-bold">{t('employee.username')} <span className="text-danger">(*)</span></label>
                                            <Input
                                                placeholder={t('employee.username')}
                                                type="text"
                                                name="username"
                                                value={formik.values.username}
                                                valid={formik.touched.username && formik.errors.username ? false : (formik.values.username ? true : false)}
                                                invalid={formik.touched.username && formik.errors.username ? true : false}
                                                {...formik.getFieldProps('username')}

                                            />
                                            {formik.touched.username && formik.errors.username ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.username}</p>
                                            ) : null}
                                        </div>

                                        <div className="form-group" >
                                            <label className="font-weight-bold">Mật khẩu <span className="text-danger">(*)</span></label>
                                            <Input
                                                placeholder={t('employee.passUser')}
                                                type="text"
                                                name="password"
                                                value={formik.values.password}
                                                valid={formik.touched.password && formik.errors.password ? false : (formik.values.password ? true : false)}
                                                invalid={formik.touched.password && formik.errors.password ? true : false}
                                                {...formik.getFieldProps('password')}
                                            />
                                            {formik.touched.password && formik.errors.password ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.password}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row ">
                                    <div className="form-group col-md-6">
                                        <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                                    </div>

                                    <div className="form-group col-md-6 d-flex justify-content-end">
                                        <Button
                                            size="lg"
                                            type="submit"
                                            className="text-uppercase font-weight-bold font-size-sm"
                                            color="info">
                                            Tạo mới
                                </Button>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                </Card>
            </Container>
            {alert}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        areaList: state.renderArea,
        objectList: state.objectList
    }
}

const mapDispatchToProps = (dispatch) => ({
    createEmployee: (data, image) => dispatch({ type: "CREATE_EMPLOYEE", data: data, avatar: image }),
    renderArea: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
    getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateEmployee));