import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import "../../css/reactTable.css";

function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  const genTable = React.useCallback((data) => {
      let newData = data.map((item, idx) => {
          return{
              ...item,
              status: item.status === 1 ? 'Đang sản xuất' : "Ngừng sản xuất",
              gtin: item.gtin ? item.gtin : 'Chưa có',
              stamp: item.stampNo,
              printNo: 0
          }
      })
    setActivity(newData)
    setLoading(false)
  }, [])

  const getData = async (startDate, endDate) => {
    axios.defaults.headers.common.Authorization = userInfo.data.token
    const response = await axios({
      method: 'GET',
      url: LINK_API.GET_ALL_PRODUCTS.concat('?startTime=', startDate, '&endTime=', endDate)
    })
    if (response && response.data && response.data.data) {
      genTable(response.data.data)
    }
    else {
      genTable([])
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getData(props.startDate, props.endDate)
    
  }, [props.startDate, props.endDate]);


  const columns = React.useMemo(
    () => [
      {
        Header: 'Tên loại sản phẩm',
        accessor: "name",
        sortable: false
      },
    //   {
    //     Header: 'Mã GTIN',
    //     accessor: "gtin",
    //     filterable: false
    //   },
      {
        Header: 'Trạng thái',
        accessor: "status",
        sortable: false
      },
      {
        Header: 'Số tem đã kích hoạt',
        accessor: "stamp",
        filterable: false
      },
      {
        Header: 'Số tem đã in',
        accessor: "printNo",
        filterable: false
      },
    ],
    [t]
  );

  return (

    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={activityRender}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    allActivity: state.allActivity
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllActivity: (data) => dispatch({ type: "GET_ALL_ACTIVITY", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));