import Axios from 'axios';
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import Header from "components/Headers/Header.js";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import { AGD_LOGO } from "constants/image";
import { useFormik } from 'formik';
import { motion } from "framer-motion";
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";
import PlusCopy from "mdi-react/ContentCopyIcon";
import FilterIcon from "mdi-react/FilterIcon";
import PenIcon from "mdi-react/LeadPencilIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import ResetIcon from "mdi-react/RestoreIcon";
import React, { useEffect } from 'react';
import cookie from "react-cookies";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Button, Card, Col, Container, Form, FormGroup, CustomInput, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink as NavLinkStrap, Row, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";
import axios from "axios"
import { Formik, Field } from "formik"
import ConfirmAlert from "components/alert/confirmAlert"
import ContentAlert from "components/alert/contentAlert"
import moment from "moment"
import DetailIcon from "mdi-react/InfoOutlineIcon"
import KeyIcon from "mdi-react/KeyIcon"
import CheckAllIcon from "mdi-react/CheckAllIcon"
function EmployeeList(props) {
  const [cooperatives, setCooperatives] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [alert, setAlert] = React.useState(null)
  const getProductTypeList = props.getProductTypeList;
  const [filterStatus, setFilter] = React.useState(false)
  const { t } = props;
  let linkUrl = "";
  if (props.userInfo && props.userInfo.data && props.userInfo.data.role === "F") {
    linkUrl = "distributor/"
  }
  React.useEffect(() => {
    getProductTypeList(1, 5)
  }, [getProductTypeList])
  const hideAlert = () => {
    setAlert(null)
  }
  const [checkPer, setPer] = React.useState(false);

  const confirmActive = React.useCallback((uuid) => {
    axios.put(LINK_API.CHANGE_STATUS_ACCOUNT + uuid + "/active")
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
          getProductTypeList(1, 5)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }, [t])
  const handleResetData = () => {
    setFilter(false);
    getProductTypeList(1, 5)
  }
  const handleClearFilter = () => {
    formik.resetForm();
  }
  const handleActive = React.useCallback((uuid, title) => {
    let ChildComponent = <div>{title}</div>
    setAlert(
      <ConfirmAlert
        onClose={hideAlert}
        onConfirm={() => confirmActive(uuid)}
        confirmBtnText={t('confirm')}
        child={ChildComponent}
      />
    )
  }, [confirmActive, t])



  const handleResetPassword = React.useCallback(async (values, uuid) => {
    setAlert(<Loading />)
    const data = {
      "password": values.newPass,
      "uuid": uuid
    }
    axios.post(LINK_API.CHANGE_PASS_ADMIN, data)
      .then((response) => {
        if (response.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
        }
        else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(() => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }, [t])

  const resetPassword = React.useCallback((uuid) => {
    const ChildComponent = (
      <div>
        <Formik
          validationSchema={
            Yup.object().shape({
              newPass: Yup.string()
                .min(6, t('tooShort'))
                .required(t('require'))
            })
          }
          initialValues={{ newPass: '', rePass: '' }}
          onSubmit={values => handleResetPassword(values, uuid)}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name="newPass"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('account.newPass')}
                    </label>
                    <Input {...props} type="password" {...props.field} placeholder={t('account.newPass')} />
                  </FormGroup>
                )}
              />
              {errors.newPass && touched.newPass ? <div>{errors.newPass}</div> : null}
              <Button color="info" type="submit">{t('confirm')}</Button>
            </Form>
          )}
        </Formik>
      </div>
    )
    setAlert(
      <ContentAlert
        title={t('employee.enterInfo')}
        hideCancel={true}
        onCancel={hideAlert}
        hideConfirm={true}
        child={ChildComponent}
      />

    )
  }, [t, handleResetPassword])

  const handleDetail = (uuid) => {
    cookie.save("uuidManu", uuid, { path: "/" });
    if (props.userInfo.data.role === 'Z') {
      props.history.push('/admin/detailAccount/' + uuid)
    } else {
      props.history.push('/sadmin/detailAccount/' + uuid)
    }

  }
  const listAccount = props.allAccount.data;
  React.useEffect(() => {
    genTable(listAccount)
  }, [listAccount])

  const totalPageStore = props.allAccount.totalPage;
  React.useEffect(() => {
    setTotalPage(totalPageStore)
  }, [totalPageStore])
  const genTable = React.useCallback((listData) => {
    if (listData && listData.length > 0) {
      let data = listData.map((prop, index) => {
        let loai = ""
        if (prop.role === "M") {
          loai = "Nhà sản xuất";
        }
        else if (prop.role === "F") {
          loai = "Vựa sỉ";
        }
        else if (prop.role === "R") {
          loai = "Nhà bán lẻ";
        }
        else if (prop.role === "D") {
          loai = "Nhà vận chuyển";
        }

        return {
          id: index + 1,
          name: prop.name,
          type: loai,
          createdTime: moment(prop.createAt).format('DD/MM/YYYY'),
          enterpriseID: prop.uuid,
          email: prop.email,
          username: prop.username,
          address: prop.address ? prop.address : 'Chưa có',
          phone: prop.phone ? prop.phone : 'Chưa có',
          tx_id: prop.tx_id,
          actions: <div className="d-flex flex-row justify-content-center">
            <Button onClick={() => handleDetail(prop.uuid)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"detailAccount" + index}>
              <span className="btn-wrapper--icon d-flex">
                <DetailIcon
                  size={18}
                  className="text-info font-size-sm"
                />
              </span>
            </Button>
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"detailAccount" + index}>
              Chi tiết tài khoản
          </UncontrolledTooltip>
            <Button onClick={() => resetPassword(prop.uuid)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipEdit" + index}>
              <span className="btn-wrapper--icon d-flex">
                <KeyIcon
                  size={18}
                  className="text-success font-size-sm"
                />
              </span>
            </Button>
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipEdit" + index}>
              {t('account.newPass')}
            </UncontrolledTooltip>
            {
              !prop.active ?
                <>
                  <Button onClick={() => handleActive(prop.uuid, t('employee.active'))} onClcolor="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipDeactive" + index}>
                    <span className="btn-wrapper--icon d-flex">
                      <CheckAllIcon
                        size={18}
                        className="text-info font-size-sm"
                      />
                    </span>
                  </Button>
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipDeactive" + index}>
                    {t('employee.active')}
                  </UncontrolledTooltip>
                </>
                :
                <>
                  <Button onClick={() => handleActive(prop.uuid, t('employee.deActive'))} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipDeactive" + index}>
                    <span className="btn-wrapper--icon d-flex">
                      <CloseIcon
                        size={18}
                        className="text-danger font-size-sm"
                      />
                    </span>
                  </Button>
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipDeactive" + index}>
                    {t('employee.deActive')}
                  </UncontrolledTooltip>
                </>
            }
          </div>
        }
      })
      setCooperatives(data)
    }
    setLoading(false)
  }, [t, resetPassword, handleActive])

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: t('account.name'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('account.ID'),
        accessor: "enterpriseID",
        filterable: false
      },
      {
        Header: "Tên đăng nhập",
        accessor: "username",
        filterable: false
      },
      {
        Header: "Địa chỉ",
        accessor: "address",
        filterable: false
      },
      {
        Header: t('account.createdTime'),
        accessor: "createdTime",
        filterable: false
      },
      {
        Header: t('account.actions'),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  const handleEnterData = () => {
    if (props.userInfo.data.role === 'Z') {
      props.history.push("/admin/createSub")
    } else {
      props.history.push("/sadmin/createSub")
    }
  }
  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      props.getProductTypeListFilter(1, values.name, values.value, 5)
    }, []),
  });
  useEffect(() => {
    props.getProductTypeList(1, 5)
    // }, [getProductList]);
    if (!props.userInfo || props.userInfo.data === "") {
      props.history.push('/login')
    }
    else {
      props.getProductTypeList(1, 5)
    }
  }, [getProductTypeList]);

  return (
    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header />
        <Container className="mt--7" fluid>
          <Card className="card-box mb-5">
            <div className="card-header py-3 d-flex justify-content-between">
              <div className="font-weight-bold card-header--title font-size-lg">Danh sách hộ kinh doanh</div>
              <div className="card-header--actions d-flex flex-row">
                <UncontrolledDropdown>
                  <DropdownToggle id="filterButton" color="neutral-success" className="mx-2">
                    <FilterIcon size={20} /> {t('filter.filter')}
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-xxl p-0">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="p-3">
                        <Row>
                          <Col md="6">
                            <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                              {t('filter.name')}
                            </small>
                            <CustomInput
                              type="select"
                              placeholder={t('filter.name')}
                              value={formik.values.name}
                              valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                              invalid={formik.touched.name && formik.errors.name ? true : false}
                              {...formik.getFieldProps('name')}
                            >
                              <option value="nameFilter">Tên tài khoản</option>
                              <option value="addressFilter">Địa chỉ</option>
                              <option value="usernameFilter">Tên đăng nhập</option>
                              <option value="uuidFilter">Mã tài khoản</option>

                              {/* <option value="status">{t('product.status')}</option> */}
                            </CustomInput>
                          </Col>
                          <Col md="6">
                            <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                              {t('filter.value')}
                            </small>
                            {formik.values.name !== "status" ?
                              <Input
                                placeholder={t('filter.value')}
                                value={formik.values.value}
                                valid={formik.touched.value && formik.errors.value ? false : (formik.values.value ? true : false)}
                                invalid={formik.touched.value && formik.errors.value ? true : false}
                                {...formik.getFieldProps('value')}
                              >
                              </Input> : <CustomInput
                                type="select"
                                placeholder={t('filter.value')}
                                value={formik.values.value}
                                valid={formik.touched.value && formik.errors.value ? false : (formik.values.value || formik.values.value === 0 ? true : false)}
                                invalid={formik.touched.value && formik.errors.value ? true : false}
                                {...formik.getFieldProps('value')}
                              >
                                <option value={1}>{t('product.inProduction')}</option>
                                <option value={0}>{t('product.stopProduction')}</option>
                              </CustomInput>}
                          </Col>
                        </Row>

                      </div>
                      <div className="divider" />
                      <div className="p-3 text-center bg-secondary">
                        <Button color="primary" type="submit">
                          {t('filter.search')}
                        </Button>
                      </div>
                    </form>
                    <div className="divider" />
                    <div className="p-3">
                      <Row>
                        <Col md="6">
                          <Nav className="nav-neutral-danger flex-column p-0">
                            <NavItem>
                              <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleClearFilter}>
                                <div className="nav-link-icon">
                                  <CloseIcon />
                                </div>
                                <span>{t('filter.clear')}</span>
                              </NavLinkStrap>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col md="6">
                          <Nav className="nav-neutral-info flex-column p-0">
                            <NavItem>
                              <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleResetData}>
                                <div className="nav-link-icon">
                                  <ResetIcon />
                                </div>
                                <span>{t('filter.reset')}</span>
                              </NavLinkStrap>
                            </NavItem>
                          </Nav>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"filterButton"}>
                  {t('filter.filter')}
                </UncontrolledTooltip>
                <div style={{ display: "inline-block" }} id="notiPlan">
                  <Button disabled={checkPer} color="info" onClick={handleEnterData} >
                    <span className="btn-wrapper--icon">
                      <PlusCircle size={16} />
                    </span>
                    <span className="btn-wrapper--label">{t('product.add')}</span>
                  </Button>
                </div>
                {checkPer ?
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
                    {t('notiPlan')}
                  </UncontrolledTooltip> : null}
              </div>
            </div>
            <div className="divider" />
            <div className="card-body pt-2 px-4 pb-4">
              <ReactTable
                noDataText={t('noData')}
                data={cooperatives}
                columns={columns}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                loading={loadingState}
                LoadingComponent={LoadingTable}
                manual
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                pages={totalPage}
                className="-striped -highlight"
                onFetchData={async (state, instance) => {
                  setLoading(true)
                  let x = state.page + 1;
                  if (filterStatus === false) {
                    props.getProductTypeList(x, state.pageSize)
                  } else {
                    props.getProductTypeListFilter(x, formik.values.name, formik.values.value, state.pageSize)
                  }
                }}
              />
            </div>
          </Card>
        </Container>
      </motion.div>
      {alert}

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    productList: state.productType,
    permission: state.permission.data,
    allAccount: state.allAccount
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProductTypeList: (page, size) => dispatch({ type: "GET_ALL_ACCOUNT", page: page, size: size }),
  getProductTypeListFilter: (page, name, value, size) => dispatch({ type: "GET_ALL_ACCOUNT_FILTER", page: page, name: name, value: value, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(EmployeeList));